import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import InputContext from "../Auth/InputContext";
import navigation from "../../Configs/Navigation.json";
import ApiEndpoint from "../../Configs/APIEndpoints.json";
import Alert from "../../Configs/Alerts.json";
import SalesGraph from "./SalesGraph";
import OrdersGraph from "./OrdersGraph";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../Component/Cookies/Cookie";
import Toast from "../../Component/Toastify/Toast";
import imagePlaceHolder from "../../Assets/image-placeholder.png";

//constant
const API_HOST = process.env.REACT_APP_HOST_API_URL;
const APP_INFRA = process.env.REACT_APP_INFRA.toLowerCase();
const S3IMG_HOST = process.env.REACT_APP_S3BUCKET_IMG_HOST;
const IMG_HOST =
  APP_INFRA == "staging" || APP_INFRA == "production" ? S3IMG_HOST : API_HOST;

const Dashboard = () => {
  const location = useLocation();
  const { showToast } = Toast();
  const navigate = useNavigate();
  const contextObj = useContext(InputContext);

  const [sellerStatus, setSellerStatus] = useState(false);
  const [isBusinessOwner, setIsBusinessOwner] = useState(false);
  const [dashboardData, setDashboardData] = useState("");
  const [ordersGraphData, setOrdersGraphData] = useState([]);
  const [salesGraphData, setSalesGraphData] = useState([]);
  const [recentSellingProducts, setRecentSellingProducts] = useState([]);
  const [lastFiveYears, setLastFiveYears] = useState([]);
  const [salesYear, setSalesYear] = useState(
    new Date().getFullYear().toString()
  );
  const [orderYear, setOrderYear] = useState(
    new Date().getFullYear().toString()
  );

  // Seller Profile Status
  useEffect(() => {
    if (contextObj.inputValue.isSellerVerified === "true") {
      setSellerStatus(false);
    }
    if (contextObj.inputValue.isBusinessOwner === true) {
      setIsBusinessOwner(true);
    }
  }, [
    contextObj.inputValue.isSellerVerified,
    contextObj.inputValue.isBusinessOwner,
  ]);

  // generate an array of the last five years
  useEffect(() => {
    window.scrollTo(0, 0);
    const getLastFiveYears = () => {
      const currentYear = new Date().getFullYear();
      const years = [];
      for (let i = 0; i < 5; i++) {
        years.push(currentYear - i);
      }
      return years;
    };
    setLastFiveYears(getLastFiveYears());
  }, []);

  useEffect(() => {
    // dashboard API
    const getDashboardData = async () => {
      const token = getDataFromCookies("R3p7uLw9Xk");
      const response = await fetch(
        `${API_HOST}/${ApiEndpoint.SellersDashboardApi}?order_year=${orderYear}&sales_year=${salesYear}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();

      if (res.status === 200) {
        setDashboardData(res.data);
        if (res.data) {
          const products = res.data.recent_activity;
          setRecentSellingProducts(products);
          setOrdersGraphData(res.data.orders_graph);
          setSalesGraphData(res.data.sales_graph);
        }
        setSellerStatus(false);
        contextObj.setInput("isSellerVerified", "true");
      } else if (
        res.status === 401 &&
        res.message === "You are not authorized."
      ) {
        contextObj.setInput("prevPath", location.pathname);

        removeDataFromCookie("R3p7uLw9Xk", "/");
        removeDataFromCookie("isLoggedIn", "/");
        removeDataFromCookie("fullName");
        removeDataFromCookie("firstChar");
        removeDataFromCookie("email");
        contextObj.setInput("isSignupNavigation", true);
        navigate(`${navigation.home}`);
        showToast("info", Alert.alertForLoginExpired);
      } else if (res.status === 401 && res.message.includes("not verified")) {
        setSellerStatus(true);
        contextObj.setInput("isSellerVerified", "false");
      } else {
        showToast("info", "Something went wrong");
      }
    };
    getDashboardData();
  }, [salesYear, orderYear]);

  useEffect(() => {
    if (
      contextObj.inputValue.navigation == "true" &&
      contextObj.inputValue.navigationFrom == "signUpPage"
    ) {
      showToast("success", Alert.alertForSucessfulRegistration);
      contextObj.setInput("navigation", "false");
      contextObj.setInput("navigationFrom", "");
    }
  }, [contextObj]);

  const handleEdit = (slug) => {
    navigate(`${navigation.products}/${slug}`);
    return;
  };

  const getBgColor = (value) => {
    switch (value) {
      case "Unverified":
        return "#F8D294";
      case "Verified":
        return "lightGreen";
      case "Verified and Published":
        return "#CDFEE1";
      case "Rejected":
        return "#FFC9C9";
      case "Hold":
        return "#FFB131";
      case "Sold":
        return "#CCCCCC";
      case "Donated":
        return "#E2CCFF";
      case "Draft":
        return "#E0F0FF";
      case "Unavailable":
        return "#c9c9c9";
      default:
        return "black";
    }
  };

  return (
    <div className="container">
      {sellerStatus && !isBusinessOwner && (
        <div className="seller-verification-line">
          <p>
            Your account verification is pending. To proceed, please{" "}
            <span
              className="complete-your-profile"
              onClick={() => {
                navigate(`${navigation.profile}`);
              }}
            >
              ensure your Profile Information and Pickup Address is complete
            </span>{" "}
            and wait for the admin verification call.
          </p>
        </div>
      )}
      {sellerStatus && isBusinessOwner && (
        <div className="seller-verification-line">
          <p>
            Your account verification is pending. To proceed, please{" "}
            <span
              className="complete-your-profile"
              onClick={() => {
                navigate(`${navigation.profile}`);
              }}
            >
              ensure your Profile Information, Payment Information, Pickup
              Address and Business Details is complete
            </span>{" "}
            and wait for the admin verification call.
          </p>
        </div>
      )}
      <div className="container-heading">
        <h2>Dashboard</h2>
      </div>
      <div className="dashboard-body">
        <div className="order-analytics">
          <div>
            <p>
              <span id="dashboard-units-sold">
                {dashboardData.units_sold ? dashboardData.units_sold : 0}
              </span>
              Units Sold
            </p>
          </div>
          <div>
            <p>
              <span id="dashboard-total-sales">
                ₹{dashboardData.total_sales ? dashboardData.total_sales : 0}
              </span>
              Total Sales
            </p>
          </div>
          <div>
            <p>
              <span id="dashboard-order-received">
                {dashboardData.order_received
                  ? dashboardData.order_received
                  : 0}
              </span>
              Orders Received
            </p>
          </div>
          <div>
            <p>
              <span id="dashboard-product-listed">
                {dashboardData.product_listed
                  ? dashboardData.product_listed
                  : 0}
              </span>
              Product Listed
            </p>
          </div>
        </div>

        <p className="sellingProductHeading">Statistics</p>
        <div className="graphDivOfSeller">
          <div className="graph-box">
            <div className="graph-header">
              <p>
                Total Sales
                <span id="dashboard-graph-total-sales">
                  ₹
                  {dashboardData.sales_graph_total
                    ? dashboardData.sales_graph_total
                    : 0}
                </span>
              </p>
              <select
                id="dashboard-total-sales-select-year"
                className="graph-select"
                value={salesYear}
                onChange={(e) => {
                  const year = e.target.value;
                  setSalesYear(year);
                }}
              >
                {lastFiveYears.map((year) => (
                  <option value={year}>{year}</option>
                ))}
              </select>
            </div>
            <SalesGraph data={salesGraphData} />
          </div>
          <div className="graph-box">
            <div className="graph-header">
              <p>
                Total Orders
                <span id="dashboard-graph-order-received">
                  {dashboardData.orders_graph_total
                    ? dashboardData.orders_graph_total
                    : 0}
                </span>
              </p>
              <select
                id="dashboard-total-orders-select-year"
                className="graph-select"
                value={orderYear}
                onChange={(e) => {
                  const year = e.target.value;
                  setOrderYear(year);
                }}
              >
                {lastFiveYears.map((year) => (
                  <option value={year}>{year}</option>
                ))}
              </select>
            </div>
            <OrdersGraph data={ordersGraphData} />
          </div>
          {/* <div className="graph-box">
                <p>
                  Total Products
                  <span>
                    {dashboardData.product_listed
                      ? dashboardData.product_listed
                      : 0}
                  </span>
                </p>
                <StoreGraph />
              </div> */}
        </div>
        <div className="second-box">
          <div>
            <p className="sellingProductHeading">Recent Activity</p>
            <table className="top-products">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody className="dashboard-table">
                {recentSellingProducts.length === 0 ? (
                  <tr>
                    <td colSpan="4" className="nomatchfound">
                      No data
                    </td>
                  </tr>
                ) : (
                  <>
                    {recentSellingProducts.map((data) => (
                      <tr key={data.id}>
                        <td>
                          <img
                            id="dashboard-product-image"
                            src={
                              data.thumb_image
                                ? `${IMG_HOST}/${data.thumb_image}`
                                : imagePlaceHolder
                            }
                            onError={(e) => {
                              e.target.src = imagePlaceHolder;
                            }}
                          />
                        </td>
                        <td id="dashboard-product-name">
                          {data.status === "Hold" ||
                          data.status === "Sold" ||
                          data.status === "Donated" ||
                          data.status === "Unavailable" ? (
                            <span>{data.name}</span>
                          ) : (
                            <span
                              className="product-name-redirect"
                              onClick={() => {
                                handleEdit(data.slug);
                              }}
                            >
                              {data.name}
                            </span>
                          )}
                        </td>
                        <td id="dashboard-product-price">
                          {data.product_price && <>₹{data.product_price}</>}
                        </td>
                        <td id="dashboard-product-status">
                          <p
                            className="product-status"
                            style={{ backgroundColor: getBgColor(data.status) }}
                          >
                            {data.status}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
