import React from "react";
import { useState, useEffect, useContext } from "react";
import Footer from "../Home/Footer/Footer";
import ResourcesSidebar from "./Sidebar";
import "./resources.css";

const APP_NAME = process.env.REACT_APP_NAME;
const SELLER_URL = process.env.REACT_APP_SELLER_DOMAIN;
const ADMIN_ADDRESS = process.env.REACT_APP_ADMIN_ADDRESS;

const SellersGuideLine = () => {
  // policy header sticky
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const policyHeading = document.querySelector(".policy-content-heading");
      const headingPosition = policyHeading.getBoundingClientRect().top;
      if (headingPosition <= 150) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#fff",
      }}
    >
      <div className="body-container">
        <div className="policy-container">
          <ResourcesSidebar activeResource={"Seller's GuideLine"} />

          <div className="policy-content">
            <h1
              className={`policy-content-heading ${
                isSticky ? "sticky-heading" : ""
              }`}
            >
              Seller's GuideLine
            </h1>
            <p>
              <span>
                {APP_NAME} provides an opportunity to every individual to sell
                from anywhere in India. With {APP_NAME}, you can now make money
                with unused toys or baby products and encourage reusability by
                sharing joy.
              </span>
            </p>
            <p>Become a Seller with {APP_NAME}</p>
            <p>
              <span>
                To become a seller, first you need to have a Sellers Account.
              </span>
            </p>

            <ol>
              <li>
                You can click on{" "}
                <a href={"/login"} target="_blank" rel="noreferrer">
                  Start Selling
                </a>{" "}
                from the navigation bar at top.
              </li>
              <li>SignUp as a seller and complete your profile information.</li>
              <li>
                Your Profile will then be verified against the details filled in
                for correctness and authenticity.
              </li>
            </ol>

            <p>Publish your product</p>
            <ol>
              <li>
                Once your profile is approved, you can add your product(s) by
                uploading attractive images and a short and catchy description.
              </li>
              <li>
                Your Product will then go through initial verification in terms
                of image clarity, price and description.
              </li>
              <li>
                Once approved, your product will then be available at {APP_NAME}
                's Buyer Portal for everyone to buy.
              </li>
              <li>
                As soon as your product receives an interest, the team at{" "}
                {APP_NAME} will get in touch with you for the availability of
                the product.
              </li>
              <li>
                Once they receive your confirmation, {APP_NAME} will pick up the
                product from your end for further processing.
              </li>
              <li>
                You will receive your payment within {3} to {7} working days
                post the shipment has been initiated
              </li>
            </ol>

            <p>Tips To Improve Your Product Listing</p>
            <ol>
              <li>
                Captivating Captures: Images are the most effective and
                interactive way of showing the product. Better the image, higher
                the chances of it getting sold. Images of {500} x {500} or{" "}
                {1000} x {1000} pixels are recommended to increase the picture
                quality.
              </li>
              <li>
                Crafting Price Perfection: {APP_NAME} accepts preloved toys and
                so it would be a good choice to quote based on how much the toy
                has been used. Below are some of the suggestion,
              </li>
              <ul>
                <li>New (Sealed): ~90% of the current market price</li>
                <li>Almost Like New: ~70% of the current market price</li>
                <li>Gently Used : ~50% of the current market price</li>
                <li>Loved: ~40% of the current market price</li>
                <li style={{ listStyle: "none", marginTop: "10px" }}>
                  <b style={{ fontWeight: 500 }}>Note:</b> Your product will be
                  listed 10-20% higher pricing on our portal."Current Market
                  Price" will be checked online at the instant when Product
                  Request is received. In case if there are multiple vendors
                  selling the same product, then the one with the lowest will be
                  considered as the current market price for computation.
                </li>
              </ul>
              <li>
                Packaging Guidelines:
                <ul>
                  <li>
                    Secure Packaging: Use sturdy, durable packaging materials to
                    ensure the item is well-protected during transit.Wrap
                    fragile items with bubble wrap or packing paper to prevent
                    breakage.
                  </li>
                  <li>
                    Proper Labeling: Clearly label the package with the below
                    mentioned address.
                  </li>
                </ul>
              </li>
              <span>
                <b style={{ fontWeight: 500 }}>{ADMIN_ADDRESS}</b>
              </span>
              <br></br>
              <span
                style={{
                  color: "#6b6b6b",
                  lineHeight: "20px",
                  padding: "3px 0",
                }}
              >
                Include your return address in case the package needs to be
                returned.
              </span>
              <li>
                Availability for Shipment Pickup: After receiving the order,{" "}
                {APP_NAME} will initiate the process of collecting the product
                from your end as soon as possible to meet the expected delivery
                date. In case, if you are NOT available at the scheduled date,
                please communicate the same to {APP_NAME} so that pickup can be
                rescheduled.
              </li>
              <li>
                Quality Control: Include any necessary documentation, such as
                user manuals or certificates, if applicable.
              </li>
            </ol>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SellersGuideLine;
