import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import InputContext from "../Auth/InputContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import navigation from "../../Configs/Navigation.json";
import ApiEndpoint from "../../Configs/APIEndpoints.json";
import Alert from "../../Configs/Alerts.json";
import ReactQuill from "react-quill";
import { getDataFromCookies } from "../../Component/Cookies/Cookie";
import { ReactComponent as RollingSvg } from "../../Assets/rolling.svg";
import Toast from "../../Component/Toastify/Toast";
import Cropper from "react-cropper";
import { faTrashAlt, faXmark } from "@fortawesome/free-solid-svg-icons";
import loadingImgPath from "../../Assets/rolling.svg";
import "cropperjs/dist/cropper.css";
import "react-quill/dist/quill.snow.css";
import "./product.css";
import CategoriesDescription from "./CategoryDescription/categoriesDescription";

// import constant
const API_HOST = process.env.REACT_APP_HOST_API_URL;
const BUYER_APP = process.env.REACT_APP_BUYER_DOMAIN;
const APP_INFRA = process.env.REACT_APP_INFRA.toLowerCase();
const S3IMG_HOST = process.env.REACT_APP_S3BUCKET_IMG_HOST;
const IMG_HOST =
  APP_INFRA == "staging" || APP_INFRA == "production" ? S3IMG_HOST : API_HOST;

const NewProducts = (props) => {
  const [pageName, setPageName] = useState("");
  const inputRef = useRef(null);
  const { slug } = useParams();
  const navigate = useNavigate();
  const { showToast } = Toast();
  const contextObj = useContext(InputContext);
  const [productId, setProductId] = useState("");
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [sellerStatus, setSellerStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [showCategoriesPopup, setShowCategoryPopup] = useState(false);
  const [openSeoEdit, setOpenSeoEdit] = useState(false);

  const metaTitleMaxLength = 70;
  const metaDescMaxLength = 320;
  const [metaTitleRemaining, setMetaTitleRemaining] =
    useState(metaTitleMaxLength);
  const [metaDescRemaining, setMetaDescRemaining] = useState(metaDescMaxLength);
  const removeEmptyHTMLTags = (input) => {
    const emptyTagsAtStart = /^<\w+>(\s*|<br>)<\/\w+>/;
    const emptyTagsAtEnd = /<\w+>(\s*|<br>)<\/\w+>$/;
    while (emptyTagsAtStart.test(input)) {
      input = input.replace(emptyTagsAtStart, "");
    }

    while (emptyTagsAtEnd.test(input)) {
      input = input.replace(emptyTagsAtEnd, "");
    }

    return input;
  };
  const [errors, setErrors] = useState({
    has_error: false,
    name: "",
    description: "",
    thumb_image: "",
    front_image: "",
    left_image: "",
    right_image: "",
    back_image: "",
    top_image: "",
    video_url: "",
    product_price: "",
    current_market_price: "",
    sku: "",
    hsn: "",
    barcode: "",
    weight: "",
    length: "",
    width: "",
    height: "",
    is_negotiable: "",
    is_donatable: "",
    status: "",
    condition: "",
    age_group_id: "",
    brand_id: "",
    category_id: "",
    estimated_shipping_cost: "",
    meta_title: "",
    meta_description: "",
  });

  const handleCategoryDetailsPopup = () => {
    setShowCategoryPopup(true);
  };

  const closedCategoryDetailsPopup = () => {
    setShowCategoryPopup(false);
  };

  // general functions
  const handleWheel = (e) => {
    e.preventDefault();
    document.activeElement.blur();
  };

  // length error
  const isValidNumeric = (value) => {
    const numeric = /^[0-9]+$/;
    const isValid = numeric.test(value);
    return isValid;
  };

  const token = getDataFromCookies("R3p7uLw9Xk");
  if (!token) {
    window.location.href = navigation.login;
  }

  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);

    setProduct((prev) => ({
      ...prev,
      sku: null,
    }));
    setProduct((prev) => ({
      ...prev,
      hsn: null,
    }));
    setProduct((prev) => ({
      ...prev,
      barcode: null,
    }));

    setErrors((prev) => ({
      ...prev,
      sku: "",
    }));
    setErrors((prev) => ({
      ...prev,
      hsn: "",
    }));
    setErrors((prev) => ({
      ...prev,
      barcode: "",
    }));
  };

  //weight units
  const [weightUnit, setWeightUnit] = useState("kg");
  const weightUnitsChangeHandler = (event) => {
    setWeightUnit(event.target.value);
  };

  //convert product weight gm to kg
  const convertWeightGmToKg = (weight) => {
    const convertedWeight = (weight / 1000).toFixed(3);
    return parseFloat(convertedWeight);
  };

  // tooltip visibility
  const handleMouseEnter = () => {
    setTooltipVisible(true);
  };
  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  // image handling
  const [showImgUpModal, setShowImgUpModal] = useState(false);
  const [rawImgName, setRawImgName] = useState("");
  const [rawImage, setRawImage] = useState(null);
  const cropperRef = useRef(null);

  const isBase64Image = (value) => {
    return typeof value === "string" && value.startsWith("data:image/");
  };

  const loadImgOnModal = (imgName) => {
    setShowImgUpModal(true);
    setRawImgName(imgName);
    setRawImage(imgContainer[imgName]);
  };

  const convertBase64ToFile = (base64Image, file) => {
    if (base64Image.startsWith("data:image/")) {
      const binaryString = atob(base64Image.split(",")[1]);
      const arrayBuffer = new ArrayBuffer(binaryString.length);
      const byteArray = new Uint8Array(arrayBuffer);
      for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }
      const fileSize = arrayBuffer.byteLength;
      const customFile = new File([arrayBuffer], file.name, {
        type: file.type,
        lastModified: file.lastModified,
      });
      Object.defineProperty(customFile, "size", {
        value: fileSize,
      });

      return customFile;
    }
  };

  const cropImage = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const croppedImg = cropperRef.current?.cropper
        .getCroppedCanvas()
        .toDataURL();

      const customFile = convertBase64ToFile(croppedImg, product[rawImgName]);
      if (customFile) {
        setProduct((prev) => ({
          ...prev,
          [rawImgName]: customFile,
        }));
        setImgContainer((prev) => ({
          ...prev,
          [rawImgName]: croppedImg,
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          [rawImgName]: "Invalid image format",
        }));
      }
    }
    // reset modal
    setShowImgUpModal(false);
    setRawImgName("");
    setRawImage(null);
  };

  const removeImageFromContainer = (imgName) => {
    setErrors((prev) => ({
      ...prev,
      [imgName]: "",
    }));
    setImgContainer((prev) => ({
      ...prev,
      [imgName]: null,
    }));
    setProduct((prev) => ({
      ...prev,
      [imgName]: null,
    }));
    document.getElementById(imgName).value = "";
  };

  const resetInputType = (imgName) => {
    setImgContainer((prev) => ({
      ...prev,
      [imgName]: null,
    }));
    setProduct((prev) => ({
      ...prev,
      [imgName]: null,
    }));
    document.getElementById(imgName).value = "";
  };

  // Get All Category Element;
  const [categoryData, setCategoryData] = useState([]);
  const getAllCategoriesData = async () => {
    await fetch(`${API_HOST}/${ApiEndpoint.SellersGetCategoryApi}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const res = data;
        if (res.status === 200) {
          setCategoryData(res.data);
          setSellerStatus(false);
          contextObj.setInput("isSellerVerified", "true");
        } else if (res.status === 401 && res.message.includes("not verified")) {
          setSellerStatus(true);
          contextObj.setInput("isSellerVerified", "false");
        }
      })
      .catch((error) => {
        console.error(error);
        if (error.message.includes("Failed to fetch")) {
          // navigate("/error");
          contextObj.setInput("serviceUnavailable", true);
        } else {
          window.location.href = "/error";
        }
      });
  };

  // Get All Brand List;
  const [brandData, setBrandData] = useState([]);
  const getAllBrandList = async () => {
    const token = getDataFromCookies("R3p7uLw9Xk");
    await fetch(`${API_HOST}/${ApiEndpoint.SellersGetBrandApi}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const dataArray = data.data;
        if (Array.isArray(dataArray)) {
          setBrandData(dataArray);
        }
      })
      .catch((error) => {
        console.error(error);
        if (error.message.includes("Failed to fetch")) {
          // navigate("/error");
          contextObj.setInput("serviceUnavailable", true);
        } else {
          window.location.href = "/error";
        }
      });
  };

  // Get All Conditions List;
  const [conditionData, setConditionData] = useState([]);
  const getAllConditionsList = async () => {
    await fetch(`${API_HOST}/${ApiEndpoint.SellersGetConditionApi}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const dataArray = data.data;
        if (Array.isArray(dataArray)) {
          const titlesArray = dataArray.map((item) => item.title);
          setConditionData(titlesArray);
        }
      })
      .catch((error) => {
        console.error(error);
        if (error.message.includes("Failed to fetch")) {
          // navigate("/error");
          contextObj.setInput("serviceUnavailable", true);
        } else {
          window.location.href = "/error";
        }
      });
  };

  // Get All Conditions List;
  const [applicableAgeGroupData, setapplicableAgeGroupData] = useState([]);
  const getAllAplplicableAgeGroupList = async () => {
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellersGetAgeApi}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    const res = await response.json();
    if (res.status === 200) {
      setapplicableAgeGroupData(res.data);
    } else {
      navigate("/error");
    }
  };
  useEffect(() => {
    getAllAplplicableAgeGroupList();
  }, []);

  // Seller Profile Status
  useEffect(() => {
    if (contextObj.inputValue.isSellerVerified === "true") {
      setSellerStatus(false);
    }
  }, [contextObj.inputValue.isSellerVerified]);

  const setImagePath = (images) => {
    if (images.thumb_image !== "null" && images.thumb_image !== null) {
      setImgContainer((prev) => ({
        ...prev,
        thumb_image: IMG_HOST + "/" + images.thumb_image,
      }));
    }
    if (images.front_image !== "null" && images.front_image !== null) {
      setImgContainer((prev) => ({
        ...prev,
        front_image: IMG_HOST + "/" + images.front_image,
      }));
    }
    if (images.left_image !== "null" && images.left_image !== null) {
      setImgContainer((prev) => ({
        ...prev,
        left_image: IMG_HOST + "/" + images.left_image,
      }));
    }
    if (images.right_image !== "null" && images.right_image !== null) {
      setImgContainer((prev) => ({
        ...prev,
        right_image: IMG_HOST + "/" + images.right_image,
      }));
    }
    if (images.back_image !== "null" && images.back_image !== null) {
      setImgContainer((prev) => ({
        ...prev,
        back_image: IMG_HOST + "/" + images.back_image,
      }));
    }
    if (images.top_image !== "null" && images.top_image !== null) {
      setImgContainer((prev) => ({
        ...prev,
        top_image: IMG_HOST + "/" + images.top_image,
      }));
    }
  };

  // Get product details
  const getProductDetails = async (productId) => {
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellersProductsApi}/${productId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );

    const res = await response.json();
    if (res.status === 200) {
      setProduct(res.data);
      if (res.data) {
        // set checkbox enabled
        if (
          res.data.sku !== null ||
          res.data.hsn !== null ||
          res.data.barcode !== null
        ) {
          setIsChecked(true);
        }

        // set seo inputs
        if (res.data && res.data.meta_title) {
          setMetaTitleRemaining(
            metaTitleMaxLength - res.data.meta_title.length
          );
        }
        if (res.data && res.data.meta_description) {
          setMetaDescRemaining(
            metaDescMaxLength - res.data.meta_description.length
          );
        }
        if (res.data && (res.data.meta_title || res.data.meta_description)) {
          setOpenSeoEdit(true);
        }

        // change default status
        if (res.data.status === "Draft") {
          setProduct((prev) => ({
            ...prev,
            status: "Draft",
          }));
        } else {
          setProduct((prev) => ({
            ...prev,
            status: "Active",
          }));
        }

        // set image host urls
        setImagePath(res.data);
      }
    } else {
      console.error(res);
    }
  };

  useEffect(() => {
    if (props.page === "new") {
      setPageName("Add");
    } else if (props.page === "edit") {
      setPageName("Edit");

      const urls = slug.split("-");
      const productId = urls[urls.length - 2];
      setProductId(productId);

      getProductDetails(productId);
    }

    const token = getDataFromCookies("R3p7uLw9Xk");
    if (!token) {
      showToast("error", Alert.alertForselectingProduct);
      navigate(`${navigation.home}`);
    }
    if (inputRef.current !== null) inputRef.current.focus();
    getAllCategoriesData();
    getAllBrandList();
    getAllConditionsList();
  }, []);

  // calculate estimated shipping cost
  const [isCheckedShippingCost, setIsCheckedShippingCost] = useState(false);
  const [enableCustomPincode, setEnableCustomPincode] = useState(false);
  const [estimatedShippingCost, setEstimatedShippingCost] = useState("");
  const [shippingCostLoading, setShippingCostLoading] = useState(false);
  const [shippingCostPincode, setShippingCostPincode] = useState("");
  const [shippingCostCustomPincode, setShippingCostCustomPincode] =
    useState("");
  useEffect(() => {
    const pincode = getDataFromCookies("pincode");
    if (pincode) {
      setShippingCostPincode(pincode);
    }
  }, [getDataFromCookies]);

  const convertToAmpersand = (text) => {
    // Replace blank spaces with "&nbsp;" and line breaks with "<br>"
    return text.replace(/\s/g, "&nbsp;").replace(/\n/g, "<br>");
  };

  const getEstimatedShippingCost = async () => {
    let errorHas = false;
    // Length error.
    if (product.length === null || product.length === "") {
      setErrors((prev) => ({
        ...prev,
        length: Alert.alertForLength,
      }));
      errorHas = true;
    } else if (
      product.length != "" &&
      product.length != null &&
      !isValidNumeric(product.length)
    ) {
      setErrors((prev) => ({
        ...prev,
        length: Alert.invalidFormat,
      }));
      errorHas = true;
    } else if (
      product.length != "" &&
      product.length != null &&
      (product.length === 0 ||
        product.length === "0" ||
        product.length < 0.1 ||
        product.length > 300)
    ) {
      setErrors((prev) => ({
        ...prev,
        length: Alert.valBetweenPoint1To300,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        length: "",
      }));
    }

    // Width error.
    if (product.width === null) {
      setErrors((prev) => ({
        ...prev,
        width: Alert.alertForWidth,
      }));
      errorHas = true;
    } else if (Number(product.width < 1 || product.width > 300)) {
      setErrors((prev) => ({
        ...prev,
        width: Alert.alertForMaxWidth,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({ ...prev, width: "" }));
    }

    // height error.
    if (product.height === null) {
      setErrors((prev) => ({
        ...prev,
        height: Alert.alertForHeight,
      }));
      errorHas = true;
    } else if (Number(product.height < 1 || product.height > 300)) {
      setErrors((prev) => ({
        ...prev,
        height: Alert.alertForMaxHeight,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({ ...prev, height: "" }));
    }

    // weight error.
    if (product.weight === null) {
      setErrors((prev) => ({
        ...prev,
        weight: Alert.alertForWeight,
      }));
      errorHas = true;
    } else if (
      weightUnit === "kg" &&
      product.weight &&
      (product.weight < 0.1 || product.weight > 20)
    ) {
      setErrors((prev) => ({
        ...prev,
        weight: Alert.alertForMaxWeightKG,
      }));
      errorHas = true;
    } else if (
      weightUnit === "gm" &&
      (product.weight < 100 || product.weight > 20000)
    ) {
      setErrors((prev) => ({
        ...prev,
        weight: Alert.alertForMaxWeightGM,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({ ...prev, weight: "" }));
    }

    if (errorHas) {
      setShippingCostLoading(false);
      return;
    }

    let productWeight = "";
    if (weightUnit === "gm") {
      productWeight = convertWeightGmToKg(product.weight);
    } else {
      productWeight = product.weight;
    }

    const payload = {
      weight: productWeight,
      height: product.height,
      length: product.length,
      width: product.width,
      pincode: shippingCostCustomPincode,
    };

    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellersProductEstimatedShippingCostApi}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    const res = await response.json();
    if (res.status === 200) {
      setEstimatedShippingCost(res.data);
    } else if (res.status === 400) {
      const errors = res.errors;
      let errorMessage = "";
      if (errors) {
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((message) => {
            errorMessage = errorMessage + " " + message;
          });
        });
      }
      setErrors((prev) => ({
        ...prev,
        estimated_shipping_cost: errorMessage,
      }));
    } else {
      console.error(res);
      setErrors((prev) => ({
        ...prev,
        estimated_shipping_cost: res.message,
      }));
    }
    setShippingCostLoading(false);
  };
  const handleCheckboxChangeShippingCost = () => {
    setIsCheckedShippingCost(!isCheckedShippingCost);

    // reset fields
    setSelectedValue("");
    setShippingCostLoading(false);
    setEstimatedShippingCost("");
    setShippingCostCustomPincode("");
    setEnableCustomPincode(false);

    setErrors((prev) => ({
      ...prev,
      estimated_shipping_cost: "",
    }));
  };

  useEffect(() => {
    if (shippingCostCustomPincode.length === 6) {
      setShippingCostLoading(true);
      getEstimatedShippingCost();
    }
  }, [shippingCostCustomPincode]);

  // pincode dropdown option
  const [selectedValue, setSelectedValue] = useState("");

  const handleChangeShippingCost = (e) => {
    setShippingCostCustomPincode("");
    setEstimatedShippingCost("");
    setErrors((prev) => ({
      ...prev,
      estimated_shipping_cost: "",
    }));
    const val = e.target.value;
    setSelectedValue(val);
    if (val === "custom") setEnableCustomPincode(true);
    else if (val !== "" && val !== "custom") {
      setShippingCostCustomPincode(val);
      setEnableCustomPincode(false);
    } else setEnableCustomPincode(false);
  };

  // save new products
  const [product, setProduct] = useState({
    name: null,
    description: editorContent,
    thumb_image: null,
    front_image: null,
    left_image: null,
    right_image: null,
    back_image: null,
    top_image: null,
    video_url: null,
    product_price: null,
    current_market_price: null,
    sku: null,
    hsn: null,
    barcode: null,
    weight: null,
    length: null,
    width: null,
    height: null,
    is_donatable: 0,
    is_negotiable: "1",
    status: "Active",
    condition: null,
    age_group_id: null,
    brand_id: null,
    category_id: null,
    is_denied: null,
    denied_note: null,
    meta_title: null,
    meta_description: null,
  });
  const [imgContainer, setImgContainer] = useState({
    thumb_image: null,
    front_image: null,
    left_image: null,
    right_image: null,
    back_image: null,
    top_image: null,
  });

  // generate slug URL
  const getSlugURL = (productName) => {
    if (productName) {
      let slug = productName.replace(/[^a-zA-Z0-9]+/g, "-").toLowerCase();
      return slug.replace(/-+/g, "-").replace(/^-|-$/g, "");
    }
  };

  // Remove html tags
  const removeHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  // Check if any field has been changed
  const [dataChanged, setDataChanged] = useState(false);
  useEffect(() => {
    const isDataChanged = Object.values(product).some(
      (value) => value !== null
    );
    setDataChanged(isDataChanged);
  }, [product]);

  // image upload functions start here
  const handleImageUpload = (imgName) => {
    const ele = document.getElementById(imgName);

    ele.addEventListener("change", (event) => {
      const file = event.target.files[0];
      if (file === undefined) return;

      setErrors((prev) => ({
        ...prev,
        [imgName]: "",
      }));

      // Check file extension
      const allowedExtensions = ["png", "jpg", "jpeg", "webp"];
      const extension = file.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(extension)) {
        setErrors((prev) => ({
          ...prev,
          [imgName]:
            "Please upload an image with .png, .jpg, .jpeg, or .webp extension.",
        }));
        resetInputType(imgName);
        alert("returning !allowedExtensions");
        return;
      }

      // Compress and resize image
      compressAndResizeImage(file, imgName);
    });
  };

  const compressAndResizeImage = (file, imgName) => {
    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set dimensions to 600x600
        canvas.width = 600;
        canvas.height = 600;

        // Calculate scaling factors to fit image within 600x600
        const scaleFactor = Math.min(600 / img.width, 600 / img.height);
        const newWidth = img.width * scaleFactor;
        const newHeight = img.height * scaleFactor;

        // Draw image on canvas with new dimensions
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        canvas.toBlob(
          (blob) => {
            // Check compressed image size
            if (blob.size > maxSizeInBytes) {
              setErrors((prev) => ({
                ...prev,
                [imgName]: "Image size must be up to 2 MB after compression.",
              }));
              resetInputType(imgName);
              return;
            }

            // Display compressed image
            const compressedImg = new File([blob], file.name, {
              type: file.type,
            });
            const reader = new FileReader();
            reader.onload = () => {
              setImgContainer((prev) => ({
                ...prev,
                [imgName]: reader.result,
              }));
            };
            reader.readAsDataURL(compressedImg);

            // set compressed image to product state
            setProduct((prev) => ({
              ...prev,
              [imgName]: compressedImg,
            }));
          },
          file.type,
          0.7 // Compression quality (0.7 means 70% quality)
        );
      };
    };

    reader.readAsDataURL(file);
  };

  // prevent page refresh
  const handleFormSubmitKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  // update product API call
  const updateProductFunction = async (formData) => {
    setIsLoading(true);
    formData.append("_method", "PUT");
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellersProductsApi}/${productId}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    const res = await response.json();
    if (res.status === 200) {
      contextObj.setInput("navigation", "true");
      contextObj.setInput("navigationFrom", "UpdateProduct");
      navigate(`${navigation.products}`);
    } else if (res.status === 400 && res.message === "The sku must be unique") {
      setErrors((prev) => ({
        ...prev,
        sku: "The sku must be unique",
      }));
    } else if (res.status === 400) {
      const errors = res.errors;
      const message = res.message;
      let errorMessage = "";

      if (message) {
        showToast("error", res.message);
        setIsLoading(false);
        return;
      }
      if (errors) {
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((message) => {
            errorMessage = message;
          });
        });
      }
      showToast("error", errorMessage);
    } else if (res.status === 404) {
      showToast("error", res.message);
    }
    setIsLoading(false);
  };

  // create product API call
  const createProductFunction = async (formData) => {
    setIsLoading(true);
    const response = await fetch(`${API_HOST}/${ApiEndpoint.createProduct}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const res = await response.json();
    if (res.status === 201) {
      showToast("success", Alert.alertForAddingProduct);
      setProduct({
        name: null,
        description: editorContent,
        thumb_image: null,
        front_image: null,
        left_image: null,
        right_image: null,
        back_image: null,
        top_image: null,
        video_url: null,
        product_price: null,
        current_market_price: null,
        sku: null,
        hsn: null,
        barcode: null,
        weight: null,
        length: null,
        width: null,
        height: null,
        is_donatable: 0,
        is_negotiable: 0,
        status: "Active",
        condition: null,
        age_group_id: null,
        brand_id: null,
        category_id: null,
        is_denied: null,
        denied_note: null,
      });
      setTimeout(() => {
        navigate(`${navigation.products}`);
      }, 3000);
    } else if (res.status === 400) {
      const errors = res.errors;
      let errorMessage = "";
      if (res.message) errorMessage = res.message;
      if (errors) {
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((message) => {
            errorMessage = message;
          });
        });
      }
      if (errorMessage.includes("sku")) {
        setErrors((prev) => ({
          ...prev,
          sku: errorMessage,
        }));
      } else {
        showToast("error", errorMessage);
      }
    } else if (res.status === 500) {
      showToast("error", Alert.alertForInternalServerError);
      window.location.href = navigation.error;
    } else {
      showToast("error", res.message);
    }
    setIsLoading(false);
  };

  const productDataHandleSubmit = (e) => {
    e.preventDefault();
    setProduct((prev) => ({
      ...prev,
      description: removeEmptyHTMLTags(product.description),
    }));
    let hasError = false;

    // name validation
    if (product.name === "" || product.name === null) {
      setErrors((prev) => ({
        ...prev,
        name: Alert.alertForName,
      }));
      hasError = true;
    } else if (product.name.length < 3 || product.name.length > 150) {
      setErrors((prev) => ({
        ...prev,
        name: Alert.alertForNameLength,
      }));
      hasError = true;
    } else {
      setErrors((prev) => ({ ...prev, name: "" }));
    }

    // Description validation
    const emptyHTMLTagsFound = (input) => {
      if (
        /^<\w+><br><\/\w+>/.test(input) ||
        /<\w+><br><\/\w+>$/.test(input) ||
        /^<\w+>\s*<\/\w+>/.test(input)
      ) {
        return true;
      }
      return false;
    };

    if (
      product.status === "Active" &&
      (product.description === null ||
        product.description === "" ||
        product.description === "<p><br></p>")
    ) {
      setErrors((prev) => ({
        ...prev,
        description: Alert.alertForDescription,
      }));
      hasError = true;
    } else if (emptyHTMLTagsFound(product.description)) {
      console.log("product.description =======>", product.description);
      setErrors((prev) => ({
        ...prev,
        description: "Leading / trailing whitespace not allowed",
      }));
      hasError = true;
    } else {
      setErrors((prev) => ({ ...prev, description: "" }));
    }

    // product_price validation
    if (
      product.status === "Active" &&
      (product.product_price == null || product.product_price == "")
    ) {
      setErrors((prev) => ({
        ...prev,
        product_price: Alert.alertForPrice,
      }));
      hasError = true;
    } else if (
      product.product_price !== null &&
      product.product_price !== "" &&
      Number(product.product_price) > 100000
    ) {
      setErrors((prev) => ({
        ...prev,
        product_price: Alert.alertForPriceMaxValue,
      }));
      hasError = true;
    } else if (
      product.product_price !== null &&
      product.product_price !== "" &&
      Number(product.product_price) < 100
    ) {
      setErrors((prev) => ({
        ...prev,
        product_price: Alert.alertForPriceMinValue,
      }));
      hasError = true;
    } else {
      setErrors((prev) => ({ ...prev, product_price: "" }));
    }

    // product current_market_price validation
    if (
      product.status === "Active" &&
      (product.current_market_price == null ||
        product.current_market_price == "")
    ) {
      setErrors((prev) => ({
        ...prev,
        current_market_price: Alert.alertForOriginalPrice,
      }));
      hasError = true;
    } else if (
      product.current_market_price !== null &&
      product.current_market_price !== "" &&
      product.current_market_price > 200000
    ) {
      setErrors((prev) => ({
        ...prev,
        current_market_price: Alert.alertForPriceMaxForOrigionalProduct,
      }));
      hasError = true;
    } else if (
      product.current_market_price !== null &&
      product.current_market_price !== "" &&
      product.current_market_price < 200
    ) {
      setErrors((prev) => ({
        ...prev,
        current_market_price: Alert.alertForPriceMinForOrigionalProduct,
      }));
      hasError = true;
    } else {
      setErrors((prev) => ({ ...prev, current_market_price: "" }));
    }

    // all images validation
    if (
      product.status === "Active" &&
      (product.thumb_image === "null" ||
        product.thumb_image === null ||
        product.thumb_image === undefined)
    ) {
      setErrors((prev) => ({
        ...prev,
        thumb_image: Alert.alertForThumbnail,
      }));
      hasError = true;
    } else {
      setErrors((prev) => ({ ...prev, thumb_image: "" }));
    }
    if (
      product.status === "Active" &&
      (product.front_image === "null" ||
        product.front_image === null ||
        product.front_image === undefined)
    ) {
      setErrors((prev) => ({
        ...prev,
        front_image: Alert.alertForFontImage,
      }));
      hasError = true;
    } else {
      setErrors((prev) => ({ ...prev, front_image: "" }));
    }
    if (
      product.status === "Active" &&
      (product.left_image === "null" ||
        product.left_image === null ||
        product.left_image === undefined)
    ) {
      setErrors((prev) => ({
        ...prev,
        left_image: Alert.alertForLeftImage,
      }));
      hasError = true;
    } else {
      setErrors((prev) => ({ ...prev, left_image: "" }));
    }
    if (
      product.status === "Active" &&
      (product.right_image === "null" ||
        product.right_image === null ||
        product.right_image === undefined)
    ) {
      setErrors((prev) => ({
        ...prev,
        right_image: Alert.alertForRightImge,
      }));
      hasError = true;
    } else {
      setErrors((prev) => ({ ...prev, right_image: "" }));
    }
    if (
      product.status === "Active" &&
      (product.back_image === "null" ||
        product.back_image === null ||
        product.back_image === undefined)
    ) {
      setErrors((prev) => ({
        ...prev,
        back_image: Alert.alertForBackImage,
      }));
      hasError = true;
    } else {
      setErrors((prev) => ({ ...prev, back_image: "" }));
    }
    if (
      product.status === "Active" &&
      (product.top_image === "null" ||
        product.top_image === null ||
        product.top_image === undefined)
    ) {
      setErrors((prev) => ({
        ...prev,
        top_image: Alert.alertForTopImage,
      }));
      hasError = true;
    } else {
      setErrors((prev) => ({ ...prev, top_image: "" }));
    }

    // Product weight validation
    if (
      product.status === "Active" &&
      (product.weight === null || product.weight === "")
    ) {
      setErrors((prev) => ({
        ...prev,
        weight: Alert.alertForWeight,
      }));
      hasError = true;
    } else if (
      product.weight !== null &&
      product.weight !== "" &&
      weightUnit === "kg" &&
      (product.weight < 0.1 || product.weight > 20)
    ) {
      setErrors((prev) => ({
        ...prev,
        weight: Alert.alertForMaxWeightKG,
      }));
      hasError = true;
    } else if (
      product.weight !== null &&
      product.weight !== "" &&
      weightUnit === "gm" &&
      (product.weight < 100 || product.weight > 20000)
    ) {
      setErrors((prev) => ({
        ...prev,
        weight: Alert.alertForMaxWeightGM,
      }));
      hasError = true;
    } else {
      setErrors((prev) => ({ ...prev, weight: "" }));
    }
    let productConvertedWeight = "";
    if (weightUnit === "gm") {
      productConvertedWeight = convertWeightGmToKg(product.weight);
    } else {
      productConvertedWeight = product.weight;
    }

    // Product length validation
    if (
      product.status === "Active" &&
      (product.length === null || product.length === "")
    ) {
      setErrors((prev) => ({
        ...prev,
        length: Alert.alertForLength,
      }));
      hasError = true;
    } else if (
      product.length != "" &&
      product.length != null &&
      !isValidNumeric(product.length)
    ) {
      setErrors((prev) => ({
        ...prev,
        length: Alert.invalidFormat,
      }));
      hasError = true;
    } else if (
      product.length != "" &&
      product.length != null &&
      (product.length === 0 ||
        product.length === "0" ||
        product.length < 0.1 ||
        product.length > 300)
    ) {
      setErrors((prev) => ({
        ...prev,
        length: Alert.valBetweenPoint1To300,
      }));
      hasError = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        length: "",
      }));
    }

    if (
      product.status === "Active" &&
      (product.width === null || product.width === "")
    ) {
      setErrors((prev) => ({
        ...prev,
        width: Alert.alertForWidth,
      }));
      hasError = true;
    } else if (
      product.width != "" &&
      product.width != null &&
      !isValidNumeric(product.width)
    ) {
      setErrors((prev) => ({
        ...prev,
        width: Alert.invalidFormat,
      }));
      hasError = true;
    } else if (
      product.width != "" &&
      product.width != null &&
      !isValidNumeric &&
      (product.width === 0 ||
        product.width === "0" ||
        product.width < 0.1 ||
        product.width > 300)
    ) {
      setErrors((prev) => ({
        ...prev,
        width: Alert.valBetweenPoint1To300,
      }));
      hasError = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        width: "",
      }));
    }

    if (
      product.status === "Active" &&
      (product.height === null || product.height === "")
    ) {
      setErrors((prev) => ({
        ...prev,
        height: Alert.alertForHeight,
      }));
      hasError = true;
    } else if (
      product.height != "" &&
      product.height != null &&
      !isValidNumeric(product.height)
    ) {
      setErrors((prev) => ({
        ...prev,
        height: Alert.invalidFormat,
      }));
      hasError = true;
    } else if (
      product.height != "" &&
      product.height != null &&
      (product.height === 0 ||
        product.height === "0" ||
        product.height < 0.1 ||
        product.height > 300)
    ) {
      setErrors((prev) => ({
        ...prev,
        height: Alert.valBetweenPoint1To300,
      }));
      hasError = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        height: "",
      }));
    }

    // Video URL validation
    let isValidVideoLink = (video) => {
      if (video === null) return;
      if (video.length === 0) {
        return true;
      } else {
        let videoLinkRegex =
          /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be|vimeo\.com|dailymotion\.com|vine\.co|v\.youku\.com|tiktok\.com|facebook\.com)\/.+/.test(
            video
          );
        return videoLinkRegex;
      }
    };
    let validVideo = isValidVideoLink(product.video_url);
    if (product.status === "Active" && validVideo === false) {
      setErrors((prev) => ({
        ...prev,
        video_url: Alert.alertForVideoUrl,
        has_error: true,
      }));
    } else {
      setErrors((prev) => ({ ...prev, video_url: "" }));
    }

    // product condition validation
    if (
      product.status === "Active" &&
      (product.condition === null || product.condition === "Select")
    ) {
      setErrors((prev) => ({
        ...prev,
        condition: Alert.alertForCondition,
      }));
      hasError = true;
    } else {
      setErrors((prev) => ({ ...prev, condition: "" }));
    }

    // Applicable age group validation
    if (
      product.status === "Active" &&
      (product.age_group_id === null || product.age_group_id === "Select")
    ) {
      setErrors((prev) => ({
        ...prev,
        age_group_id: Alert.alertForAge,
      }));
      hasError = true;
    } else {
      setErrors((prev) => ({ ...prev, age_group_id: "" }));
    }

    // product category validation
    if (
      product.status === "Active" &&
      (product.category_id === null || product.category_id === "Select")
    ) {
      setErrors((prev) => ({
        ...prev,
        category_id: Alert.alertForCategory,
      }));
      hasError = true;
    } else {
      setErrors((prev) => ({ ...prev, category_id: "" }));
    }

    // if any field has error, break the function call from here
    if (hasError) return;

    // mandatory fields for update and create Product
    const formData = new FormData();
    formData.append("status", product.status);
    formData.append("name", product.name);

    // optional fields
    if (product.description) {
      // formData.append("description", product.description);
      const descriptionWithAmpersand = convertToAmpersand(product.description);
      formData.append("description", descriptionWithAmpersand);
    }
    if (product.product_price) {
      formData.append("product_price", product.product_price);
    }
    if (product.current_market_price) {
      formData.append("current_market_price", product.current_market_price);
    }
    if (product.sku) {
      formData.append("sku", product.sku);
    }
    if (product.hsn) {
      formData.append("hsn", product.hsn);
    }
    if (product.barcode) {
      formData.append("barcode", product.barcode);
    }
    if (product.video_url) {
      formData.append("video_url", product.video_url);
    }
    if (product.length) {
      formData.append("length", product.length);
    }
    if (product.width) {
      formData.append("width", product.width);
    }
    if (product.height) {
      formData.append("height", product.height);
    }
    if (product.weight) {
      formData.append("weight", productConvertedWeight);
    }
    if (product.is_donatable) {
      formData.append("is_donatable", product.is_donatable);
    }
    if (product.is_negotiable) {
      formData.append("is_negotiable", product.is_negotiable);
    }
    if (product.brand_id && product.brand_id !== "Select") {
      formData.append("brand_id", product.brand_id);
    }
    if (product.category_id && product.category_id !== "Select") {
      formData.append("category_id", product.category_id);
    }
    if (product.condition && product.condition !== "Select") {
      formData.append("condition", product.condition);
    }
    if (product.age_group_id && product.age_group_id !== "Select") {
      formData.append("age_group_id", product.age_group_id);
    }

    // Add meta title and description
    if (product.meta_title) {
      formData.append("meta_title", product.meta_title);
    } else {
      formData.append(
        "meta_title",
        product.name.substring(0, metaTitleMaxLength)
      );
    }
    if (product.meta_description) {
      formData.append("meta_description", product.meta_description);
    } else if (product.description) {
      formData.append("meta_description", removeHtmlTags(product.description).substring(
        0,
        metaDescMaxLength
      ));
    }

    if (pageName === "Add") {
      if (product.status === "Active") {
        formData.append("thumb_image", product.thumb_image);
        formData.append("front_image", product.front_image);
        formData.append("left_image", product.left_image);
        formData.append("right_image", product.right_image);
        formData.append("back_image", product.back_image);
        formData.append("top_image", product.top_image);

        // append only image(s) while product status is Draft
      } else if (product.status === "Draft") {
        if (product.thumb_image)
          formData.append("thumb_image", product.thumb_image);
        if (product.front_image)
          formData.append("front_image", product.front_image);
        if (product.left_image)
          formData.append("left_image", product.left_image);
        if (product.right_image)
          formData.append("right_image", product.right_image);
        if (product.back_image)
          formData.append("back_image", product.back_image);
        if (product.top_image) formData.append("top_image", product.top_image);
      }

      createProductFunction(formData);
    } else if (pageName === "Edit") {
      formData.append("thumb_image", product.thumb_image);
      formData.append("front_image", product.front_image);
      formData.append("left_image", product.left_image);
      formData.append("right_image", product.right_image);
      formData.append("back_image", product.back_image);
      formData.append("top_image", product.top_image);

      updateProductFunction(formData);
    }
  };

  const handleChange = (event) => {
    const { name, checked } = event.target;
    const value = checked ? "1" : "0";
    setProduct((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (
      name === "length" ||
      name === "width" ||
      name === "height" ||
      name === "weight"
    ) {
      // number only validation
      const newValue = value.replace(/[^0-9.]/g, "");
      if (newValue !== "") {
        setIsCheckedShippingCost(false);
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
      setProduct((prev) => ({
        ...prev,
        [name]: newValue,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
      setProduct((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  const handleDescriptionChange = (value) => {
    setErrors((prev) => ({
      ...prev,
      description: "",
      has_error: true,
    }));
    setEditorContent(value);
    setProduct((prev) => ({
      ...prev,
      description: value,
    }));


    
  };

  // character "e"  not allowed in input types numbers
  const handleKeyPress = (e) => {
    if (e.keyCode === 69 || e.key === "e" || e.key === "+" || e.key === "-") {
      e.preventDefault();
    }
  };

  return (
    <div className="container">
      <div className="container-heading">
        <h2
          className="cursor-pointer"
          onClick={() => {
            navigate(`${navigation.products}`);
          }}
        >
          <span>
            <svg
              width="15"
              height="10"
              viewBox="0 0 15 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 4.11623V5.884H3.44772L6.36769 8.75001L5.09417 10L0 5.00003L5.09417 0L6.36769 1.25L3.44751 4.11623H15Z"
                fill="#939393"
              />
            </svg>
          </span>
          {`${pageName} Product`}
        </h2>
      </div>

      <div className="product-container">
        {/* Product rejected note */}
        {product.is_denied === 1 && product.denied_note && (
          <div className="product-denied-note">
            <p>
              <span>Rejection note</span>: {product.denied_note}
            </p>
          </div>
        )}

        <form onSubmit={productDataHandleSubmit}>
          <div className="product-input-container">
            <div className="product-left-area">
              {/* name and description div */}
              <div>
                <div className="single-row">
                  <label>
                    Title <span className="red"> *</span>
                  </label>
                  <input
                    id="product-create-update-name"
                    type="text"
                    name="name"
                    value={product.name}
                    onChange={handleInputChange}
                    className="form-control"
                    ref={inputRef}
                  />
                  <label id="product_name_error" className="red">
                    {errors.name}
                  </label>
                </div>
                <div className="single-row">
                  <label>
                    Description
                    {product.status === "Active" && (
                      <span id="title_Name-error" className="red">
                        {" "}
                        *
                      </span>
                    )}
                  </label>
                  <ReactQuill
                    id="product-create-update-description"
                    className="custom-react-quill"
                    name="description"
                    value={product.description}
                    onChange={handleDescriptionChange}
                    theme="snow"
                    style={{ height: "fitContent" }}
                  />
                  <label id="product_description_error" className="red">
                    {errors.description}
                  </label>
                </div>
              </div>

              {/* images and video url div */}
              <div>
                <div className="images-instruction-container">
                  <div className="mediaHeadingDiv">
                    <p
                      className="imges-guide"
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      Media
                      <span>
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.5 1.1C4.33305 1.1 3.21389 1.56357 2.38873 2.38873C1.56357 3.21389 1.1 4.33305 1.1 5.5C1.1 6.66695 1.56357 7.78611 2.38873 8.61127C3.21389 9.43643 4.33305 9.9 5.5 9.9C6.66695 9.9 7.78611 9.43643 8.61127 8.61127C9.43643 7.78611 9.9 6.66695 9.9 5.5C9.9 4.33305 9.43643 3.21389 8.61127 2.38873C7.78611 1.56357 6.66695 1.1 5.5 1.1ZM0 5.5C0 2.46235 2.46235 0 5.5 0C8.53765 0 11 2.46235 11 5.5C11 8.53765 8.53765 11 5.5 11C2.46235 11 0 8.53765 0 5.5Z"
                            fill="#A4A4A4"
                          />
                          <path
                            d="M5.50078 4.4002C5.64665 4.4002 5.78654 4.45814 5.88969 4.56129C5.99283 4.66443 6.05078 4.80433 6.05078 4.9502V8.2502C6.05078 8.39606 5.99283 8.53596 5.88969 8.6391C5.78654 8.74225 5.64665 8.8002 5.50078 8.8002C5.35491 8.8002 5.21502 8.74225 5.11187 8.6391C5.00873 8.53596 4.95078 8.39606 4.95078 8.2502V4.9502C4.95078 4.80433 5.00873 4.66443 5.11187 4.56129C5.21502 4.45814 5.35491 4.4002 5.50078 4.4002ZM6.32578 3.0252C6.32578 3.244 6.23886 3.45384 6.08414 3.60856C5.92943 3.76328 5.71958 3.8502 5.50078 3.8502C5.28198 3.8502 5.07214 3.76328 4.91742 3.60856C4.7627 3.45384 4.67578 3.244 4.67578 3.0252C4.67578 2.80639 4.7627 2.59655 4.91742 2.44183C5.07214 2.28711 5.28198 2.2002 5.50078 2.2002C5.71958 2.2002 5.92943 2.28711 6.08414 2.44183C6.23886 2.59655 6.32578 2.80639 6.32578 3.0252Z"
                            fill="#A4A4A4"
                          />
                        </svg>
                      </span>
                    </p>
                  </div>
                  {tooltipVisible && (
                    <div className="images-tooltip">
                      <p>* Allowed extensions [.png, .jpg, .jpeg, .webp]</p>
                      <p>* Thumbnail must be 1:1 ratio</p>
                      <p>* Size upto 2 MB</p>
                    </div>
                  )}

                  {/* logic for image uploading starts from here */}
                  <div className="product-images">
                    <div className="thumb-image">
                      <label>
                        Thumbnail
                        {product.status === "Active" && (
                          <span className="red"> *</span>
                        )}
                      </label>
                      <div
                        onClick={(e) => {
                          handleImageUpload("thumb_image");
                        }}
                        id="thumb_imageUploaded_For_Sellers"
                        className={
                          "image-dropzone " +
                          (imgContainer.thumb_image && "dropzone-uploaded")
                        }
                      >
                        {imgContainer.thumb_image ? (
                          <>
                            <img
                              className="product-image"
                              src={imgContainer.thumb_image}
                              loading="lazy"
                            />
                            <div className="img-btn-action">
                              <div className="img-btns">
                                {isBase64Image(imgContainer.thumb_image) && (
                                  <button
                                    type="button"
                                    className="image-edit-icon"
                                    onClick={() => {
                                      loadImgOnModal("thumb_image");
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faEdit} />
                                    <span>Edit</span>
                                  </button>
                                )}
                                <button
                                  type="button"
                                  className="image-remove-icon"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeImageFromContainer("thumb_image");
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                  Remove
                                </button>
                              </div>
                            </div>
                          </>
                        ) : (
                          <p style={{ position: "absolute" }}>
                            Click to upload
                          </p>
                        )}
                        <input
                          id="thumb_image"
                          type="file"
                          accept=".png, .jpg, .jpeg, .webp"
                          name="thumb_image"
                          onChange={handleInputChange}
                          // hidden
                          style={{ opacity: 0 }}
                        />
                      </div>
                      <label id="product_thumb_image_error" className="red">
                        {errors.thumb_image}
                      </label>
                    </div>
                    <div>
                      <label>
                        Front View
                        {product.status === "Active" && (
                          <span className="red"> *</span>
                        )}
                      </label>
                      <div
                        onClick={(e) => {
                          handleImageUpload("front_image");
                        }}
                        id="font_imageUploaded_For_Sellers"
                        className={
                          "image-dropzone " +
                          (imgContainer.front_image && "dropzone-uploaded")
                        }
                      >
                        {imgContainer.front_image ? (
                          <>
                            <img
                              className="product-image"
                              src={imgContainer.front_image}
                              loading="lazy"
                            />
                            <div className="img-btn-action">
                              <div className="img-btns">
                                {isBase64Image(imgContainer.front_image) && (
                                  <button
                                    type="button"
                                    className="image-edit-icon"
                                    onClick={() => {
                                      loadImgOnModal("front_image");
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faEdit} />
                                    <span>Edit</span>
                                  </button>
                                )}
                                <button
                                  type="button"
                                  className="image-remove-icon"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeImageFromContainer("front_image");
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                  Remove
                                </button>
                              </div>
                            </div>
                          </>
                        ) : (
                          <p style={{ position: "absolute" }}>
                            Click to upload
                          </p>
                        )}
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg, .webp"
                          name="front_image"
                          id="front_image"
                          onChange={handleInputChange}
                          //hidden
                          style={{ opacity: 0 }}
                        />
                      </div>
                      <label id="product_front_image_error" className="red">
                        {errors.front_image}
                      </label>
                    </div>
                    <div>
                      <label>
                        Left View
                        {product.status === "Active" && (
                          <span className="red"> *</span>
                        )}
                      </label>
                      <div
                        onClick={(e) => {
                          handleImageUpload("left_image");
                        }}
                        id="left_imageUploaded_For_Sellers"
                        className={
                          "image-dropzone " +
                          (imgContainer.left_image && "dropzone-uploaded")
                        }
                      >
                        {imgContainer.left_image ? (
                          <>
                            <img
                              className="product-image"
                              src={imgContainer.left_image}
                              loading="lazy"
                            />
                            <div className="img-btn-action">
                              <div className="img-btns">
                                {isBase64Image(imgContainer.left_image) && (
                                  <button
                                    type="button"
                                    className="image-edit-icon"
                                    onClick={() => {
                                      loadImgOnModal("left_image");
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faEdit} />
                                    <span>Edit</span>
                                  </button>
                                )}
                                <button
                                  type="button"
                                  className="image-remove-icon"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeImageFromContainer("left_image");
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                  Remove
                                </button>
                              </div>
                            </div>
                          </>
                        ) : (
                          <p style={{ position: "absolute" }}>
                            Click to upload
                          </p>
                        )}
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg, .webp"
                          name="left_image"
                          id="left_image"
                          onChange={handleInputChange}
                          // hidden
                          style={{ opacity: 0 }}
                        />
                      </div>
                      <label id="product_left_image_error" className="red">
                        {errors.left_image}
                      </label>
                    </div>
                    <div>
                      <label>
                        Right View
                        {product.status === "Active" && (
                          <span className="red"> *</span>
                        )}
                      </label>
                      <div
                        onClick={(e) => {
                          handleImageUpload("right_image");
                        }}
                        id="right_imageUploaded_For_Sellers"
                        className={
                          "image-dropzone " +
                          (imgContainer.right_image && "dropzone-uploaded")
                        }
                      >
                        {imgContainer.right_image ? (
                          <>
                            <img
                              className="product-image"
                              src={imgContainer.right_image}
                              loading="lazy"
                            />
                            <div className="img-btn-action">
                              <div className="img-btns">
                                {isBase64Image(imgContainer.right_image) && (
                                  <button
                                    type="button"
                                    className="image-edit-icon"
                                    onClick={() => {
                                      loadImgOnModal("right_image");
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faEdit} />
                                    <span>Edit</span>
                                  </button>
                                )}
                                <button
                                  type="button"
                                  className="image-remove-icon"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeImageFromContainer("right_image");
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                  Remove
                                </button>
                              </div>
                            </div>
                          </>
                        ) : (
                          <p style={{ position: "absolute" }}>
                            Click to upload
                          </p>
                        )}
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg, .webp"
                          name="right_image"
                          id="right_image"
                          onChange={handleInputChange}
                          //hidden
                          style={{ opacity: 0 }}
                        />
                      </div>
                      <label id="product_right_image_error" className="red">
                        {errors.right_image}
                      </label>
                    </div>
                    <div>
                      <label>
                        Back View
                        {product.status === "Active" && (
                          <span className="red"> *</span>
                        )}
                      </label>
                      <div
                        onClick={(e) => {
                          handleImageUpload("back_image");
                        }}
                        id="Back_imageUploaded_For_Sellers"
                        className={
                          "image-dropzone " +
                          (imgContainer.back_image && "dropzone-uploaded")
                        }
                      >
                        {imgContainer.back_image ? (
                          <>
                            <img
                              className="product-image"
                              src={imgContainer.back_image}
                              loading="lazy"
                            />
                            <div className="img-btn-action">
                              <div className="img-btns">
                                {isBase64Image(imgContainer.back_image) && (
                                  <button
                                    type="button"
                                    className="image-edit-icon"
                                    onClick={() => {
                                      loadImgOnModal("back_image");
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faEdit} />
                                    <span>Edit</span>
                                  </button>
                                )}
                                <button
                                  type="button"
                                  className="image-remove-icon"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeImageFromContainer("back_image");
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                  Remove
                                </button>
                              </div>
                            </div>
                          </>
                        ) : (
                          <p style={{ position: "absolute" }}>
                            Click to upload
                          </p>
                        )}
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg, .webp"
                          name="back_image"
                          id="back_image"
                          onChange={handleInputChange}
                          //hidden
                          style={{ opacity: 0 }}
                        />
                      </div>
                      <label id="product_back_image_error" className="red">
                        {errors.back_image}
                      </label>
                    </div>
                    <div>
                      <label>
                        Top View
                        {product.status === "Active" && (
                          <span className="red"> *</span>
                        )}
                      </label>
                      <div
                        onClick={(e) => {
                          handleImageUpload("top_image");
                        }}
                        id="top_imageUploaded_For_Sellers"
                        className={
                          "image-dropzone " +
                          (imgContainer.top_image && "dropzone-uploaded")
                        }
                      >
                        {imgContainer.top_image ? (
                          <>
                            <img
                              className="product-image"
                              src={imgContainer.top_image}
                              loading="lazy"
                            />
                            <div className="img-btn-action">
                              <div className="img-btns">
                                {isBase64Image(imgContainer.top_image) && (
                                  <button
                                    type="button"
                                    className="image-edit-icon"
                                    onClick={() => {
                                      loadImgOnModal("top_image");
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faEdit} />
                                    <span>Edit</span>
                                  </button>
                                )}
                                <button
                                  type="button"
                                  className="image-remove-icon"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeImageFromContainer("top_image");
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                  Remove
                                </button>
                              </div>
                            </div>
                          </>
                        ) : (
                          <p style={{ position: "absolute" }}>
                            Click to upload
                          </p>
                        )}
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg, .webp"
                          name="top_image"
                          id="top_image"
                          onChange={handleInputChange}
                          // hidden
                          style={{ opacity: 0 }}
                        />
                      </div>
                      <label id="products_top_image_error" className="red">
                        {errors.top_image}
                      </label>
                    </div>
                  </div>
                  <div className="single-row">
                    <label>Video URL</label>
                    <input
                      id="product-create-update-video-url"
                      type="text"
                      name="video_url"
                      value={product.video_url}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                    <label id="product_video_url_error" className="red">
                      {errors.video_url}
                    </label>
                  </div>
                </div>
              </div>

              {/* price and est.origional price div */}
              <div id="priceAndEsstimtedPriceDiv">
                <div className="PriceHeadingDiv">
                  <p className="pricingPara">Pricing</p>
                </div>
                <div className="product-prices">
                  <div className="col">
                    <label>
                      Product Price (₹)
                      {product.status === "Active" && (
                        <span className="red"> *</span>
                      )}
                    </label>
                    <div className="product-price">
                      <input
                        id="product-create-update-product-price"
                        type="number"
                        name="product_price"
                        value={product.product_price}
                        onChange={handleInputChange}
                        onWheel={handleWheel}
                        className="form-control"
                        min="0"
                        onKeyPress={handleKeyPress}
                      />
                      <span className="question-circle-icon-span">
                        <FontAwesomeIcon
                          className="question-circle-icon"
                          icon={faQuestionCircle}
                        />
                      </span>
                      <p className="product-tooltip">
                        Shipping cost will be deducted from the product price
                        during payment.
                      </p>
                    </div>
                    <label id="product_price_error" className="red">
                      {errors.product_price}
                    </label>
                  </div>

                  <div className="col ">
                    <label>
                      Estimated Original Price (₹)
                      {product.status === "Active" && (
                        <span className="red"> *</span>
                      )}
                    </label>
                    <input
                      id="product-create-update-estimated-original-price"
                      type="number"
                      name="current_market_price"
                      value={product.current_market_price}
                      onChange={handleInputChange}
                      onWheel={handleWheel}
                      className="form-control"
                      min="0"
                      onKeyPress={handleKeyPress}
                    />
                    <label id="current_market_price_error" className="red">
                      {errors.current_market_price}
                    </label>
                  </div>
                </div>
              </div>

              {/* SKU or Barcode div */}
              <div id="sku_barcode_container">
                <div className="barcodeforProductBox">
                  <span>
                    <input
                      id="product-create-update-sku-hsn-barcode-checkbox"
                      type="checkbox"
                      checked={isChecked}
                      onClick={handleCheckboxChange}
                    />
                  </span>
                  <label onClick={handleCheckboxChange}>
                    This product has SKU, HSN or Barcode
                  </label>
                </div>
                {isChecked && (
                  <div className="skuAndBarcodeInputFeild">
                    <div className="col">
                      <label>SKU</label>
                      <input
                        id="product-create-update-sku"
                        name="sku"
                        value={product.sku}
                        onChange={handleInputChange}
                        type="text"
                        className="form-control"
                        onKeyPress={handleKeyPress}
                      />
                      <label id="sku_error" className="red">
                        {errors.sku}
                      </label>
                    </div>
                    <div className="col">
                      <label>HSN</label>
                      <input
                        id="product-create-update-hsn"
                        name="hsn"
                        value={product.hsn}
                        onChange={handleInputChange}
                        type="text"
                        className="form-control"
                        onKeyPress={handleKeyPress}
                      />
                    </div>
                    <div className="col">
                      <label>Barcode (ISBN, UPC, GTIN, etc.)</label>
                      <input
                        id="product-create-update-barcode"
                        name="barcode"
                        value={product.barcode}
                        onChange={handleInputChange}
                        type="text"
                        className="form-control"
                        onKeyPress={handleKeyPress}
                      />
                    </div>
                  </div>
                )}
              </div>

              {/* shipping details(height, width,length and weight) div */}
              <div id="shipping_details_section">
                <p className="productShippingPara">Shipping</p>
                <div className="select-row ">
                  <div className="weightKgDivForMobile">
                    <div className="col" style={{ marginRight: "5px" }}>
                      <label>
                        Weight
                        {product.status === "Active" && (
                          <span className="red"> *</span>
                        )}
                      </label>
                      <input
                        id="product-create-update-weight"
                        type="text"
                        name="weight"
                        value={product.weight}
                        onChange={handleInputChange}
                        onWheel={handleWheel}
                        className="form-control"
                        onKeyPress={handleKeyPress}
                      />
                      <label id="weight_error" className="red">
                        {errors.weight}
                      </label>
                    </div>
                    <select
                      id="product-create-update-weight-type"
                      className="productWeightSelect"
                      value={weightUnit}
                      onChange={weightUnitsChangeHandler}
                    >
                      <option id="sellers-weightType-kilogram" value="kg">
                        kg
                      </option>
                      <option id="sellers-weightType-gram" value="gm">
                        gm
                      </option>
                    </select>
                  </div>
                  <div className="col">
                    <label>
                      Length (cm)
                      {product.status === "Active" && (
                        <span className="red"> *</span>
                      )}
                    </label>
                    <input
                      id="product-create-update-length"
                      type="text"
                      name="length"
                      value={product.length}
                      onChange={handleInputChange}
                      onWheel={handleWheel}
                      className="form-control"
                      onKeyPress={handleKeyPress}
                      min="0"
                    />
                    <label id="length_error" className="red">
                      {errors.length}
                    </label>
                  </div>
                  <div className="col ">
                    <label>
                      Width (cm)
                      {product.status === "Active" && (
                        <span className="red"> *</span>
                      )}
                    </label>
                    <input
                      id="product-create-update-width"
                      type="text"
                      name="width"
                      value={product.width}
                      onChange={handleInputChange}
                      onWheel={handleWheel}
                      className="form-control"
                      onKeyPress={handleKeyPress}
                      min="0"
                    />
                    <label id="width_error" className="red">
                      {errors.width}
                    </label>
                  </div>
                  <div className="col ">
                    <label>
                      Height (cm)
                      {product.status === "Active" && (
                        <span className="red"> *</span>
                      )}
                    </label>
                    <input
                      id="product-create-update-height"
                      type="text"
                      name="height"
                      value={product.height}
                      onChange={handleInputChange}
                      onWheel={handleWheel}
                      className="form-control"
                      onKeyPress={handleKeyPress}
                      min="0"
                    />
                    <label id="height_error" className="red">
                      {errors.height}
                    </label>
                  </div>
                </div>
              </div>

              {/* Get estimated shipping cost */}
              <div>
                <div className="barcodeforProductBox">
                  <label>
                    <input
                      id="product-create-update-calculate-shipping-cost-checkbox"
                      type="checkbox"
                      checked={isCheckedShippingCost}
                      onClick={handleCheckboxChangeShippingCost}
                    />
                    <span>Calculate estimated shipping cost</span>
                  </label>
                </div>
                {isCheckedShippingCost && (
                  <>
                    <div className="skuAndBarcodeInputFeild">
                      <div className="col">
                        <label>Pincode</label>
                        <select
                          id="product-create-update-custom-pincode-select"
                          className="form-control editable-select"
                          value={selectedValue}
                          onChange={handleChangeShippingCost}
                        >
                          <option value="">Select</option>
                          {shippingCostPincode && (
                            <option value={shippingCostPincode}>
                              {shippingCostPincode}
                            </option>
                          )}

                          <option value="custom" className="custom-pincode">
                            Custom
                          </option>
                        </select>
                      </div>
                      {enableCustomPincode && (
                        <div className="col">
                          <label htmlFor="">Enter your pincode</label>
                          <input
                            id="product-create-update-pincode"
                            type="text"
                            maxLength="6"
                            className="form-control"
                            onChange={() => {
                              setErrors((prev) => ({
                                ...prev,
                                estimated_shipping_cost: "",
                              }));
                            }}
                            onInput={(e) => {
                              const inputValue = e.target.value;
                              e.target.value = inputValue.replace(
                                /[^0-9]/g,
                                ""
                              );
                              setShippingCostCustomPincode(inputValue);
                              setEstimatedShippingCost("");
                            }}
                          />
                        </div>
                      )}
                      {shippingCostLoading ? (
                        <div className="col margin-top-25">
                          <RollingSvg className="shipping-cost-rolling" />
                        </div>
                      ) : (
                        <div className="col">
                          <label>Estimated cost (₹)</label>
                          <input
                            id="product-create-update-shipping-cost"
                            style={{ backgroundColor: "#e5e5e5" }}
                            type="text"
                            className="form-control"
                            disabled
                            value={estimatedShippingCost}
                          />
                        </div>
                      )}
                    </div>
                    {errors.estimated_shipping_cost && (
                      <label id="estimated_shipping_cost_error" className="red">
                        {errors.estimated_shipping_cost}
                      </label>
                    )}
                  </>
                )}
              </div>

              {/* give permissions checkbox div  */}
              <div htmlFor="permission checkbox line">
                <div>
                  <label
                    className="checkbox-line"
                    style={{ marginBottom: "10px" }}
                  >
                    <input
                      id="product-create-update-negotiable-checkbox"
                      type="checkbox"
                      name="is_negotiable"
                      checked={product.is_negotiable === "1"}
                      onChange={handleChange}
                    />{" "}
                    <span>
                      Give permission to the reviewer for adjust the effective
                      product price?
                    </span>
                  </label>
                </div>
                <div>
                  <label className="checkbox-line">
                    <input
                      id="product-create-update-donatable-checkbox"
                      type="checkbox"
                      name="is_donatable"
                      checked={product.is_donatable === "1"}
                      onChange={handleChange}
                    />{" "}
                    <span>Are you interested to donate this product?</span>
                  </label>
                </div>
              </div>

              {/* Product SEO */}
              <div>
                <div className="small-container-heading">
                  <p className="productShippingPara">Search Engine Listing</p>
                  <button
                    id="blue-edit-btn-for-meta"
                    className="blue-edit-btn"
                    type="button"
                    onClick={() => {
                      setOpenSeoEdit(!openSeoEdit);
                    }}
                  >
                    {openSeoEdit ? "Close" : "Edit"}
                  </button>
                </div>

                <div className="seo-read">
                  <p className="seo-read-title">
                    {product.meta_title
                      ? product.meta_title
                      : product.name &&
                        product.name.substring(0, metaTitleMaxLength)}
                  </p>
                  <p className="seo-read-url">
                    {BUYER_APP}/products/
                    {getSlugURL(product.name)}...
                  </p>
                  <p className="seo-read-description">
                    {product.meta_description
                      ? product.meta_description
                      : product.description &&
                        removeHtmlTags(product.description).substring(
                          0,
                          metaDescMaxLength
                        )}
                  </p>
                </div>

                <div
                  className={`product-seo-input ${openSeoEdit ? "" : "close"}`}
                >
                  <div className="single-row">
                    <label className="meta-label">
                      <span>Meta Title</span>
                      <span>
                        {metaTitleRemaining}/{metaTitleMaxLength}
                      </span>
                    </label>
                    <input
                      value={product.meta_title}
                      onChange={(e) => {
                        e.preventDefault();
                        handleInputChange(e);
                        const length = e.target.value.length;
                        setMetaTitleRemaining(metaTitleMaxLength - length);
                      }}
                      className="form-control"
                      type="text"
                      name="meta_title"
                      minLength={3}
                      maxLength={metaTitleMaxLength}
                    />
                  </div>
                  <div className="single-row">
                    <label className="meta-label">
                      <span>Meta Description</span>
                      <span>
                        {metaDescRemaining}/{metaDescMaxLength}
                      </span>
                    </label>
                    <textarea
                      value={product.meta_description}
                      name="meta_description"
                      id="meta_description"
                      className="form-control meta-description-input"
                      onChange={(e) => {
                        e.preventDefault();
                        handleInputChange(e);
                        const length = e.target.value.length;
                        setMetaDescRemaining(metaDescMaxLength - length);
                      }}
                      minLength={3}
                      maxLength={metaDescMaxLength}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-right-area">
              {/* status of product box */}
              <div>
                <label>
                  Status
                  <span className="red"> *</span>
                </label>
                <select
                  id="product-create-update-status-select"
                  className="form-control-select-input"
                  value={product.status}
                  onChange={(e) => {
                    const val = e.target.value;
                    setProduct((prev) => ({
                      ...prev,
                      status: val,
                    }));
                  }}
                >
                  <option value="Active">Active</option>
                  <option value="Draft">Draft</option>
                </select>
              </div>

              {/* Product organization */}
              <div>
                <p className="productOrgPara">Product organization</p>
                <div>
                  <div className="col">
                    <label>
                      Condition
                      {product.status === "Active" && (
                        <span className="red"> *</span>
                      )}
                    </label>
                    <select
                      id="product-create-update-condition-select"
                      name="condition"
                      value={product.condition}
                      onChange={handleInputChange}
                      className="form-control-select-input"
                    >
                      {conditionData.length === 0 ? (
                        <option>No data</option>
                      ) : (
                        <>
                          <option>Select</option>
                          {conditionData.map((conditions) => (
                            <option
                              id="sellers-productsConditions-options"
                              key={conditions}
                              value={conditions}
                            >
                              {conditions}
                            </option>
                          ))}
                        </>
                      )}
                    </select>
                    <label id="condition_error" className="red">
                      {errors.condition}
                    </label>
                  </div>
                  <div className="col">
                    <label>
                      Applicable Age Group
                      {product.status === "Active" && (
                        <span className="red"> *</span>
                      )}
                    </label>
                    <select
                      id="product-create-update-applicable-age-group-select"
                      className="form-control-select-input"
                      name="age_group_id"
                      value={product.age_group_id}
                      onChange={handleInputChange}
                    >
                      {applicableAgeGroupData.length === 0 ? (
                        <option>No data</option>
                      ) : (
                        <>
                          <option>Select</option>
                          {applicableAgeGroupData.map((ageGroup) => (
                            <option
                              id="sellers-productApplicableAgeOptions"
                              key={ageGroup.id}
                              value={ageGroup.id}
                            >
                              {ageGroup.title}
                            </option>
                          ))}
                        </>
                      )}
                    </select>
                    <label id="age_group_error" className="red">
                      {errors.age_group_id}
                    </label>
                  </div>
                  <div className="col">
                    <label>Brand</label>
                    <select
                      id="product-create-update-brand-select"
                      className="form-control-select-input"
                      name="brand_id"
                      value={product.brand_id}
                      onChange={handleInputChange}
                    >
                      {brandData.length === 0 ? (
                        <option>No data</option>
                      ) : (
                        <>
                          <option>Select</option>
                          {brandData.map((brands) => (
                            <option
                              id="sellers-productBrandsOptions"
                              key={brands.id}
                              value={brands.id}
                            >
                              {brands.name}
                            </option>
                          ))}
                        </>
                      )}
                    </select>
                  </div>
                  <div className="col">
                    <div className="mediaHeadingDiv">
                      <p
                        className="imges-guide category-spain-guide"
                        onClick={handleCategoryDetailsPopup}
                      >
                        <label>
                          Category
                          {product.status === "Active" && (
                            <span className="red"> *</span>
                          )}
                        </label>
                        <span className="Category_information_tag">
                          <svg
                            width="11"
                            height="11"
                            viewBox="0 0 11 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.5 1.1C4.33305 1.1 3.21389 1.56357 2.38873 2.38873C1.56357 3.21389 1.1 4.33305 1.1 5.5C1.1 6.66695 1.56357 7.78611 2.38873 8.61127C3.21389 9.43643 4.33305 9.9 5.5 9.9C6.66695 9.9 7.78611 9.43643 8.61127 8.61127C9.43643 7.78611 9.9 6.66695 9.9 5.5C9.9 4.33305 9.43643 3.21389 8.61127 2.38873C7.78611 1.56357 6.66695 1.1 5.5 1.1ZM0 5.5C0 2.46235 2.46235 0 5.5 0C8.53765 0 11 2.46235 11 5.5C11 8.53765 8.53765 11 5.5 11C2.46235 11 0 8.53765 0 5.5Z"
                              fill="#A4A4A4"
                            />
                            <path
                              d="M5.50078 4.4002C5.64665 4.4002 5.78654 4.45814 5.88969 4.56129C5.99283 4.66443 6.05078 4.80433 6.05078 4.9502V8.2502C6.05078 8.39606 5.99283 8.53596 5.88969 8.6391C5.78654 8.74225 5.64665 8.8002 5.50078 8.8002C5.35491 8.8002 5.21502 8.74225 5.11187 8.6391C5.00873 8.53596 4.95078 8.39606 4.95078 8.2502V4.9502C4.95078 4.80433 5.00873 4.66443 5.11187 4.56129C5.21502 4.45814 5.35491 4.4002 5.50078 4.4002ZM6.32578 3.0252C6.32578 3.244 6.23886 3.45384 6.08414 3.60856C5.92943 3.76328 5.71958 3.8502 5.50078 3.8502C5.28198 3.8502 5.07214 3.76328 4.91742 3.60856C4.7627 3.45384 4.67578 3.244 4.67578 3.0252C4.67578 2.80639 4.7627 2.59655 4.91742 2.44183C5.07214 2.28711 5.28198 2.2002 5.50078 2.2002C5.71958 2.2002 5.92943 2.28711 6.08414 2.44183C6.23886 2.59655 6.32578 2.80639 6.32578 3.0252Z"
                              fill="#A4A4A4"
                            />
                          </svg>
                        </span>
                      </p>
                    </div>
                    <select
                      id="product-create-update-category-select"
                      className="form-control-select-input"
                      name="category_id"
                      value={product.category_id}
                      onChange={handleInputChange}
                    >
                      {categoryData.length === 0 ? (
                        <option>No data</option>
                      ) : (
                        <>
                          <option>Select</option>
                          {categoryData.map((category) => (
                            <option
                              id="sellers-productsCategoryOptions"
                              key={category.id}
                              value={category.id}
                            >
                              {category.name}
                            </option>
                          ))}{" "}
                        </>
                      )}
                    </select>
                    <label id="category_id_error" className="red">
                      {errors.category_id}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="product-btn-area">
            <button
              type="button"
              id="product-create-update-discard-btn"
              className="btn secondary-btn"
              onClick={() => {
                navigate("/products");
              }}
            >
              Discard
            </button>
            <button
              type="submit"
              id="product-create-update-save-btn"
              className="btn primary-btn"
              disabled={sellerStatus}
              style={{
                opacity: sellerStatus ? 0.5 : 1,
                pointerEvents: sellerStatus ? "none" : "",
              }}
            >
              Save
            </button>
          </div>
        </form>
      </div>
      {showCategoriesPopup && (
        <CategoriesDescription
          closedCategoryDetailsPopup={closedCategoryDetailsPopup}
        />
      )}
      {/* image upload modal */}
      {showImgUpModal && (
        <div className="modal fade">
          <div className="img-modal-content">
            <div className="img-modal-header">
              <p>Image upload</p>
              <button
                className="modal-close-icon"
                onClick={() => {
                  setShowImgUpModal(false);
                }}
              >
                <FontAwesomeIcon icon={faXmark} />
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-img-editor">
                <Cropper
                  src={rawImage}
                  ref={cropperRef}
                  initialAspectRatio={1}
                  aspectRatio={rawImgName === "thumb_image" ? 1 : undefined}
                  preview=".img-preview"
                  viewMode={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false}
                  guides={true}
                  style={{ maxHeight: 350 }}
                />
                <div
                  className="img-preview"
                  style={{
                    width: "100%",
                    float: "left",
                    height: "300px",
                    border: "1px solid #ced4da",
                    maxHeight: 350,
                  }}
                />
              </div>
            </div>
            <div className="modal-buttons">
              <button
                className="btn secondary-btn"
                onClick={() => {
                  setShowImgUpModal(false);
                }}
              >
                Cancel
              </button>
              <button className="btn primary-btn" onClick={cropImage}>
                Upload
              </button>
            </div>
          </div>
        </div>
      )}

      {/* page loading modal */}
      {isLoading && (
        <div className="modal fade">
          <div className="modal-content-loader">
            <img src={loadingImgPath} className="loader-img-size" />
          </div>
        </div>
      )}
    </div>
  );
};

export default NewProducts;
