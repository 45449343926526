import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import validator from "validator";
import InputContext from "../InputContext";
import loadingImgPath from "../../../Assets/rolling.svg";
import navigation from "../../../Configs/Navigation.json";
import ApiEndpoint from "../../../Configs/APIEndpoints.json";
import Alert from "../../../Configs/Alerts.json";

//import Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { setDataToCookiesWithExpirationTime } from "../../../Component/Cookies/Cookie";
import Toast from "../../../Component/Toastify/Toast";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
//css
import "../auth.css";
// import login_banner from "../../../Assets/login_banner.png";
import TermsConditionsText from "./TermsConditionsText";
import login_banner from "../../../Assets/LoginModelPopupSeller.jpg";
import ButtonImg from "../../../Assets/rolling.svg";

//constant
const API_HOST = process.env.REACT_APP_HOST_API_URL;
const APP_NAME = process.env.REACT_APP_NAME;
const ADMIN_EMAIL = process.env.REACT_APP_ADMIN_EMAIL;
const ADMIN_PHONE = process.env.REACT_APP_ADMIN_PHONE;

const Login = (props) => {
  const contextObj = useContext(InputContext);
  const [imgLoaded, setImgLoaded] = useState(false);
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [searchParams] = useSearchParams();
  const [disabledResendOTP, setDisableResendOTP] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [otp, setOtp] = useState("");
  const [loginError, setLoginError] = useState("");
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [emailOrPhoneError, setEmailOrPhoneError] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [loginByPassword, setLoginByPassword] = useState(true);
  const { showToast } = Toast();
  const [loginOtpVerifyToken, setLoginOtpVerifyToken] = useState("");
  const [isLoginUsingOtpDisable, setIsLoginUsingOtpDisable] = useState(false);
  const [featureFlags, setFeatureFlags] = useState({
    canLogin: true,
    canSellerForgot_Password: true,
    flagsUpdated: false,
  });

  const [emailOrPhoneType, setEmailOrPhoneType] = useState("");
  const [emailOrPhoneTypeValue, setEmailOrPhoneTypeValue] = useState("");
  const [showEmailPhonesendDiv, setShowEmailPhonesendDiv] = useState(false);
  const [isOtp, setIsOtp] = useState(false);
  const [isEmailAndPhone, setIsEmailAndPhone] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [isActive, setIsActive] = useState(false);
  const [resendOtp, setResendOtp] = useState({
    phone: "",
    email: "",
    otpVerifyToken: "",
  });

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  // Resend otp timer setting
  useEffect(() => {
    let interval = null;

    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
    if (seconds === 0) {
      resetTimer();
    }

    return () => clearInterval(interval);
  }, [isActive, seconds]);

  const startTimer = () => {
    setIsActive(true);
  };

  const resetTimer = () => {
    setIsActive(false);
    setSeconds(60);
  };

  // call necessary method on page load
  useEffect(() => {
    if (inputRef.current !== null) inputRef.current.focus();

    // login from home page
    const fromHomePage = contextObj.inputValue.loginFromHome;
    if (fromHomePage) {
      setEmailOrPhone(sessionStorage.getItem("home_input"));
      setIsEmailAndPhone(true);
    }

    const featureFlags = contextObj.inputValue.featureFlags;
    if (featureFlags) {
      setFeatureFlags((prev) => ({
        ...prev,
        canLogin: featureFlags.seller_login,
        canSellerForgot_Password: featureFlags.seller_forgot_password,
        flagsUpdated: true,
      }));
    }
  }, [contextObj]);

  const handleLoginSubmission = async (
    emailOrPhoneValue,
    passwordValue,
    type
  ) => {
    let payload = {};
    payload["password"] = passwordValue;
    if (type === "email") payload["email"] = emailOrPhoneValue;
    else payload["phone"] = emailOrPhoneValue;

    try {
      const response = await fetch(
        `${API_HOST}/${ApiEndpoint.SellersAuthLoginApi}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const res = await response.json();
      if (res.status === 200) {
        const expiryDate = new Date();
        expiryDate.setFullYear(expiryDate.getFullYear() + 1);
        setDataToCookiesWithExpirationTime(
          "R3p7uLw9Xk",
          res.data,
          expiryDate,
          "/"
        );
        setDataToCookiesWithExpirationTime("isLoggedIn", true, expiryDate, "/");
        contextObj.setInput("navigation", "true");
        contextObj.setInput("navigationFrom", "logInPage");
        document.body.style.overflow = "auto";
        console.log(
          "contextObj.inputValue.prevPath ===>",
          contextObj.inputValue.prevPath
        );

        if (
          contextObj.inputValue.prevPath &&
          contextObj.inputValue.prevPath !== "/"
        ) {
          navigate(`${contextObj.inputValue.prevPath}`);
        } else {
          contextObj.setInput("prevPath", null);
          navigate(`${navigation.dashbord}`);
        }
      } else if (res.status === 400) {
        const errors = res.errors;
        let errorMessage = Alert.alertForInvalidCredintials;

        // Check if errors exist
        if (errors) {
          Object.keys(errors).forEach((key) => {
            errors[key].forEach((message) => {
              errorMessage = message;
            });
          });
        }
        setLoginError(errorMessage);
      } else if (res.status === 401) {
        setLoginError(Alert.alertForInvalidCredintials);
      } else if (res.status === 403) {
        setLoginError(Alert.alertForBlockedUser);
      } else if (res.status === 404) {
        setEmailOrPhoneError(res.message);
      } else {
        setLoginError(Alert.alertForSomethingWrong);
        console.error(res.message);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      if (err.message.includes("Failed to fetch")) {
        setLoginError(err.message);
      }
    }
  };

  // neviagte to otp or password request page
  const handleLogin = async () => {
    setEmailOrPhoneError("");
    setPasswordError("");
    setIsLoading(true);

    const emailOrPhoneInput = emailOrPhone;
    const emailPhoneInputValue = emailOrPhoneInput;
    const passwordInput = password;
    const passwordInputValue = passwordInput;

    // email or phone validation
    let errors = false;
    if (passwordInputValue === "") {
      setIsLoading(false);
      setPasswordError("Password is required");
      errors = true;
    }

    const isEmail = validator.isEmail(emailPhoneInputValue);
    const phoneRegex = /^(\+?91|0)?[6789]\d{9}$/;
    const isPhoneNumber = phoneRegex.test(emailPhoneInputValue);
    if (emailPhoneInputValue === "") {
      setIsLoading(false);
      setEmailOrPhoneError(Alert.alertForEmailandPhone);
      errors = true;
    } else if (isEmail) {
      if (errors === true) {
        return;
      }
      handleLoginSubmission(emailPhoneInputValue, passwordInputValue, "email");
    } else if (isPhoneNumber) {
      if (errors === true) {
        return;
      }
      handleLoginSubmission(emailPhoneInputValue, passwordInputValue, "phone");
    } else {
      setIsLoading(false);
      setEmailOrPhoneError(Alert.alertForValidEmail);
      errors = true;
    }
  };

  // handle emailOrPhoneInput
  const handleEmailOrPhoneInput = (event) => {
    let { name, value } = event.target;
    if (name === "email_phone_value") {
      const trimmedValue = value.trim();
      if (trimmedValue.length === 10 && /^[6789]\d{9}$/.test(trimmedValue)) {
        setIsEmailAndPhone(true);
      } else if (/^\S+@\S+\.\S+$/.test(trimmedValue)) {
        setIsEmailAndPhone(true);
      } else {
        setIsEmailAndPhone(false);
      }
      if (value.trim() !== "" || value == "") {
        setEmailOrPhone(trimmedValue);
      }
    }
    setEmailOrPhoneError("");
    setLoginError("");
    setIsLoading(false);
  };

  const handlePasswordInput = (event) => {
    let { name, value } = event.target;
    if (name === "password") {
      const password = value.trim();
      const isPasswordValid = password.length >= 1;
      // &&
      // /[a-z]/.test(password) &&
      // /[A-Z]/.test(password) &&
      // /\d/.test(password) &&
      // /[!@#$%^&*]/.test(password);

      if (isPasswordValid) {
        setIsPassword(true);
      } else {
        setIsPassword(false);
      }
      if (value.trim() !== "" || value == "") {
        setPassword(password);
      }
      setPasswordError("");
      setLoginError("");
      setIsLoading(false);
    }
  };

  const handleOtpChange = (e) => {
    setOtpError("");
    setLoginError("");
    const enteredOTP = e.target.value.replace(/[^0-9]/g, "");
    setOtp(enteredOTP);
    if (enteredOTP.length === 4 && /^\d+$/.test(enteredOTP)) {
      setIsOtp(true);
    } else {
      setIsOtp(false);
    }
  };
  // call handleSubmission on Enter keypress
  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === 13) {
      event.preventDefault();
      handleLogin();
    }
  };
  // handel Login Via Otp
  const handelLoginViaOtp = async (props) => {
    setEmailOrPhoneError("");
    const emailOrPhoneInput = emailOrPhone;
    const emailPhoneInputValue = emailOrPhoneInput;
    let errors = false;
    const isEmail = validator.isEmail(emailPhoneInputValue);
    const phoneRegex = /^(\+?91|0)?[6789]\d{9}$/;
    const isPhoneNumber = phoneRegex.test(emailPhoneInputValue);
    if (emailPhoneInputValue == "") {
      setIsLoading(false);
      setDisableResendOTP(false);
      setEmailOrPhoneError(Alert.alertForEmailandPhone);
      errors = true;
    } else if (isEmail) {
      if (errors == true) {
        
        setDisableResendOTP(false);
        return;
      }
      sendRequetOtpLogin(emailPhoneInputValue, "email");
    } else if (isPhoneNumber) {
      if (errors == true) {
        setDisableResendOTP(false);
        return;
      }
      sendRequetOtpLogin(emailPhoneInputValue, "phone");
    } else {
      setIsLoading(false);
      setDisableResendOTP(false);
      setEmailOrPhoneError(Alert.alertForValidEmail);
      errors = true;
    }
  };
  // Login Send Otp
  const sendRequetOtpLogin = async (emailOrPhoneValue, type) => {
    setIsLoginUsingOtpDisable(true);
    let payload = {};
    if (type === "email") {
      payload["email"] = emailOrPhoneValue;
      setResendOtp((prev) => ({
        ...prev,
        email: emailOrPhoneValue,
      }));
      setEmailOrPhoneType("email");
      setEmailOrPhoneTypeValue(emailOrPhoneValue);
    } else {
      payload["phone"] = emailOrPhoneValue;
      setResendOtp((prev) => ({
        ...prev,
        phone: emailOrPhoneValue,
      }));
      setEmailOrPhoneType("phone");
      setEmailOrPhoneTypeValue(emailOrPhoneValue);
    }
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellerLoginByOtpStep1}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type, Authorization",
        },
        body: JSON.stringify(payload),
      }
    );
    const loginSendOtp = await response.json();
    setIsLoginUsingOtpDisable(false);
    if (
      loginSendOtp.status == 500 ||
      loginSendOtp.status == 501 ||
      loginSendOtp.status == 502 ||
      loginSendOtp.status == 503 ||
      (loginSendOtp.message &&
        loginSendOtp.message.toLowerCase().includes("server error"))
    ) {
      if (loginSendOtp.status == 503) {
      } else {
      }
      contextObj.setInput("serviceUnavailable", true);
    } else {
      contextObj.setInput("serviceUnavailable", false);
    }
    if (loginSendOtp.status == 201) {
      setShowEmailPhonesendDiv(true);
      showToast("success", loginSendOtp.message);

      setLoginByPassword(false);
      setLoginOtpVerifyToken(loginSendOtp.data);
      setResendOtp((prev) => ({
        ...prev,
        otpVerifyToken: loginSendOtp.data,
      }));
      setIsActive(true);
      startTimer();
    }
    if (loginSendOtp.status === 400) {
      setEmailOrPhoneError(loginSendOtp.message);
    }

    if (loginSendOtp.status === 404) {
      setEmailOrPhoneError(loginSendOtp.message);
    }
    if (loginSendOtp.status == 429) {
      setEmailOrPhoneError(loginSendOtp.message);
    }
    setDisableResendOTP(false);
  };

  // login By otp

  const handleLoginViaOtp = () => {
    setEmailOrPhoneError("");
    setOtpError("");
    setLoginError("");
    let errorFound = false;
    if (otp == "" || otp == null) {
      setOtpError(Alert.alertForOtpRequirement);
      errorFound = true;
    }
    if (otp.length > 1 && otp.length < 4) {
      setOtpError(Alert.alertForOtpLength);
      errorFound = true;
    }
    if (errorFound == true) {
      return;
    }
    let errors = false;
    const emailOrPhoneInput = emailOrPhone;
    const emailPhoneInputValue = emailOrPhoneInput;
    const isEmail = validator.isEmail(emailPhoneInputValue);
    const phoneRegex = /^(\+?91|0)?[6789]\d{9}$/;
    const isPhoneNumber = phoneRegex.test(emailPhoneInputValue);
    if (emailPhoneInputValue === "") {
      setIsLoading(false);
      setEmailOrPhoneError(Alert.alertForEmailandPhone);
      errors = true;
    } else if (isEmail) {
      if (errors === true) return;
      handleLoginSubmissionViaOtp(emailPhoneInputValue, otp, "email");
    } else if (isPhoneNumber) {
      if (errors === true) {
        return;
      }
      handleLoginSubmissionViaOtp(emailPhoneInputValue, otp, "phone");
    } else {
      setIsLoading(false);

      setEmailOrPhoneError(Alert.alertForValidEmail);
      errors = true;
    }
  };

  const handleLoginSubmissionViaOtp = async (
    emailOrPhoneValue,
    otpValue,
    type
  ) => {
    const payload = {};
    if (type === "email") {
      payload["email"] = emailOrPhoneValue;
      payload["otp"] = otpValue;
      payload["otp_verify_token"] = loginOtpVerifyToken;
    } else {
      payload["phone"] = emailOrPhoneValue;
      payload["otp"] = otpValue;
      payload["otp_verify_token"] = loginOtpVerifyToken;
    }
    const AssingParameters = JSON.stringify(payload);
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellerLoginVerifyOtpStep2}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: AssingParameters,
      }
    );
    const res = await response.json();
    if (res.status === 200) {
      setIsActive(true);
      startTimer();
      setIsLoading(false);
      const expiryDate = new Date();
      expiryDate.setFullYear(expiryDate.getFullYear() + 1);
      setDataToCookiesWithExpirationTime(
        "R3p7uLw9Xk",
        res.data,
        expiryDate,
        "/"
      );
      document.body.style.overflow = "auto";

      setDataToCookiesWithExpirationTime("isLoggedIn", true, expiryDate, "/");
      contextObj.setInput("navigation", "true");
      contextObj.setInput("navigationFrom", "logInPage");
      navigate(`${navigation.dashbord}`);
    } else if (res.status === 400) {
      setIsLoading(false);
      const errors = res.errors;
      let errorMessage = Alert.alertForInvalidCredintials;

      // Check if errors exist
      if (errors) {
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((message) => {
            errorMessage = message;
          });
        });
      }
      setLoginError(errorMessage);
    } else if (res.status === 401) {
      setIsLoading(false);
      setOtpError(Alert.alertForInvalidOtp);
    } else if (res.status === 403) {
      setIsLoading(false);
      setLoginError(Alert.alertForBlockedUser);
    } else if (res.status === 404) {
      setIsLoading(false);
      setEmailOrPhoneError(res.message);
    } else {
      setIsLoading(false);
      setLoginError(Alert.alertForSomethingWrong);
      console.error(res.message);
    }
  };

  // const resend Otp Via Call

  const handelResendOtp = async () => {
    const Payload = {};
    if (resendOtp.phone == "" || resendOtp.phone == null) {
      Payload["email"] = resendOtp.email;
      Payload["otp_verify_token"] = resendOtp.otpVerifyToken;
      setEmailOrPhoneType("email");
      setEmailOrPhoneTypeValue(resendOtp.email);
    } else {
      Payload["phone"] = resendOtp.phone;
      Payload["otp_verify_token"] = resendOtp.otpVerifyToken;
      setEmailOrPhoneType("phone");
      setEmailOrPhoneTypeValue(resendOtp.phone);
    }
    const AssingParameters = JSON.stringify(Payload);
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellerLoginResendOtpStep3}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: AssingParameters,
      }
    );
    const otpSendMessage = await response.json();
    if (
      otpSendMessage.status == 500 ||
      otpSendMessage.status == 501 ||
      otpSendMessage.status == 502 ||
      otpSendMessage.status == 503 ||
      (otpSendMessage.message &&
        otpSendMessage.message.toLowerCase().includes("server error"))
    ) {
      contextObj.setInput("serviceUnavailable", true);
    } else {
      contextObj.setInput("serviceUnavailable", false);
    }
    if (otpSendMessage.status == 201) {
      setShowEmailPhonesendDiv(true);
      setLoginOtpVerifyToken(otpSendMessage.data);
      setResendOtp((prev) => ({
        ...prev,
        otpVerifyToken: otpSendMessage.data,
      }));
      setIsActive(true);
      startTimer();
    }
    if (otpSendMessage.status == 429) {
      setOtpError(otpSendMessage.message);
    }
    setDisableResendOTP(false);
  };

  return (
    <>
      <div className="auth-container">
        <div
          className="auth-Holder"
          style={{
            opacity: imgLoaded ? 1 : 0,
          }}
        >
          <div
            className="closeLoginModalBtn"
            onClick={() => {
              props.showForgotPassModal(false);
              props.onClose(false);
              contextObj.setInput("loginFromHome", false);
            }}
          >
            <FontAwesomeIcon
              className="curserPointer"
              icon={faTimes}
              size="lg"
              color={window.innerWidth > 600 ? "#9e9e9eb7" : "#fff"}
            />
          </div>
          <div className="login-Image-Container">
            <img
              src={login_banner}
              alt=""
              className="login-Image"
              onLoad={() => {
                setImgLoaded(true);
              }}
              onError={() => {
                setImgLoaded(true);
              }}
            />
          </div>
          <div className="login-left-box">
            <h1 className="login_heading_tag">JOYREJOY</h1>

            <div className="loginModalPopup">
              <div className="login-box">
                <h2 className="auth-heading">Login to your seller panel</h2>
                {showEmailPhonesendDiv == true && isActive == true && (
                  <div className="ForgotPasswoed_sentDetails">
                    <li className="sendOtpContainer">
                      We have sent an OTP to your {emailOrPhoneType}:{" "}
                      {emailOrPhoneTypeValue}
                    </li>
                  </div>
                )}

                {featureFlags.canLogin ? (
                  <>
                    {loginByPassword == true ? (
                      <>
                        <label className="login-label-tag">
                          <input
                            type="text"
                            id="sellers-emailIdOrPhone-feild"
                            className="email-phone-input login_input_tag"
                            onChange={handleEmailOrPhoneInput}
                            name="email_phone_value"
                            ref={inputRef}
                            placeholder="Email address or mobile number"
                            maxLength={50}
                            onKeyPress={handleKeyPress}
                            value={emailOrPhone}
                          />
                          <span className="login-Span-tags">
                            {" "}
                            Email or mobile
                          </span>
                        </label>
                        {emailOrPhoneError && (
                          <p className="auth-error">{emailOrPhoneError}</p>
                        )}
                      </>
                    ) : (
                      <>
                        <label className="login-label-tag">
                          <input
                            type="text"
                            className="email-phone-input login_input_tag"
                            id="sellers-emailIdOrPhone-feild"
                            onChange={handleEmailOrPhoneInput}
                            name="email_phone_value"
                            ref={inputRef}
                            placeholder="Email address or mobile number"
                            maxLength={50}
                            onKeyPress={handleKeyPress}
                            value={emailOrPhone}
                            readOnly
                          />

                          <span className="login-Span-tags">
                            {" "}
                            Email address or mobile number
                          </span>
                          <span
                            className="login_edit-icon"
                            onClick={() => {
                              setLoginByPassword(true);
                            }}
                          >
                            <svg
                              width="14"
                              height="15"
                              viewBox="0 0 14 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.00432 11.0312C1.63242 11.0312 1.28491 10.9032 1.03494 10.6654C0.717916 10.3666 0.565499 9.91549 0.620369 9.42776L0.845947 7.45243C0.888624 7.08053 1.1142 6.5867 1.37636 6.31845L6.38175 1.02045C7.63157 -0.30253 8.93626 -0.339111 10.2592 0.910712C11.5822 2.16053 11.6188 3.46523 10.369 4.78821L5.3636 10.0862C5.10754 10.3606 4.63199 10.6166 4.26009 10.6776L2.29696 11.0129C2.19332 11.019 2.10187 11.0312 2.00432 11.0312ZM8.33879 0.904615C7.86934 0.904615 7.46086 1.19726 7.04629 1.63622L2.0409 6.94037C1.91896 7.0684 1.77874 7.37324 1.75435 7.55004L1.52878 9.52531C1.50439 9.7265 1.55316 9.89111 1.6629 9.99475C1.77264 10.0984 1.93725 10.135 2.13845 10.1045L4.10158 9.76923C4.27838 9.73875 4.57103 9.58018 4.69296 9.45215L9.69835 4.15415C10.4543 3.34939 10.7287 2.60559 9.62519 1.56915C9.13745 1.09971 8.71678 0.904615 8.33879 0.904615Z"
                                fill="#888888"
                              />
                              <path
                                d="M9.19847 5.80701C9.18628 5.80701 9.16799 5.80701 9.15579 5.80701C7.25362 5.61801 5.72336 4.17313 5.43071 2.28315C5.39413 2.03319 5.56484 1.80152 5.81481 1.75884C6.06477 1.72226 6.29644 1.89297 6.33912 2.14293C6.5708 3.61833 7.76575 4.75232 9.25334 4.89864C9.5033 4.92302 9.68621 5.1486 9.66182 5.39853C9.63133 5.63021 9.43014 5.80701 9.19847 5.80701Z"
                                fill="#888888"
                              />
                              <path
                                d="M11.4313 13.0004H0.457252C0.207288 13.0004 0 12.7932 0 12.5432C0 12.2932 0.207288 12.0859 0.457252 12.0859H11.4313C11.6813 12.0859 11.8885 12.2932 11.8885 12.5432C11.8885 12.7932 11.6813 13.0004 11.4313 13.0004Z"
                                fill="#888888"
                              />
                            </svg>
                          </span>
                        </label>
                        {emailOrPhoneError && (
                          <p className="auth-error">{emailOrPhoneError}</p>
                        )}
                      </>
                    )}
                    {loginByPassword == true ? (
                      <>
                        <label className="login-label-tag">
                          <input
                            id="sellers-loginPassword"
                            type="password"
                            className="login_input_tag password-input"
                            minLength={6}
                            // maxLength={16}
                            name="password"
                            placeholder="Password"
                            onChange={handlePasswordInput}
                            onKeyPress={handleKeyPress}
                            value={password}
                          />
                          <span className="login-Span-tags">Password</span>

                          <span className="password-eye-icons">
                            {showPassword ? (
                              <FontAwesomeIcon
                                icon={faEye}
                                onClick={() => {
                                  setShowPassword(false);
                                  document.querySelector(
                                    ".password-input"
                                  ).type = "password";
                                }}
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faEyeSlash}
                                onClick={() => {
                                  setShowPassword(true);
                                  document.querySelector(
                                    ".password-input"
                                  ).type = "text";
                                }}
                              />
                            )}
                          </span>
                        </label>
                        {loginError && (
                          <p className="auth-error">{loginError}</p>
                        )}
                        {passwordError && (
                          <p className="auth-error">{passwordError}</p>
                        )}

                        <div className="login_via_otp_forgot_password_Container">
                          {isActive ? (
                            <p
                              id="sellers-LoginThrough-otpButton"
                              className="LoginUsing_otp"
                              style={{
                                color: "#3366cc",
                                cursor: "default",
                              }}
                            >
                              Login using OTP in {formatTime(seconds)}
                            </p>
                          ) : (
                            <button
                              id="sellers-LoginThrough-otpButton"
                              className="LoginUsing_otp login_using_otp_button"
                              disabled={isLoginUsingOtpDisable}
                              onClick={() => {
                                if (!disabledResendOTP) {
                                  setDisableResendOTP(true);
                                  handelLoginViaOtp(props);
                                }
                              }}
                            >
                              {disabledResendOTP ? (
                                <img
                                  className="Signup_button_loading_Img"
                                  src={ButtonImg}
                                  alt=""
                                  style={{
                                    height: "12px",
                                  }}
                                />
                              ) : (
                                "Login using OTP"
                              )}
                            </button>
                          )}
                          <p className="forgot-password-line">
                            {featureFlags.canSellerForgot_Password && (
                              <li
                                id="sellers-forgotPasswordButton"
                                onClick={() => {
                                  props.onClose(false);
                                  props.showForgotPassModal(true);
                                }}
                                className="login-forgot-password"
                              >
                                Forgot Password?
                              </li>
                            )}
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <label className="login-label-tag">
                          <input
                            type="otp"
                            className="login_input_tag"
                            maxLength={4}
                            placeholder="OTP"
                            value={otp}
                            onChange={handleOtpChange}
                          />
                          <span className="login-Span-tags">OTP</span>
                        </label>
                        {otpError && <p className="auth-error">{otpError}</p>}
                        <div className="login_via_otp_forgot_password_Container">
                          <p
                            className="LoginUsing_otp"
                            onClick={() => {
                              setLoginByPassword(true);
                            }}
                          >
                            Login using Password
                          </p>
                          {isActive ? (
                            <p
                              style={{
                                color: "#3366cc",
                                cursor: "default",
                              }}
                              className="Resend-otp-line"
                            >
                              Resend in {formatTime(seconds)}
                            </p>
                          ) : (
                            <p
                              className="Resend-otp-line"
                              onClick={() => {
                                if (!disabledResendOTP) {
                                  setDisableResendOTP(true);
                                  handelResendOtp();
                                }
                              }}
                            >
                              {disabledResendOTP ? (
                                <img
                                  className="Signup_button_loading_Img"
                                  src={ButtonImg}
                                  alt=""
                                  style={{
                                    height: "12px",
                                  }}
                                />
                              ) : (
                                "Resend OTP"
                              )}
                            </p>
                          )}
                        </div>
                      </>
                    )}

                    {loginByPassword == true ? (
                      <>
                        {isPassword == true && isEmailAndPhone == true ? (
                          <>
                            <button
                              id="sellers-loginButton"
                              className="login-btn"
                              onClick={handleLogin}
                              style={{
                                backgroundColor: isLoading
                                  ? "#EA5E3D"
                                  : "#EA5E3D",
                                opacity: isLoading ? "0.5" : "1",
                              }}
                            >
                              {isLoading ? (
                                <img
                                  src={loadingImgPath}
                                  alt=""
                                  className="loading-img-size"
                                />
                              ) : (
                                "Log in"
                              )}
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              id="sellers-loginButton"
                              className="login-btn-password"
                            >
                              {isLoading ? (
                                <img
                                  src={loadingImgPath}
                                  alt=""
                                  className="loading-img-size"
                                />
                              ) : (
                                "Log in"
                              )}
                            </button>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {isOtp == true && isEmailAndPhone == true ? (
                          <>
                            <button
                              id="sellers-loginButton"
                              className="login-btn"
                              onClick={handleLoginViaOtp}
                              style={{
                                backgroundColor: isLoading
                                  ? "#EA5E3D"
                                  : "#EA5E3D",
                                opacity: isLoading ? "0.5" : "1",
                              }}
                            >
                              {isLoading ? (
                                <img
                                  src={loadingImgPath}
                                  alt=""
                                  className="loading-img-size"
                                />
                              ) : (
                                "Log in"
                              )}
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              id="sellers-loginButton"
                              className="login-btn-otp"
                            >
                              {isLoading ? (
                                <img
                                  src={loadingImgPath}
                                  alt=""
                                  className="loading-img-size"
                                />
                              ) : (
                                "Log in"
                              )}
                            </button>
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {featureFlags.flagsUpdated && (
                      <>
                        <p style={{ color: "#e19200" }}>
                          The {APP_NAME} team has temporarily disabled the
                          seller login. Please get in touch with the
                          administrator for further assistance.
                        </p>
                        <br />

                        <h4 className="contact-info-heading">Contact Us</h4>
                        <p className="login-contact-info">
                          <span>Email:</span> {`${ADMIN_EMAIL}`}
                        </p>
                        <p className="login-contact-info">
                          <span> Phone:</span> {`${ADMIN_PHONE}`}
                        </p>
                      </>
                    )}
                  </>
                )}
              </div>
              <div>
                <div className="loginsignupbuttoncontainer">
                  <div className="HrLineForLine"></div>
                  <li className="listIagtojoyrejoynew"> New to JOYREJOY?</li>
                  <div className="HrLineForLine"></div>
                </div>
                <button
                  id="sellers-createNew-account"
                  className="Login-quick-line-button"
                  onClick={() => {
                    navigate("/signup");
                  }}
                >
                  Create your seller account
                </button>
              </div>
            </div>
            <div className="TermsConditionsTextHolder">
              {" "}
              <TermsConditionsText onClose={props.onClose} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
