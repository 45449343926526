import React, { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faXmark } from "@fortawesome/free-solid-svg-icons";
import validator from "validator";
import loadingImgPath from "../../Assets/rolling.svg";
import navigation from "../../Configs/Navigation.json";
import ApiEndpoint from "../../Configs/APIEndpoints.json";
import Alert from "../../Configs/Alerts.json";
import InputContext from "../Auth/InputContext";
import {
  setDataToCookies,
  getDataFromCookies,
  removeDataFromCookie,
} from "../../Component/Cookies/Cookie";
import Toast from "../../Component/Toastify/Toast";
import "./profile.css";

const API_HOST = process.env.REACT_APP_HOST_API_URL;
const APP_NAME = process.env.REACT_APP_NAME;

const Profile = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const token = getDataFromCookies("R3p7uLw9Xk");
  const [isLoading, setIsLoading] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [changePasswordPopup, setChangePasswordPopup] = useState(false);
  const [pageLoadderForMobileScreen, setPageLoaderMobile] = useState(false);
  const contextObj = useContext(InputContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  // error usestates
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [otp, setOTP] = useState("");
  const [otpValidationMessage, setOtpValidationMessage] = useState("");
  const [passwordContainerError, setpasswordContainerError] = useState({
    currentPasswordError: "",
    newPasswordError: "",
    confirmNewPasswordError: "",
    passwordError: "",
  });

  //modals, values and errors
  const [otpVerifyToken, setOtpVerifyToken] = useState("");
  const [newEmailValueInModal, setNewEmailValueInModal] = useState("");
  const [newEmailVerifyTokenInModal, setNewEmailVerifyTokenInModal] =
    useState("");
  const [newPhoneNumberInModal, setNewPhoneNumberInModal] = useState("");
  const [newPhoneVerifyTokenInModal, setNewPhoneVerifyTokenInModal] =
    useState("");
  const [showcurrent_password, setShowcurrent_password] = useState(false);
  const [shownew_password, setShownew_password] = useState(false);
  const [shownew_password_confirmation, setShownew_password_confirmation] =
    useState(false);

  const [Showcurrent_password_Email, setShowcurrent_password_Email] =
    useState(false);
  const [emailUpdateModal, setEmailUpdateModal] = useState(false);
  const [newEmailModal, setNewEmailModal] = useState(false);
  const [verifyEmailAddressModal, setVerifyEmailAddressModal] = useState(false);
  const [phoneUpdateModal, setPhoneUpdateModal] = useState(false);
  const [newPhoneModal, setNewPhoneModal] = useState(false);
  const [verifyPhoneNumberModal, setVerifyPhoneNumberModal] = useState(false);
  const [emailUpdateModalError, setEmailUpdateModalError] = useState("");
  const [emailNewModalError, setEmailNewModalError] = useState("");
  const [emailVerifyModalError, setEmailVerifyModalError] = useState("");
  const [phoneUpdateModalError, setPhoneUpdateModalError] = useState("");
  const [phoneNewModalError, setPhoneNewModalError] = useState("");
  const [phoneVerifyModalError, setPhoneVerifyModalError] = useState("");
  const [editModeOn, setEditModeOn] = useState(false);
  const [updatePasswordModal, setUpdatePasswordModal] = useState(false);
  const [featureFlags, setFeatureFlags] = useState({
    sellerProfileinformation: false,
    canSellerProfileDeleteAccount: false,
    canSellerProfileChangePassword: false,
  });
  const [DeactivateAccountModel, setDeactivateModalVisible] = useState(false);
  const [
    DeactivateAccountByEmailModalPopup,
    setDeactivateAccountByEmailModalPopup,
  ] = useState(false);
  const [
    DeactivateAccountByPhoneModalPopup,
    setDeactivateAccountByPhoneModalPopup,
  ] = useState(false);
  const [deleteSelectedValue, setDeleteSelectedValue] = useState("");
  const [deactivateAccountError, setDeactivateAccountError] = useState("");
  const [seconds, setSeconds] = useState(60);
  const [isActive, setIsActive] = useState(false);
  const [unableToDelete, setUnableToDelete] = useState(false);
  const { showToast } = Toast();

  // Model Popup controler To show Change Password
  useEffect(() => {
    if (changePasswordPopup) {
      document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = "auto";
      };
    }
  }, [changePasswordPopup]);

  // Hamburger button logic implementaion for small screen.
  useEffect(() => {
    const featureFlags = contextObj.inputValue.featureFlags;
    if (featureFlags) {
      setFeatureFlags((prev) => ({
        ...prev,
        sellerProfileinformation:
          featureFlags.seller_profile_profileinformation,
        canSellerProfileDeleteAccount:
          featureFlags.seller_profile_deleteaccount,
        canSellerProfileChangePassword:
          featureFlags.seller_profile_changepassword,
      }));
    }
  }, [contextObj.inputValue.featureFlags]);

  const [ProfileChangePassword, setProfileChangePassword] = useState({
    current_password: "",
    new_password: "",
    new_password_confirmation: "",
  });
  const [profileData, setProfileData] = useState({
    f_name: "",
    l_name: "",
    email: "",
    phone: "",
    image: null,
    upi_id: "",
    bank_name: "",
    branch_name: "",
    account_number: "",
    ifsc_code: "",
    account_holder_name: "",
    street_address: "",
    city: "",
    state: "",
    pincode: "",
    country: "India",
  });

  const fetchProfileData = async () => {
    const token = getDataFromCookies("R3p7uLw9Xk");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${navigation.home}`);
    }

    setPageLoaderMobile(true);
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellersProfileApi}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const res = await response.json();

    if (res.status === 200) {
      if (res.data) {
        const resData = res.data;

        // Set Profile Status
        if (resData.status === 1) {
          props.profileUnverified(false);
          contextObj.setInput("isSellerVerified", "true");
        } else {
          props.profileUnverified(true);
          contextObj.setInput("isSellerVerified", "false");
        }

        const fullName = resData.f_name + " " + resData.l_name;
        const firstName = resData.f_name;

        setProfileData(resData);
        setFirstName(resData.f_name);
        setLastName(resData.l_name);

        props.setUserName(fullName);
        props.setEmail(resData.email);

        setDataToCookies("firstName", firstName, "/");
        setDataToCookies("fullName", fullName, "/");
        setDataToCookies("firstChar", resData.f_name[0], "/");
        setDataToCookies("email", resData.email, "/");

        if (props.setIsPageLoading !== undefined) {
          props.setIsPageLoading(false);
        }
        if (resData.pincode) {
          setDataToCookies("pincode", resData.pincode, "/");
        }
      }
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      removeDataFromCookie("R3p7uLw9Xk", "/");
      removeDataFromCookie("isLoggedIn", "/");
      removeDataFromCookie("fullName");
      removeDataFromCookie("firstChar");
      removeDataFromCookie("email");
      contextObj.setInput("isSignupNavigation", true);
      showToast("info", Alert.alertForLoginExpired);
      navigate(`${navigation.home}`);
    } else {
      console.error(res);
    }
    setPageLoaderMobile(false);
  };

  // get seller profile
  useEffect(() => {
    const expiryDate = new Date();
    expiryDate.setFullYear(expiryDate.getFullYear() + 1);

    fetchProfileData();
  }, []);

  const handleChange = (event) => {
    let { name, value } = event.target;

    // button disabled and enabled by input events
    if (
      (name === "f_name" && value === firstName) ||
      (name === "l_name" && value === lastName)
    ) {
      setIsDataChanged(false);
    } else {
      setIsDataChanged(true);
    }

    // update profiledata useState value while updating input fields
    if (name === "f_name") {
      setFirstNameError("");
      setProfileData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else if (name === "l_name") {
      setLastNameError("");
      setProfileData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else if (name === "phone") {
      const input = event.target;
      const numericValue = input.value.replace(/\D/g, "");
      input.value = numericValue;
      setProfileData((prevData) => ({
        ...prevData,
        [name]: numericValue,
      }));
    } else if (name === "image") {
      const file = event.target.files[0];
      setProfileData((prevData) => ({
        ...prevData,
        [name]: file,
      }));
    } else {
      setProfileData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const verifyPasswordForEmailUpdate = async () => {
    setIsLoading(true);
    let errorHas = false;

    const passwordInput = document.querySelector(
      ".modal-email-update-password"
    );
    const passwordInputValue = passwordInput.value.trim();

    if (passwordInputValue === "" || passwordInputValue === null) {
      setEmailUpdateModalError("Password is required");
      errorHas = true;
      setIsLoading(false);
    } else if (
      passwordInputValue.length < 6 ||
      passwordInputValue.length > 16
    ) {
      setEmailUpdateModalError("Password must be between 6 and 16 characters");
      errorHas = true;
      setIsLoading(false);
    } else {
      setEmailUpdateModalError("");
    }
    if (errorHas === true) return;

    const payload = {
      email: profileData.email,
      password: passwordInputValue,
    };

    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellersProfileUpdateEmailPhoneStep1}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    const res = await response.json();

    if (res.status === 200) {
      setIsLoading(false);
      setEmailUpdateModal(false);
      setNewEmailModal(true);

      passwordInput.value = "";
    } else if (res.status === 401 || res.status === 404) {
      setIsLoading(false);
      setEmailUpdateModalError(res.message);
    } else if (res.status === 400) {
      const errors = res.errors;
      let errorMessage = "";
      if (errors) {
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((message) => {
            errorMessage = errorMessage + " " + message;
          });
        });
      }
      setIsLoading(false);
      setEmailUpdateModalError(errorMessage);
    } else {
      setIsLoading(false);
      console.error(res);
    }
  };

  // call handleSubmission on Enter keypress
  const verifyPasswordForEmailUpdateKeyPress = (event) => {
    if (event.key === "Enter" || event.key === 13) {
      event.preventDefault();
      verifyPasswordForEmailUpdate();
    }
  };

  const verifyNewEmail = async () => {
    setIsLoading(true);
    let errorHas = false;

    const newEmailInput = document.querySelector(".modal-new-email");
    const newEmailInputValue = newEmailInput.value.trim();
    const isEmail = validator.isEmail(newEmailInputValue);

    if (newEmailInputValue === "" || newEmailInputValue === null) {
      setEmailNewModalError("Email is required");
      errorHas = true;
      setIsLoading(false);
    } else if (!isEmail) {
      setEmailNewModalError("Invalid email address");
      errorHas = true;
      setIsLoading(false);
    } else {
      setEmailNewModalError("");
    }
    if (errorHas === true) return;

    const payload = {
      email: newEmailInputValue,
    };

    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellersProfileUpdateEmailPhoneStep2}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    const res = await response.json();

    if (res.status === 201) {
      setIsLoading(false);
      setNewEmailValueInModal(newEmailInputValue);
      setNewEmailVerifyTokenInModal(res.data);
      setNewEmailModal(false);
      setVerifyEmailAddressModal(true);
      setEmailNewModalError("");
      setIsActive(true);
      startTimer();
    } else if (
      res.status === 401 ||
      res.status === 404 ||
      res.status === 409 ||
      res.status === 429
    ) {
      setIsLoading(false);
      setEmailNewModalError(res.message);
    } else if (res.status === 400) {
      const errors = res.errors;
      let errorMessage = "";
      if (errors) {
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((message) => {
            errorMessage = errorMessage + " " + message;
          });
        });
      }
      setIsLoading(false);
      setEmailNewModalError(errorMessage);
    } else {
      setIsLoading(false);
      console.error(res);
    }
  };

  // call verifyNewEmail on Enter keypress
  const verifyNewEmailKeyPress = (event) => {
    if (event.key === "Enter" || event.key === 13) {
      event.preventDefault();
      verifyNewEmail();
    }
  };

  // handle newEmailInput
  const handleNewEmailInput = (event) => {
    setIsLoading(false);
    setEmailNewModalError("");
    const input = document.querySelector(".modal-new-email");
    setNewEmailValueInModal(input.value);
  };

  const verifyOTPAndSaveNewEmail = async () => {
    setIsLoading(true);
    let errorHas = false;
    const otpInput = document.querySelector(".modal-verify-email-otp");
    const otpInputValue = otpInput.value.trim();

    if (otpInputValue === "" || otpInputValue === null) {
      setEmailVerifyModalError("OTP is required");
      errorHas = true;
      setIsLoading(false);
    } else if (otpInputValue.length > 4 || otpInputValue.length < 4) {
      setEmailVerifyModalError("OTP length must be 4 characters");
      errorHas = true;
      setIsLoading(false);
    } else {
      setEmailVerifyModalError("");
    }
    if (errorHas === true) return;

    const payload = {
      email: newEmailValueInModal,
      otp: otpInputValue,
      otp_verify_token: newEmailVerifyTokenInModal,
    };

    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellersProfileUpdateEmailPhoneStep3}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    const res = await response.json();

    if (res.status === 200) {
      contextObj.setInput("email", payload.email);
      setIsLoading(false);
      setVerifyEmailAddressModal(false);
      setNewEmailValueInModal("");
      setNewEmailVerifyTokenInModal("");
      setEmailVerifyModalError("");
      document.querySelector(".modal-verify-email-otp").value = "";
      setProfileData((prev) => ({
        ...prev,
        email: newEmailValueInModal,
      }));
      fetchProfileData();
      setNewEmailValueInModal("");
      setNewEmailVerifyTokenInModal("");
      showToast("success", Alert.alertForEmailUpdate);
    } else if (res.status === 401 || res.status === 404) {
      setIsLoading(false);
      setEmailVerifyModalError(res.message);
    } else if (res.status === 400) {
      const errors = res.errors;
      let errorMessage = "";
      if (errors) {
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((message) => {
            errorMessage = errorMessage + " " + message;
          });
        });
      }
      setIsLoading(false);
      setEmailVerifyModalError(errorMessage);
    } else {
      setIsLoading(false);
      console.error(res);
    }
  };

  // call handleSubmission on Enter keypress
  const verifyOTPAndSaveNewEmailKeyPress = (event) => {
    if (event.key === "Enter" || event.key === 13) {
      event.preventDefault();
      verifyOTPAndSaveNewEmail();
    }
  };

  const verifyPasswordForPhoneUpdate = async () => {
    setIsLoading(true);
    let errorHas = false;

    const passwordInput = document.querySelector(
      ".modal-phone-update-password"
    );
    const passwordInputValue = passwordInput.value.trim();

    if (passwordInputValue === "" || passwordInputValue === null) {
      setPhoneUpdateModalError("Password is required");
      errorHas = true;
      setIsLoading(false);
    } else if (
      passwordInputValue.length < 6 ||
      passwordInputValue.length > 16
    ) {
      setPhoneUpdateModalError("Password must be between 6 and 16 characters");
      errorHas = true;
      setIsLoading(false);
    } else {
      setPhoneUpdateModalError("");
    }
    if (errorHas === true) return;

    const payload = {
      email: profileData.email,
      password: passwordInputValue,
    };

    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellersProfileUpdateEmailPhoneStep1}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    const res = await response.json();

    if (res.status === 200) {
      setIsLoading(false);
      setPhoneUpdateModal(false);
      setNewPhoneModal(true);
      passwordInput.value = "";
    } else if (res.status === 401 || res.status === 404) {
      setIsLoading(false);
      setPhoneUpdateModalError(res.message);
    } else if (res.status === 400) {
      const errors = res.errors;
      let errorMessage = "";
      if (errors) {
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((message) => {
            errorMessage = errorMessage + " " + message;
          });
        });
      }
      setIsLoading(false);
      setPhoneUpdateModalError(errorMessage);
    } else {
      setIsLoading(false);
      console.error(res);
    }
  };

  // call handleSubmission on Enter keypress
  const verifyPasswordForPhoneUpdateKeyPress = (event) => {
    if (event.key === "Enter" || event.key === 13) {
      event.preventDefault();
      verifyPasswordForPhoneUpdate();
    }
  };

  const verifyNewPhone = async () => {
    setIsLoading(true);
    let errorHas = false;

    const newPhoneInput = document.querySelector(".modal-new-phone");
    const newPhoneInputValue = newPhoneInput.value.trim();
    const phoneRegex =
      /^(?!\s*$)(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
    const isPhoneNumber = phoneRegex.test(newPhoneInputValue);

    if (newPhoneInputValue === "" || newPhoneInputValue === null) {
      setPhoneNewModalError("Phone is required");
      errorHas = true;
      setIsLoading(false);
    } else if (!isPhoneNumber) {
      setPhoneNewModalError("Invalid phone number");
      errorHas = true;
      setIsLoading(false);
    } else {
      setPhoneNewModalError("");
    }
    if (errorHas === true) return;

    const payload = {
      phone: newPhoneInputValue,
    };

    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellersProfileUpdateEmailPhoneStep2}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    const res = await response.json();

    if (res.status === 201) {
      setIsLoading(false);
      setNewPhoneNumberInModal(newPhoneInputValue);
      setNewPhoneVerifyTokenInModal(res.data);
      setNewPhoneModal(false);
      setVerifyPhoneNumberModal(true);
      setPhoneNewModalError("");
      setIsActive(true);
      startTimer();
    } else if (
      res.status === 401 ||
      res.status === 404 ||
      res.status === 409 ||
      res.status === 429
    ) {
      setIsLoading(false);
      setPhoneNewModalError(res.message);
    } else if (res.status === 400) {
      const errors = res.errors;
      let errorMessage = "";
      if (errors) {
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((message) => {
            errorMessage = errorMessage + " " + message;
          });
        });
      }
      setIsLoading(false);
      setPhoneNewModalError(errorMessage);
    } else {
      setIsLoading(false);
      console.error(res);
    }
  };

  // call verifyNewEmail on Enter keypress
  const verifyNewPhoneKeyPress = (event) => {
    if (event.key === "Enter" || event.key === 13) {
      event.preventDefault();
      verifyNewPhone();
    }
  };

  // handle newPhoneInput
  const handleNewPhoneInput = (event) => {
    setIsLoading(false);
    setPhoneNewModalError("");

    const value = event.target.value;

    const trimmedValue = value.replace(/\D/g, "");
    const limitedValue = trimmedValue.slice(0, 10);

    setNewPhoneNumberInModal(limitedValue);
  };

  const verifyOTPAndSaveNewPhone = async () => {
    setIsLoading(true);
    let errorHas = false;
    const otpInput = document.querySelector(".modal-verify-phone-otp");
    const otpInputValue = otpInput.value.trim();

    if (otpInputValue === "" || otpInputValue === null) {
      setPhoneVerifyModalError("OTP is required");
      errorHas = true;
      setIsLoading(false);
    } else if (otpInputValue.length > 4 || otpInputValue.length < 4) {
      setPhoneVerifyModalError("OTP length must be 4 characters");
      errorHas = true;
      setIsLoading(false);
    } else {
      setPhoneVerifyModalError("");
    }
    if (errorHas === true) return;

    const payload = {
      phone: newPhoneNumberInModal,
      otp: otpInputValue,
      otp_verify_token: newPhoneVerifyTokenInModal,
    };
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellersProfileUpdateEmailPhoneStep3}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    const res = await response.json();

    if (res.status === 200) {
      setIsLoading(false);
      setVerifyPhoneNumberModal(false);
      setNewPhoneNumberInModal("");
      setNewPhoneVerifyTokenInModal("");
      setPhoneVerifyModalError("");
      document.querySelector(".modal-verify-phone-otp").value = "";
      setProfileData((prev) => ({
        ...prev,
        phone: newPhoneNumberInModal,
      }));
      setNewPhoneNumberInModal("");
      setNewPhoneVerifyTokenInModal("");
      showToast("success", Alert.alertForPhoneUpdate);
    } else if (res.status === 401 || res.status === 404) {
      setIsLoading(false);
      setPhoneVerifyModalError(res.message);
    } else if (res.status === 400) {
      const errors = res.errors;
      let errorMessage = "";
      if (errors) {
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((message) => {
            errorMessage = errorMessage + " " + message;
          });
        });
      }
      setIsLoading(false);
      setPhoneVerifyModalError(errorMessage);
    } else {
      setIsLoading(false);
      console.error(res);
    }
  };

  // call handleSubmission on Enter keypress
  const verifyOTPAndSaveNewPhoneKeyPress = (event) => {
    if (event.key === "Enter" || event.key === 13) {
      event.preventDefault();
      verifyOTPAndSaveNewPhone();
    }
  };

  // update basic information
  const basicInformationSubmit = async (event) => {
    event.preventDefault();
    let errorHas = false;
    const fnameRegex = /^[a-zA-Z\s]+$/;

    if (profileData.f_name === "" || profileData.f_name === null) {
      setFirstNameError(Alert.alertForFirstName);
      errorHas = true;
    } else if (
      profileData.f_name.length > 50 ||
      profileData.f_name.length < 2
    ) {
      setFirstNameError(Alert.alertForNameCharacter);
      errorHas = true;
    } else {
      setFirstNameError("");
    }

    if (profileData.l_name === "" || profileData.l_name === null) {
      setLastNameError(Alert.alertForLastName);
      errorHas = true;
    } else if (
      profileData.l_name.length > 50 ||
      profileData.l_name.length < 2
    ) {
      setLastNameError(Alert.alertForNameCharacter);
      errorHas = true;
    } else {
      setLastNameError("");
    }
    const phoneRegex =
      /^(?!\s*$)(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
    const isPhoneNumber = phoneRegex.test(profileData.phone);
    if (profileData.phone === "" || profileData.phone === null) {
      setPhoneError(Alert.alertForPhoneRequirement);
      errorHas = true;
    } else if (!isPhoneNumber) {
      setPhoneError(Alert.alertForInvalidPhone);
      errorHas = true;
    } else {
      setPhoneError("");
    }

    // if any errors are there don't call the api
    if (errorHas === true) return;

    const formData = new FormData();
    formData.append("basic_info", 1);
    formData.append("address_info", 0);
    formData.append("payment_info", 0);
    formData.append("business_info", 0);
    if (profileData.f_name !== null) {
      formData.append("f_name", profileData.f_name);
    }
    if (profileData.l_name !== null) {
      formData.append("l_name", profileData.l_name);
    }
    if (profileData.phone !== null) {
      formData.append("phone", profileData.phone);
    }
    if (profileData.image !== null) {
      formData.append("image", profileData.image);
    }
    formData.append("email", profileData.email);

    // as per PUT rules for  passing formData
    formData.append("_method", "PUT");

    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellersProfileApi}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
        body: formData,
      }
    );

    const res = await response.json();

    if (res.status === 200) {
      // Set Profile Status
      if (res.data && res.data.status === 1) {
        props.profileUnverified(false);
        contextObj.setInput("isSellerVerified", "true");
      } else {
        props.profileUnverified(true);
        contextObj.setInput("isSellerVerified", "false");
      }

      if (res.data) {
        const resData = res.data;
        setFirstName(resData.f_name);
        setLastName(resData.l_name);

        const fullName = resData.f_name + " " + resData.l_name;
        const firstName = resData.f_name;
        const firstChar = resData.f_name.charAt(0).toUpperCase();

        contextObj.setInput("firstName", firstName);
        contextObj.setInput("fullName", fullName);
        contextObj.setInput("email", resData.email);
        contextObj.setInput("firstChar", firstChar);
        contextObj.setInput("name", fullName);

        setDataToCookies("firstName", firstName, "/");
        setDataToCookies("fullName", fullName, "/");
        setDataToCookies("email", resData.email, "/");
        setDataToCookies("firstChar", firstChar, "/");

        props.setUserName(fullName);
        props.setEmail(resData.email);
        props.setFirstChar(firstChar);
      }

      setEditModeOn(false);
      setIsDataChanged(false);
      showToast("success", Alert.alertForProfileUpdate);

      contextObj.setInput(
        "reFetchProfileDetails",
        !contextObj.inputValue.reFetchProfileDetails
      );
    }
  };

  const isPasswordValid = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*()_\-+=])[^\s]{6,}$/;
    return passwordRegex.test(password);
  };
  const handleLogout = async () => {
    const token = getDataFromCookies("R3p7uLw9Xk");
    if (!token) {
      showToast("info", Alert.alertForSomethingWrong);
      navigate(`${navigation.home}`);
    } else {
      const response = await fetch(
        `${API_HOST}/${ApiEndpoint.SellersLogoutApi}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = await response.json();

      if (res.status === 200 || res.status === 401) {
        removeDataFromCookie("R3p7uLw9Xk");
        removeDataFromCookie("isLoggedIn");
        removeDataFromCookie("fullName");
        removeDataFromCookie("firstChar");
        removeDataFromCookie("email");
      } else {
        showToast("info", "Something went wrong");
      }
    }
  };
  const handlePasswordChange = (event) => {
    let { name, value } = event.target;
    value = event.target.value.replace(
      /[#!?><,~|\\[\]{:;"/+\-$%^&*()_=}]/g,
      ""
    );
    setpasswordContainerError((prevData) => ({
      ...prevData,
      passwordError: "",
    }));
    if (name === "current_password") {
      setpasswordContainerError((prevData) => ({
        ...prevData,
        currentPasswordError: "",
        newPasswordError: "",
        confirmNewPasswordError: "",
      }));
      if (value.trim() !== "" || value == "") {
        setProfileChangePassword((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (name === "new_password") {
      if (value.trim() !== "" || value == "") {
        setProfileChangePassword((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (name === "new_password_confirmation") {
      if (value.trim() !== "" || value == "") {
        setProfileChangePassword((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };
  const SaveNewPassword = async () => {
    let errorHas = false;
    if (
      ProfileChangePassword.current_password === "" ||
      ProfileChangePassword.current_password === null
    ) {
      setpasswordContainerError((prevData) => ({
        ...prevData,
        currentPasswordError: Alert.alertForCurrentPassword,
      }));
      errorHas = true;
    } else {
      setpasswordContainerError((prevData) => ({
        ...prevData,
        currentPasswordError: "",
      }));
    }

    if (
      ProfileChangePassword.new_password === "" ||
      ProfileChangePassword.new_password === null
    ) {
      setpasswordContainerError((prevData) => ({
        ...prevData,
        newPasswordError: Alert.alertForNewPassword,
      }));
      errorHas = true;
    } else if (!isPasswordValid(ProfileChangePassword.new_password)) {
      setpasswordContainerError((prevData) => ({
        ...prevData,
        newPasswordError: Alert.alertForpasswordCharacters,
      }));
      errorHas = true;
    } else {
      setpasswordContainerError((prevData) => ({
        ...prevData,
        newPasswordError: "",
        passwordError: "",
      }));
    }

    if (
      ProfileChangePassword.new_password_confirmation === "" ||
      ProfileChangePassword.new_password_confirmation === null
    ) {
      setpasswordContainerError((prevData) => ({
        ...prevData,
        confirmNewPasswordError: Alert.alertForConfirmNewPassword,
      }));
      errorHas = true;
    } else if (
      !isPasswordValid(ProfileChangePassword.new_password_confirmation)
    ) {
      setpasswordContainerError((prevData) => ({
        ...prevData,
        confirmNewPasswordError: Alert.alertForpasswordCharacters,
      }));
      errorHas = true;
    } else {
      setpasswordContainerError((prevData) => ({
        ...prevData,
        confirmNewPasswordError: "",
        passwordError: "",
      }));
    }
    if (
      (ProfileChangePassword.new_password !== "" ||
        ProfileChangePassword.new_password !== null) &&
      (ProfileChangePassword.new_password_confirmation !== "" ||
        ProfileChangePassword.new_password_confirmation !== null) &&
      isPasswordValid(ProfileChangePassword.new_password_confirmation) &&
      isPasswordValid(ProfileChangePassword.new_password)
    ) {
      if (
        ProfileChangePassword.new_password_confirmation !==
        ProfileChangePassword.new_password
      ) {
        setpasswordContainerError((prevData) => ({
          ...prevData,
          passwordError: Alert.alertForSamePassword,
        }));
        errorHas = true;
      } else {
        setpasswordContainerError((prevData) => ({
          ...prevData,
          passwordError: "",
        }));
      }
    }

    if (errorHas === true) {
      return;
    }

    const url = `${API_HOST}/${ApiEndpoint.SellerUpdatePassword}`;
    const payload = JSON.stringify(ProfileChangePassword);

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: payload,
    });

    const res = await response.json();
    if (res.status === 200) {
      showToast("success", Alert.alertForPasswordUpdated);

      setProfileChangePassword((prev) => ({
        ...prev,
        current_password: "",
        new_password: "",
        new_password_confirmation: "",
      }));
      setpasswordContainerError((prevData) => ({
        ...prevData,
        currentPasswordError: "",
        newPasswordError: "",
        confirmNewPasswordError: "",
        passwordError: "",
      }));
      setUpdatePasswordModal(false);

      //logout the user
      await handleLogout();
      removeDataFromCookie("isLoggedIn");
      removeDataFromCookie("R3p7uLw9Xk");
      removeDataFromCookie("fullName");
      removeDataFromCookie("firstChar");
      removeDataFromCookie("email");
      contextObj.setInput("navigation", "true");
      contextObj.setInput("navigationFrom", "Logout");
      // showToast("success", Alert.alertForLogout);
      navigate(`${navigation.home}`);
    } else if (res.status === 401) {
      if (res.message == "Incorrect current password.") {
        setpasswordContainerError((prevData) => ({
          ...prevData,
          currentPasswordError: Alert.alertforIncorrectcurrentPassword,
        }));
      }
    } else if (res.status === 409) {
      setpasswordContainerError((prevData) => ({
        ...prevData,
        passwordError: res.message,
      }));
    }
  };

  const resetTimer = () => {
    setIsActive(false);
    setSeconds(60);
  };
  // settimer
  useEffect(() => {
    let interval = null;

    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
    if (seconds === 0) {
      resetTimer();
    }

    return () => clearInterval(interval);
  }, [isActive, seconds]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleSelectChange = (e) => {
    setDeleteSelectedValue(e.target.value);
  };

  const startTimer = () => {
    setIsActive(true);
  };

  const sendRequetOtpVerifation = async () => {
    setDeactivateAccountError("");
    const payload = {
      otp_send_via: "",
    };
    if (deleteSelectedValue == "Phone") {
      payload.phone = profileData.phone;
    } else if (deleteSelectedValue == "Email") {
      payload.email = profileData.email;
    }
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellerDeactivateAccountSentOTP}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const deactivateAccount = await response.json();
    const stringifiedResp = response;
    if (
      deactivateAccount.status == 500 ||
      deactivateAccount.status == 501 ||
      deactivateAccount.status == 502 ||
      deactivateAccount.status == 503 ||
      (deactivateAccount.message &&
        deactivateAccount.message.toLowerCase().includes("server error"))
    ) {
      if (deactivateAccount.status == 503) {
      } else {
      }
      contextObj.setInput("serviceUnavailable", true);
    } else {
      contextObj.setInput("serviceUnavailable", false);
    }
    if (deactivateAccount.status == 201 || deactivateAccount.remember_token) {
      setDeactivateModalVisible(false);
      if (deleteSelectedValue == "Phone") {
        setDeactivateAccountByPhoneModalPopup(true);
      } else {
        setDeactivateAccountByEmailModalPopup(true);
      }
      setOtpVerifyToken(deactivateAccount.data);
      setIsActive(true);
      startTimer();
    }

    if (deactivateAccount.status == 429) {
      setDeactivateAccountError(deactivateAccount.message);
    }
  };

  const checkAbleToDeactivate = async () => {
    const token = getDataFromCookies("R3p7uLw9Xk");
    if (token == null || token == "") {
      return;
    }
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.CheckDeactivateConditions}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    const res = await response.json();

    if (res.status == 200) {
      setDeactivateModalVisible(true);
      if (profileData.email == null) {
        setDeleteSelectedValue("Phone");
      } else if (profileData.phone == null) {
        setDeleteSelectedValue("Email");
      } else if (profileData.phone !== null && profileData.email !== null) {
        setDeleteSelectedValue("Email");
      }
    } else if (res.status == 403) {
      setUnableToDelete(true);
    }
  };

  const resendVerifyOtp = async () => {
    setOtpValidationMessage("");
    const payload = {
      otp_send_via: "",
      otp_verify_token: "",
    };
    payload.otp_verify_token = otpVerifyToken;
    if (deleteSelectedValue == "Phone") {
      payload.phone = profileData.phone;
    } else if (deleteSelectedValue == "Email") {
      payload.email = profileData.email;
    }
    const token = getDataFromCookies("R3p7uLw9Xk");
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellerDeactivateAccountResendOTP}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const otpSendMessage = await response.json();
    if (
      otpSendMessage.status == 500 ||
      otpSendMessage.status == 501 ||
      otpSendMessage.status == 502 ||
      otpSendMessage.status == 503 ||
      (otpSendMessage.message &&
        otpSendMessage.message.toLowerCase().includes("server error"))
    ) {
      contextObj.setInput("serviceUnavailable", true);
    } else {
      contextObj.setInput("serviceUnavailable", false);
    }
    if (otpSendMessage.status == 201) {
      setOtpVerifyToken(otpSendMessage.data);
      setIsActive(true);
      startTimer();
    }
    if (otpSendMessage.status == 429) {
      setOtpValidationMessage(otpSendMessage.message);
    }
  };

  const handelOtpSubmit = async () => {
    const payload = {
      otp: "",
      otp_send_via: "",
      otp_verify_token: "",
    };

    let errorFound = false;
    if (otp == "" || otp == null) {
      setOtpValidationMessage(Alert.alertForOtpRequirement);
      errorFound = true;
    }
    if (otp.length > 1 && otp.length < 4) {
      setOtpValidationMessage(Alert.alertForOtpLength);
      errorFound = true;
    }
    if (errorFound == true) return;
    payload.otp = otp;
    payload.otp_verify_token = otpVerifyToken;
    if (deleteSelectedValue == "Phone") {
      payload.phone = profileData.phone;
      //payload.otp_send_via = "phone";
    } else if (deleteSelectedValue == "Email") {
      payload.email = profileData.email;
      //payload.otp_send_via = "email";
    }
    const token = getDataFromCookies("R3p7uLw9Xk");
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellerDeactivateAccountVerifyOTP}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const deactivateAccount = await response.json();
    setOTP("");
    if (
      deactivateAccount.status == 500 ||
      deactivateAccount.status == 501 ||
      deactivateAccount.status == 502 ||
      deactivateAccount.status == 503 ||
      (deactivateAccount.message &&
        deactivateAccount.message.toLowerCase().includes("server error"))
    ) {
      if (deactivateAccount.status == 503) {
      } else {
      }
    } else {
    }
    if (deactivateAccount.status == 200) {
      showToast("success", deactivateAccount.message);
      removeDataFromCookie("isLoggedIn");
      removeDataFromCookie("R3p7uLw9Xk");
      removeDataFromCookie("fullName");
      removeDataFromCookie("firstChar");
      removeDataFromCookie("email");
      navigate("/");
    } else {
      setOtpValidationMessage(deactivateAccount.message);
    }
  };

  const handelOtpChange = (event) => {
    setOtpValidationMessage("");
    const enteredOTP = event.target.value.replace(/[^0-9]/g, "");
    setOTP(enteredOTP);
  };

  const handleResendOtpPhoneAndEmail = async () => {
    const payload = {};
    const newPhoneInput = document.querySelector(".modal-new-phone");
    const newPhoneInputValue = newPhoneInput.value.trim();
    const newEmailInput = document.querySelector(".modal-new-email");
    const newEmailInputValue = newEmailInput.value.trim();
    if (newPhoneInputValue === "" || newPhoneInputValue === null) {
      payload.email = newEmailInputValue;
      payload.otp_verify_token = newEmailVerifyTokenInModal;
    } else {
      payload.phone = newPhoneInputValue;
      payload.otp_verify_token = newPhoneVerifyTokenInModal;
    }
    const PayloadParams = JSON.stringify(payload);
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellersProfileUpdateEmailPhoneResendOtp}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: PayloadParams,
      }
    );

    const res = await response.json();

    if (res.status === 201) {
      setIsActive(true);
      startTimer();
      if (newPhoneInputValue === "" || newPhoneInputValue === null) {
        setNewEmailVerifyTokenInModal(res.data);
      } else {
        setNewPhoneVerifyTokenInModal(res.data);
      }
    } else if (
      res.status === 401 ||
      res.status === 404 ||
      res.status === 409 ||
      res.status === 429
    ) {
      setIsLoading(false);
      setEmailNewModalError(res.message);
    } else if (res.status === 400) {
      const errors = res.errors;
      let errorMessage = "";
      if (errors) {
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((message) => {
            errorMessage = errorMessage + " " + message;
          });
        });
      }
      setIsLoading(false);
      setEmailNewModalError(errorMessage);
    } else {
      setIsLoading(false);
      console.error(res);
    }
  };

  return (
    <>
      {pageLoadderForMobileScreen === false ? (
        <div className="basic-info">
          <div className="profile-content-heading">
            <h3 id="Profile-Information-heading-name">Profile Information</h3>
            <button
              type="button"
              id="seller-setEditProfileButton"
              className={`blue-edit-btn ${editModeOn ? "hidden" : ""}`}
              onClick={() => {
                setProfileData((prevData) => ({
                  ...prevData,
                  f_name: firstName,
                  l_name: lastName,
                }));
                setEditModeOn(true);
              }}
            >
              Edit
            </button>
          </div>
          <div className="profile-details">
            {editModeOn ? (
              <>
                <div className="first-last-name-input">
                  <div>
                    <label>
                      First Name <span className="red">*</span>
                    </label>
                    <input
                      id="seller-profilefirstName"
                      type="text"
                      name="f_name"
                      className="form-control"
                      onChange={handleChange}
                      value={profileData.f_name}
                    />
                    {firstNameError && (
                      <span className="profile-error-red">
                        {firstNameError}
                      </span>
                    )}
                  </div>
                  <div>
                    <label>
                      Last Name <span className="red">*</span>
                    </label>
                    <input
                      id="seller-profilelastName"
                      type="text"
                      name="l_name"
                      className="form-control"
                      onChange={handleChange}
                      value={profileData.l_name}
                    />
                    {lastNameError && (
                      <span className="profile-error-red">{lastNameError}</span>
                    )}
                  </div>
                </div>
                <div className="profile-button-row">
                  <button
                    type="button"
                    id="seller-profileButton-forDataChanged"
                    disabled={!isDataChanged}
                    className="profile-btn"
                    onClick={basicInformationSubmit}
                    style={{
                      opacity: isDataChanged ? 1 : 0.5,
                      pointerEvents: isDataChanged ? "" : "none",
                    }}
                  >
                    Update Profile
                  </button>
                  <button
                    type="button"
                    className="profile-btn cancel-btn"
                    onClick={() => {
                      setEditModeOn(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <div>
                <p className="profile-label-view">Name</p>
                <p className="profile-input-view">
                  {/* {profileData.f_name} {profileData.l_name} */}
                  {firstName} {lastName}
                </p>
              </div>
            )}
            <div className="profile-hr" />

            <div className="same-line">
              <div className="Email_phone_div">
                <label className="LableTagForEmailandPhone">Email</label>
                <div className="profile-email-input">
                  <li className="ProfileEmailPhoneReadOnly">
                    {profileData.email}
                  </li>
                  <span
                    id="seller-profile-emailAndPhoneEdit"
                    className="profile-edit-pen"
                    onClick={() => {
                      {
                        profileData.email && setEmailUpdateModal(true);
                      }
                    }}
                  >
                    <svg
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.00627 11.0312C1.63437 11.0312 1.28686 10.9032 1.0369 10.6654C0.71987 10.3666 0.567452 9.91549 0.622322 9.42776L0.8479 7.45243C0.890577 7.08053 1.11615 6.5867 1.37831 6.31845L6.3837 1.02045C7.63352 -0.30253 8.93822 -0.339111 10.2612 0.910712C11.5842 2.16053 11.6208 3.46523 10.3709 4.78821L5.36555 10.0862C5.10949 10.3606 4.63395 10.6166 4.26205 10.6776L2.29891 11.0129C2.19527 11.019 2.10382 11.0312 2.00627 11.0312ZM8.34074 0.904615C7.87129 0.904615 7.46282 1.19726 7.04824 1.63622L2.04285 6.94037C1.92092 7.0684 1.78069 7.37324 1.75631 7.55004L1.53073 9.52531C1.50634 9.7265 1.55512 9.89111 1.66486 9.99475C1.7746 10.0984 1.93921 10.135 2.1404 10.1045L4.10353 9.76923C4.28034 9.73875 4.57298 9.58018 4.69491 9.45215L9.7003 4.15415C10.4563 3.34939 10.7306 2.60559 9.62714 1.56915C9.13941 1.09971 8.71873 0.904615 8.34074 0.904615Z"
                        fill="#888888"
                      />
                      <path
                        d="M9.20042 5.80701C9.18823 5.80701 9.16994 5.80701 9.15775 5.80701C7.25558 5.61801 5.72531 4.17313 5.43267 2.28315C5.39609 2.03319 5.56679 1.80152 5.81676 1.75884C6.06672 1.72226 6.2984 1.89297 6.34107 2.14293C6.57275 3.61833 7.7677 4.75232 9.25529 4.89864C9.50526 4.92302 9.68816 5.1486 9.66377 5.39853C9.63329 5.63021 9.4321 5.80701 9.20042 5.80701Z"
                        fill="#888888"
                      />
                      <path
                        d="M11.4313 12.9995H0.457252C0.207288 12.9995 0 12.7922 0 12.5422C0 12.2922 0.207288 12.085 0.457252 12.085H11.4313C11.6813 12.085 11.8885 12.2922 11.8885 12.5422C11.8885 12.7922 11.6813 12.9995 11.4313 12.9995Z"
                        fill="#888888"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              <div className="Email_phone_div">
                <label className="LableTagForEmailandPhone">Phone</label>
                <div className="profile-phone-input">
                  <li className="ProfileEmailPhoneReadOnly">
                    {profileData.phone}
                  </li>
                  <span
                    id="seller-profile-PhoneEdit"
                    className="profile-edit-pen"
                    onClick={() => {
                      {
                        profileData.phone && setPhoneUpdateModal(true);
                      }
                    }}
                  >
                    <svg
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.00627 11.0312C1.63437 11.0312 1.28686 10.9032 1.0369 10.6654C0.71987 10.3666 0.567452 9.91549 0.622322 9.42776L0.8479 7.45243C0.890577 7.08053 1.11615 6.5867 1.37831 6.31845L6.3837 1.02045C7.63352 -0.30253 8.93822 -0.339111 10.2612 0.910712C11.5842 2.16053 11.6208 3.46523 10.3709 4.78821L5.36555 10.0862C5.10949 10.3606 4.63395 10.6166 4.26205 10.6776L2.29891 11.0129C2.19527 11.019 2.10382 11.0312 2.00627 11.0312ZM8.34074 0.904615C7.87129 0.904615 7.46282 1.19726 7.04824 1.63622L2.04285 6.94037C1.92092 7.0684 1.78069 7.37324 1.75631 7.55004L1.53073 9.52531C1.50634 9.7265 1.55512 9.89111 1.66486 9.99475C1.7746 10.0984 1.93921 10.135 2.1404 10.1045L4.10353 9.76923C4.28034 9.73875 4.57298 9.58018 4.69491 9.45215L9.7003 4.15415C10.4563 3.34939 10.7306 2.60559 9.62714 1.56915C9.13941 1.09971 8.71873 0.904615 8.34074 0.904615Z"
                        fill="#888888"
                      />
                      <path
                        d="M9.20042 5.80701C9.18823 5.80701 9.16994 5.80701 9.15775 5.80701C7.25558 5.61801 5.72531 4.17313 5.43267 2.28315C5.39609 2.03319 5.56679 1.80152 5.81676 1.75884C6.06672 1.72226 6.2984 1.89297 6.34107 2.14293C6.57275 3.61833 7.7677 4.75232 9.25529 4.89864C9.50526 4.92302 9.68816 5.1486 9.66377 5.39853C9.63329 5.63021 9.4321 5.80701 9.20042 5.80701Z"
                        fill="#888888"
                      />
                      <path
                        d="M11.4313 12.9995H0.457252C0.207288 12.9995 0 12.7922 0 12.5422C0 12.2922 0.207288 12.085 0.457252 12.085H11.4313C11.6813 12.085 11.8885 12.2922 11.8885 12.5422C11.8885 12.7922 11.6813 12.9995 11.4313 12.9995Z"
                        fill="#888888"
                      />
                    </svg>
                  </span>
                </div>

                {phoneError && (
                  <span className="profile-error-red">{phoneError}</span>
                )}
              </div>
            </div>

            {(featureFlags.canSellerProfileChangePassword ||
              featureFlags.canSellerProfileDeleteAccount) && (
              <div className="profile-hr" />
            )}

            <div className="passord-change-remove-account">
              {/* Password chnage */}
              {featureFlags.canSellerProfileChangePassword && (
                <div className="profile-action-box">
                  <div className="profile-action-header">
                    <p className="profile-action-heading">Password</p>
                    <button
                      type="button"
                      id="sellers-changePassword-forProfile"
                      className="action-link"
                      onClick={() => {
                        setUpdatePasswordModal(true);
                      }}
                    >
                      Change
                    </button>
                  </div>
                  <p className="profile-action-warning">
                    You can reset or change your password by clicking here.
                  </p>
                </div>
              )}

              {/* Remove account */}
              {featureFlags.canSellerProfileDeleteAccount && (
                <>
                  <div className="profile-action-box">
                    <div className="profile-action-header">
                      <p className="profile-action-heading">Remove Account</p>
                      <button
                        id="sellers-removeAccount-fromProfile"
                        className="action-link deactivate-btn"
                        onClick={() => {
                          checkAbleToDeactivate();
                        }}
                      >
                        Deactivate
                      </button>
                    </div>
                    <div>
                      <p className="profile-action-warning">
                        Once you delete your account, there is no going back.
                        Please be certain.
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="profile-loading-container">
          <img className="loader" src={loadingImgPath} />
        </div>
      )}

      {/* update Email Address Modal */}
      <div
        className="modal fade"
        style={{
          display: emailUpdateModal ? "block" : "none",
        }}
      >
        <div className="modal-content">
          <span
            id="sellers-verifyProfileButton"
            className="float-right"
            onClick={() => {
              setEmailUpdateModal(false);
              setEmailUpdateModalError("");
              document.querySelector(".modal-email-update-password").value = "";
            }}
          >
            <FontAwesomeIcon icon={faXmark} className="modal-close-icon" />
          </span>
          <div className="modal-header">
            <h3 className="modal-header">Verify Your Account</h3>
          </div>
          <div className="modal-body">
            <div className="" style={{ textAlign: "left" }}>
              <div>
                <label className="Profile_lable_tag">
                  <input
                    id="sellers-emailUpdataion-forProfileUpdate"
                    type="email"
                    className="Profile_input_tag"
                    readOnly={true}
                    value={profileData.email}
                  />
                  <span className="Profile_span_tag">Email</span>
                </label>
              </div>
              <div>
                <label className="Profile_lable_tag">
                  <input
                    type="password"
                    placeholder="password"
                    id="profile_Input_Email_Model"
                    className="Profile_input_tag modal-email-update-password"
                    onKeyPress={verifyPasswordForEmailUpdateKeyPress}
                  />
                  <span className="Profile_span_tag">Password</span>
                  <span className="password-eye-icons-password">
                    {Showcurrent_password_Email ? (
                      <FontAwesomeIcon
                        icon={faEye}
                        className="fontassowmicon"
                        style={{ color: "#CDCDCD" }}
                        id="sellers-showCurrentPassword-forProfileUpdation"
                        onClick={() => {
                          setShowcurrent_password_Email(false);
                          const x = document.getElementById(
                            "profile_Input_Email_Model"
                          );
                          x.type = "password";
                        }}
                        tabindex="0"
                      />
                    ) : (
                      <FontAwesomeIcon
                        id="sellers-showCurrentPassword-forProfileUpdation"
                        className="fontassowmicon"
                        icon={faEyeSlash}
                        style={{ color: "#CDCDCD" }}
                        onClick={() => {
                          setShowcurrent_password_Email(true);
                          const x = document.getElementById(
                            "profile_Input_Email_Model"
                          );
                          x.type = "text";
                        }}
                        tabindex="0"
                      />
                    )}
                  </span>
                </label>
              </div>

              {emailUpdateModalError && (
                <span className="modal-error-red">{emailUpdateModalError}</span>
              )}
            </div>
          </div>
          <div className="modal-button text-align-right">
            <button
              id="sellers-verifyPassword-forEmailUpdate-inProfilePage"
              className={`btn margin-bottom-25 ${
                isLoading ? "disabled-btn" : "primary-btn"
              }`}
              onClick={() => {
                verifyPasswordForEmailUpdate();
              }}
            >
              {isLoading ? (
                <img src={loadingImgPath} className="loading-img-size" />
              ) : (
                "Continue"
              )}
            </button>
          </div>
        </div>
      </div>

      {/* new Email Address Modal */}
      <div
        className="modal fade"
        style={{
          display: newEmailModal ? "block" : "none",
        }}
      >
        <div className="modal-content">
          <span
            className="float-right"
            id="sellers-newDetailsUploaded-forProfilePage"
            onClick={() => {
              setNewEmailModal(false);
              document.querySelector(".modal-new-email").value = "";
              setNewEmailValueInModal("");
              setNewEmailVerifyTokenInModal("");
              setEmailNewModalError("");
            }}
          >
            <FontAwesomeIcon icon={faXmark} className="modal-close-icon" />
          </span>
          <h3 className="modal-header">New Email Address</h3>
          <div className="modal-body">
            <div className="" style={{ textAlign: "left" }}>
              <label className="Profile_lable_tag">
                <input
                  id="sellers-newEmailAddress-forProfileUpdationPage"
                  type="email"
                  placeholder="New Email Address"
                  className="Profile_input_tag modal-new-email"
                  onKeyPress={verifyNewEmailKeyPress}
                  value={newEmailValueInModal}
                  onChange={handleNewEmailInput}
                />
                <span className="Profile_span_tag">New Email Address</span>
              </label>
              {emailNewModalError && (
                <span className="modal-error-red">{emailNewModalError}</span>
              )}
            </div>
          </div>
          <div className="modal-button text-align-right">
            {isActive ? (
              <button
                id="sellers-requestOtp-countdownButton"
                className="deactivate-account-btn"
              >
                Request OTP in {formatTime(seconds)}
              </button>
            ) : (
              <button
                id="sellers-verifyingNewEmail-button-ForSellersProfilePage"
                className={`btn margin-bottom-25 ${
                  isLoading ? "disabled-btn" : "primary-btn"
                }`}
                onClick={verifyNewEmail}
              >
                {isLoading ? (
                  <img src={loadingImgPath} className="loading-img-size" />
                ) : (
                  "Request OTP"
                )}
              </button>
            )}
          </div>
        </div>
      </div>

      {/* verify Email Address Modal */}
      <div
        id="sellers-verifyEmailModalPopup-forSellerProfilePage"
        className="modal fade"
        style={{
          display: verifyEmailAddressModal ? "block" : "none",
        }}
      >
        <div className="modal-content">
          <span
            className="float-right"
            id="sellers-modalContant-verifyEmailPopup-forProfile"
            onClick={() => {
              setVerifyEmailAddressModal(false);
              setNewEmailValueInModal("");
              setNewEmailVerifyTokenInModal("");
              setEmailVerifyModalError("");
              document.querySelector(".modal-verify-email-otp").value = "";
            }}
          >
            <FontAwesomeIcon icon={faXmark} className="modal-close-icon" />
          </span>
          <h3 className="modal-header">Update Email Address</h3>
          <div className="modal-body">
            <div style={{ textAlign: "left" }}>
              {newEmailVerifyTokenInModal && isActive && (
                <p className="modal-oto-sent-info">
                  OTP has been sent to{" "}
                  <span style={{ fontWeight: "500" }}>
                    {newEmailValueInModal}
                  </span>
                </p>
              )}
              <div>
                <label className="Profile_lable_tag">
                  <input
                    id="sellers-modalpopupEmailfeild-forSellersProfile"
                    type="email"
                    className="Profile_input_tag"
                    placeholder=" Email Address"
                    value={newEmailValueInModal}
                    readOnly={true}
                  />
                  <span className="Profile_span_tag"> Email Address</span>
                  <span
                    id="sellers-modalContant-verifyEmailPopup-forProfile"
                    className="Profile_Deactivate_edit-icon"
                    onClick={() => {
                      setVerifyEmailAddressModal(false);
                      setNewEmailModal(true);
                      setNewEmailVerifyTokenInModal("");
                      setEmailVerifyModalError("");
                      document.querySelector(".modal-verify-email-otp").value =
                        "";
                    }}
                  >
                    <svg
                      width="14"
                      height="15"
                      viewBox="0 0 14 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.00432 11.0312C1.63242 11.0312 1.28491 10.9032 1.03494 10.6654C0.717916 10.3666 0.565499 9.91549 0.620369 9.42776L0.845947 7.45243C0.888624 7.08053 1.1142 6.5867 1.37636 6.31845L6.38175 1.02045C7.63157 -0.30253 8.93626 -0.339111 10.2592 0.910712C11.5822 2.16053 11.6188 3.46523 10.369 4.78821L5.3636 10.0862C5.10754 10.3606 4.63199 10.6166 4.26009 10.6776L2.29696 11.0129C2.19332 11.019 2.10187 11.0312 2.00432 11.0312ZM8.33879 0.904615C7.86934 0.904615 7.46086 1.19726 7.04629 1.63622L2.0409 6.94037C1.91896 7.0684 1.77874 7.37324 1.75435 7.55004L1.52878 9.52531C1.50439 9.7265 1.55316 9.89111 1.6629 9.99475C1.77264 10.0984 1.93725 10.135 2.13845 10.1045L4.10158 9.76923C4.27838 9.73875 4.57103 9.58018 4.69296 9.45215L9.69835 4.15415C10.4543 3.34939 10.7287 2.60559 9.62519 1.56915C9.13745 1.09971 8.71678 0.904615 8.33879 0.904615Z"
                        fill="#888888"
                      />
                      <path
                        d="M9.19847 5.80701C9.18628 5.80701 9.16799 5.80701 9.15579 5.80701C7.25362 5.61801 5.72336 4.17313 5.43071 2.28315C5.39413 2.03319 5.56484 1.80152 5.81481 1.75884C6.06477 1.72226 6.29644 1.89297 6.33912 2.14293C6.5708 3.61833 7.76575 4.75232 9.25334 4.89864C9.5033 4.92302 9.68621 5.1486 9.66182 5.39853C9.63133 5.63021 9.43014 5.80701 9.19847 5.80701Z"
                        fill="#888888"
                      />
                      <path
                        d="M11.4313 13.0004H0.457252C0.207288 13.0004 0 12.7932 0 12.5432C0 12.2932 0.207288 12.0859 0.457252 12.0859H11.4313C11.6813 12.0859 11.8885 12.2932 11.8885 12.5432C11.8885 12.7932 11.6813 13.0004 11.4313 13.0004Z"
                        fill="#888888"
                      />
                    </svg>
                  </span>
                </label>
              </div>
              <label className="Profile_lable_tag">
                <input
                  id="sellers-verifyOtp-forUpdatingEmail-ForSellersProfile"
                  type="number"
                  placeholder="OTP"
                  className="Profile_input_tag modal-verify-email-otp"
                  onKeyPress={verifyOTPAndSaveNewEmailKeyPress}
                />

                <span className="Profile_span_tag">OTP</span>
              </label>
              <li
                className="modal-info-resendotp"
                onClick={() => {
                  if (!isActive) {
                    handleResendOtpPhoneAndEmail();
                  }
                }}
              >
                {isActive == true
                  ? `Resend OTP after ${" "}
                    ${formatTime(seconds)}`
                  : "Resend OTP"}
              </li>
              {emailVerifyModalError && (
                <p className="modal-error-red margin-top-25">
                  {emailVerifyModalError}
                </p>
              )}
            </div>
          </div>
          <div className="modal-button text-align-right">
            <button
              className={`btn margin-bottom-25 ${
                isLoading ? "disabled-btn" : "primary-btn"
              }`}
              onClick={verifyOTPAndSaveNewEmail}
            >
              {isLoading ? (
                <img src={loadingImgPath} className="loading-img-size" />
              ) : (
                "Verify & Update"
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Phone update modal */}
      <div
        className="modal fade"
        style={{
          display: phoneUpdateModal ? "block" : "none",
        }}
      >
        <div className="modal-content">
          <span
            id="sellers-updatePhoneNumberModal-forSellersProfile"
            className="float-right"
            onClick={() => {
              setPhoneUpdateModal(false);
              setPhoneUpdateModalError("");
              document.querySelector(".modal-phone-update-password").value = "";
            }}
          >
            <FontAwesomeIcon icon={faXmark} className="modal-close-icon" />
          </span>
          <h3 className="modal-header">Verify Your Account</h3>
          <div className="modal-body">
            <div style={{ textAlign: "left" }}>
              <div>
                <label className="Profile_lable_tag">
                  <input
                    id="sellers-inputFeildForUpdating-PhoneNumber-ProfilePage"
                    type="number"
                    className="Profile_input_tag"
                    readOnly={true}
                    value={profileData.phone}
                  />
                  <span className="Profile_span_tag">Phone</span>
                </label>
              </div>
              <div>
                <label className="Profile_lable_tag">
                  <input
                    type="password"
                    placeholder="password"
                    id="Phone_Modal_Password"
                    className="Profile_input_tag modal-phone-update-password"
                    onKeyPress={verifyPasswordForPhoneUpdateKeyPress}
                  />
                  <span className="Profile_span_tag">Password</span>
                  <span className="password-eye-icons-password">
                    {showcurrent_password ? (
                      <FontAwesomeIcon
                        id="sellers-profileFontAwsomeIconButton"
                        icon={faEye}
                        className="fontassowmicon"
                        style={{ color: "#CDCDCD" }}
                        onClick={() => {
                          setShowcurrent_password(false);
                          const x = document.getElementById(
                            "Phone_Modal_Password"
                          );
                          x.type = "password";
                        }}
                        tabindex="0"
                      />
                    ) : (
                      <FontAwesomeIcon
                        id="sellers-profileFontAwsomeIconButton"
                        className="fontassowmicon"
                        icon={faEyeSlash}
                        style={{ color: "#CDCDCD" }}
                        onClick={() => {
                          setShowcurrent_password(true);
                          const x = document.getElementById(
                            "Phone_Modal_Password"
                          );
                          x.type = "text";
                        }}
                        tabindex="0"
                      />
                    )}
                  </span>
                </label>
              </div>
              {phoneUpdateModalError && (
                <span className="modal-error-red">{phoneUpdateModalError}</span>
              )}
            </div>
          </div>
          <div className="modal-button text-align-right">
            <button
              id="sellers-verifyPassword-forUdatingThePhone-forSellerProfile"
              className={`btn margin-bottom-25 ${
                isLoading ? "disabled-btn" : "primary-btn"
              }`}
              onClick={() => {
                verifyPasswordForPhoneUpdate();
              }}
            >
              {isLoading ? (
                <img src={loadingImgPath} className="loading-img-size" />
              ) : (
                "Continue"
              )}
            </button>
          </div>
        </div>
      </div>

      {/* new phone add modal */}
      <div
        className="modal fade"
        style={{
          display: newPhoneModal ? "block" : "none",
        }}
      >
        <div className="modal-content">
          <span
            id="seller-newPhoneNumberModal-forSellerProfile"
            className="float-right"
            onClick={() => {
              setNewPhoneModal(false);
              document.querySelector(".modal-new-phone").value = "";
              setNewPhoneNumberInModal("");
              setNewPhoneVerifyTokenInModal("");
              setPhoneNewModalError("");
            }}
          >
            <FontAwesomeIcon icon={faXmark} className="modal-close-icon" />
          </span>
          <h3 className="modal-header">New Phone Number</h3>
          <div className="modal-body">
            <div className="" style={{ textAlign: "left" }}>
              <label className="Profile_lable_tag">
                <input
                  id="seller-newPhoneNumberModalInputFeild"
                  type="text"
                  placeholder="New Phone Number"
                  className="Profile_input_tag modal-new-phone"
                  onKeyPress={verifyNewPhoneKeyPress}
                  value={newPhoneNumberInModal}
                  onChange={handleNewPhoneInput}
                />
                <span className="Profile_span_tag">New Phone Number</span>
              </label>
              {phoneNewModalError && (
                <span className="modal-error-red">{phoneNewModalError}</span>
              )}
            </div>
          </div>

          <div className="modal-button text-align-right">
            {isActive ? (
              <button className="deactivate-account-btn">
                Request OTP in {formatTime(seconds)}
              </button>
            ) : (
              <button
                id="seller-newPhoneNumberModalVerifyingButton"
                className={`btn margin-bottom-25 ${
                  isLoading ? "disabled-btn" : "primary-btn"
                }`}
                onClick={verifyNewPhone}
              >
                {isLoading ? (
                  <img src={loadingImgPath} className="loading-img-size" />
                ) : (
                  "Request OTP"
                )}
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Verify phone number modal */}
      <div
        className="modal fade"
        style={{
          display: verifyPhoneNumberModal ? "block" : "none",
        }}
      >
        <div className="modal-content">
          <span
            id="seller-verifyPhoneNumberModal-forProfile"
            className="float-right"
            onClick={() => {
              setVerifyPhoneNumberModal(false);
              setNewPhoneNumberInModal("");
              setNewPhoneVerifyTokenInModal("");
              setPhoneVerifyModalError("");
              document.querySelector(".modal-verify-phone-otp").value = "";
            }}
          >
            <FontAwesomeIcon icon={faXmark} className="modal-close-icon" />
          </span>
          <h3 className="modal-header">Update Phone Number</h3>
          <div className="modal-body">
            <div style={{ textAlign: "left" }}>
              {newPhoneVerifyTokenInModal && isActive == true ? (
                <p className="modal-oto-sent-info">
                  OTP has been sent to{" "}
                  <span style={{ fontWeight: "500" }}>
                    {newPhoneNumberInModal}
                  </span>
                </p>
              ) : (
                <></>
              )}
              <label className="Profile_lable_tag">
                <input
                  id="seller-verifyPhoneNumberModal"
                  type="number"
                  className="Profile_input_tag"
                  value={newPhoneNumberInModal}
                  readOnly={true}
                  placeholder="Phone Number"
                />
                <span className="Profile_span_tag"> Phone Number</span>
                <span
                  id="seller-verifyPhoneNumberModal-forProfile"
                  className="Profile_Deactivate_edit-icon"
                  onClick={() => {
                    setVerifyPhoneNumberModal(false);
                    setNewPhoneModal(true);
                    setNewPhoneVerifyTokenInModal("");
                    setPhoneVerifyModalError("");
                    document.querySelector(".modal-verify-phone-otp").value =
                      "";
                  }}
                >
                  <svg
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.00432 11.0312C1.63242 11.0312 1.28491 10.9032 1.03494 10.6654C0.717916 10.3666 0.565499 9.91549 0.620369 9.42776L0.845947 7.45243C0.888624 7.08053 1.1142 6.5867 1.37636 6.31845L6.38175 1.02045C7.63157 -0.30253 8.93626 -0.339111 10.2592 0.910712C11.5822 2.16053 11.6188 3.46523 10.369 4.78821L5.3636 10.0862C5.10754 10.3606 4.63199 10.6166 4.26009 10.6776L2.29696 11.0129C2.19332 11.019 2.10187 11.0312 2.00432 11.0312ZM8.33879 0.904615C7.86934 0.904615 7.46086 1.19726 7.04629 1.63622L2.0409 6.94037C1.91896 7.0684 1.77874 7.37324 1.75435 7.55004L1.52878 9.52531C1.50439 9.7265 1.55316 9.89111 1.6629 9.99475C1.77264 10.0984 1.93725 10.135 2.13845 10.1045L4.10158 9.76923C4.27838 9.73875 4.57103 9.58018 4.69296 9.45215L9.69835 4.15415C10.4543 3.34939 10.7287 2.60559 9.62519 1.56915C9.13745 1.09971 8.71678 0.904615 8.33879 0.904615Z"
                      fill="#888888"
                    />
                    <path
                      d="M9.19847 5.80701C9.18628 5.80701 9.16799 5.80701 9.15579 5.80701C7.25362 5.61801 5.72336 4.17313 5.43071 2.28315C5.39413 2.03319 5.56484 1.80152 5.81481 1.75884C6.06477 1.72226 6.29644 1.89297 6.33912 2.14293C6.5708 3.61833 7.76575 4.75232 9.25334 4.89864C9.5033 4.92302 9.68621 5.1486 9.66182 5.39853C9.63133 5.63021 9.43014 5.80701 9.19847 5.80701Z"
                      fill="#888888"
                    />
                    <path
                      d="M11.4313 13.0004H0.457252C0.207288 13.0004 0 12.7932 0 12.5432C0 12.2932 0.207288 12.0859 0.457252 12.0859H11.4313C11.6813 12.0859 11.8885 12.2932 11.8885 12.5432C11.8885 12.7932 11.6813 13.0004 11.4313 13.0004Z"
                      fill="#888888"
                    />
                  </svg>
                </span>
              </label>
              <label className="Profile_lable_tag">
                <input
                  id="sellers-verifyPhoneNumberModalInputFeildForVerifying"
                  type="number"
                  className="Profile_input_tag modal-verify-phone-otp"
                  placeholder="OTP"
                  onKeyPress={verifyOTPAndSaveNewPhoneKeyPress}
                />
                <span className="Profile_span_tag">OTP</span>
              </label>

              <li
                id="sellers-verifyPhoneNumberModalActiveButton"
                className="modal-info-resendotp"
                onClick={() => {
                  if (!isActive) {
                    handleResendOtpPhoneAndEmail();
                  }
                }}
              >
                {isActive
                  ? `Resend OTP after ${" "}
                    ${formatTime(seconds)}`
                  : "Resend OTP"}
              </li>
              {phoneVerifyModalError && (
                <p className="modal-error-red margin-top-25">
                  {phoneVerifyModalError}
                </p>
              )}
            </div>
          </div>
          <div className="modal-button text-align-right">
            <button
              id="sellers-verifyOTPAndSaveNewPhone-forProfile"
              className={`btn margin-bottom-25 ${
                isLoading ? "disabled-btn" : "primary-btn"
              }`}
              onClick={verifyOTPAndSaveNewPhone}
            >
              {isLoading ? (
                <img src={loadingImgPath} className="loading-img-size" />
              ) : (
                "Verify & Update"
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Update password modal */}
      <div
        className="modal fade"
        style={{
          display: updatePasswordModal ? "block" : "none",
        }}
      >
        <div className="modal-content">
          <span
            id="seller-ChangePassword_Model_popup-forSellerProfile"
            className="float-right"
            onClick={() => {
              setProfileChangePassword((prev) => ({
                ...prev,
                current_password: "",
                new_password: "",
                new_password_confirmation: "",
              }));
              setpasswordContainerError((prevData) => ({
                ...prevData,
                currentPasswordError: "",
                newPasswordError: "",
                confirmNewPasswordError: "",
                passwordError: "",
              }));
              setUpdatePasswordModal(false);
            }}
          >
            <FontAwesomeIcon icon={faXmark} className="modal-close-icon" />
          </span>
          <h3 className="modal-header">Update your password</h3>
          <div className="modal-body">
            <label className="Profile_lable_tag">
              <input
                autocomplete="off"
                className="Profile_input_tag"
                id="current_passwordInputTag"
                type="password"
                maxLength={16}
                name="current_password"
                value={ProfileChangePassword.current_password}
                placeholder="Current Password"
                onChange={handlePasswordChange}
              />
              <span className="Profile_span_tag">Current Password</span>
              <span className="password-eye-icons-password">
                {showcurrent_password ? (
                  <FontAwesomeIcon
                    id="sellers-ChangePassword_Model_popup-fontAwsomeButton"
                    icon={faEye}
                    className="fontassowmicon"
                    style={{ color: "#CDCDCD" }}
                    onClick={() => {
                      setShowcurrent_password(false);
                      const x = document.getElementById(
                        "current_passwordInputTag"
                      );
                      x.type = "password";
                    }}
                    tabindex="0"
                  />
                ) : (
                  <FontAwesomeIcon
                    id="sellers-ChangePassword_Model_popup-fontAwsomeButton"
                    className="fontassowmicon"
                    icon={faEyeSlash}
                    style={{ color: "#CDCDCD" }}
                    onClick={() => {
                      setShowcurrent_password(true);
                      const x = document.getElementById(
                        "current_passwordInputTag"
                      );
                      x.type = "text";
                    }}
                    tabindex="0"
                  />
                )}
              </span>
            </label>

            <li className="profile-error-red-li">
              {passwordContainerError.currentPasswordError}
            </li>
            <label className="Profile_lable_tag">
              <input
                autocomplete="off"
                className="Profile_input_tag"
                type="password"
                maxLength={16}
                id="new_passwordInputTag"
                placeholder="New Password"
                name="new_password"
                value={ProfileChangePassword.new_password}
                onChange={handlePasswordChange}
              />
              <span className="Profile_span_tag">New Password</span>
              <span className="password-eye-icons-password">
                {shownew_password ? (
                  <FontAwesomeIcon
                    id="sellers-ChangePassword_Model_popup-fontAwsomeButton"
                    icon={faEye}
                    className="fontassowmicon"
                    style={{ color: "#CDCDCD" }}
                    onClick={() => {
                      setShownew_password(false);
                      const x = document.getElementById("new_passwordInputTag");
                      x.type = "password";
                    }}
                    tabindex="0"
                  />
                ) : (
                  <FontAwesomeIcon
                    id="sellers-ChangePassword_Model_popup-fontAwsomeButton"
                    className="fontassowmicon"
                    icon={faEyeSlash}
                    style={{ color: "#CDCDCD" }}
                    onClick={() => {
                      setShownew_password(true);
                      const x = document.getElementById("new_passwordInputTag");
                      x.type = "text";
                    }}
                    tabindex="0"
                  />
                )}
              </span>
            </label>
            <li className="profile-error-red-li">
              {passwordContainerError.newPasswordError}
            </li>
            <label className="Profile_lable_tag">
              <input
                autocomplete="off"
                className="Profile_input_tag"
                name="new_password_confirmation"
                id="new_password_confirmationInputTag"
                type="password"
                maxLength={16}
                value={ProfileChangePassword.new_password_confirmation}
                placeholder="Confirm New Password"
                onChange={handlePasswordChange}
              />
              <span className="Profile_span_tag">Confirm New Password</span>
              <span className="password-eye-icons-password">
                {shownew_password_confirmation ? (
                  <FontAwesomeIcon
                    id="sellers-ChangePassword_Model_popup-fontAwsomeButton"
                    icon={faEye}
                    className="fontassowmicon"
                    style={{ color: "#CDCDCD" }}
                    onClick={() => {
                      setShownew_password_confirmation(false);
                      const x = document.getElementById(
                        "new_password_confirmationInputTag"
                      );
                      x.type = "password";
                    }}
                    tabindex="0"
                  />
                ) : (
                  <FontAwesomeIcon
                    id="sellers-ChangePassword_Model_popup-fontAwsomeButton"
                    className="fontassowmicon"
                    icon={faEyeSlash}
                    style={{ color: "#CDCDCD" }}
                    onClick={() => {
                      setShownew_password_confirmation(true);
                      const x = document.getElementById(
                        "new_password_confirmationInputTag"
                      );
                      x.type = "text";
                    }}
                    tabindex="0"
                  />
                )}
              </span>
            </label>
            <li className="profile-error-red-li">
              {passwordContainerError.confirmNewPasswordError}
            </li>
            <li className="profile-error-red-li">
              {passwordContainerError.passwordError}
            </li>
          </div>
          <div className="modal-button text-align-right">
            <button
              id="sellers-ChangePassword_Model_popup-saveButton-forProfile"
              className={`btn margin-bottom-25 ${
                isLoading ? "disabled-btn" : "primary-btn"
              }`}
              onClick={() => {
                SaveNewPassword();
              }}
            >
              {isLoading ? (
                <img src={loadingImgPath} className="loading-img-size" />
              ) : (
                "Save Password"
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Account Deactivate Modal */}
      <div
        className="modal fade"
        style={{
          display: DeactivateAccountModel ? "block" : "none",
        }}
      >
        <div className="deactivate-modal">
          <div
            className="deactivate-modal-cross"
            onClick={() => {
              setDeactivateModalVisible(false);
            }}
          >
            <FontAwesomeIcon
              id="sellers-Deactivate_account_Model_popupDiv-forProfile"
              icon={faXmark}
              className="modal-close-icon"
            />
          </div>
          <div className="deactivate-modal-body">
            <div className="deactivate-warning">
              <h3 className="first-warning-heading">
                When you deactivate your account
              </h3>
              <ul>
                <li>
                  You will be logged out of your {APP_NAME} Seller Account
                </li>
                <li>
                  Your {APP_NAME} Seller Account can't be accessed further.
                </li>
              </ul>
              <h3>Will I be able to reactivate my account?</h3>
              <p>
                Once deactivated, you will lose all data. You might need to
                re-register with your credentials to have a fresh seller
                account.
              </p>
            </div>
            <div className="deactivate-action">
              <h2 className="deactivate-ask">
                Are you sure you want to leave?
              </h2>
              <div className="select-wrapper">
                <label
                  htmlFor="select-input"
                  className={`select-label ${
                    deleteSelectedValue ? "active" : ""
                  }`}
                >
                  Email or Phone
                </label>
                <select
                  id="select-input"
                  className="select-Tag"
                  value={deleteSelectedValue}
                  onChange={(e) => {
                    handleSelectChange(e);
                  }}
                >
                  {profileData.email && (
                    <option value="Email">{profileData.email}</option>
                  )}
                  {profileData.phone && (
                    <option value="Phone">{profileData.phone}</option>
                  )}
                </select>
                <p className="error">{deactivateAccountError}</p>
              </div>
              <div className="deactivate-btn-area">
                {isActive ? (
                  <button
                    type="button"
                    className="deactivate-account-btn disabled-btn"
                  >
                    Request OTP in {formatTime(seconds)}
                  </button>
                ) : (
                  <button
                    type="button"
                    id="sellers-Deactivate_account_Model_popupButton-forProfile"
                    className="deactivate-account-btn"
                    onClick={() => {
                      setOtpValidationMessage("");
                      sendRequetOtpVerifation();
                    }}
                  >
                    Request OTP
                  </button>
                )}
                <button
                  type="button"
                  className="let-me-stay-btn"
                  onClick={() => {
                    setDeactivateModalVisible(false);
                  }}
                >
                  NO, LET ME STAY!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Deactivate by email modal */}
      <div
        className="modal fade"
        style={{
          display: DeactivateAccountByEmailModalPopup ? "block" : "none",
        }}
      >
        <div className="modal-content">
          <span
            id="sellers-RemoveAccountByEmail-forSellerProfile"
            className="float-right"
            onClick={() => {
              setDeactivateAccountByEmailModalPopup(false);
            }}
          >
            <FontAwesomeIcon icon={faXmark} className="modal-close-icon" />
          </span>
          <h3 className="modal-header">Verify OTP</h3>
          <div className="modal-body">
            <label className="Profile_lable_tag">
              <input
                id="sellers-RemoveAccountByEmailInputFeild"
                autocomplete="off"
                className="Profile_input_tag"
                type="text"
                name="email"
                value={profileData.email}
                placeholder="Email"
                readOnly
              />
              <span className="Profile_span_tag">Email</span>
              <span
                id="sellers-RemoveAccountByEmail-button-profilePage"
                className="Profile_Deactivate_edit-icon"
                onClick={() => {
                  setDeactivateAccountByEmailModalPopup(false);
                  setDeleteSelectedValue("Email");
                  setDeactivateModalVisible(true);
                }}
              >
                <svg
                  width="14"
                  height="15"
                  viewBox="0 0 14 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.00432 11.0312C1.63242 11.0312 1.28491 10.9032 1.03494 10.6654C0.717916 10.3666 0.565499 9.91549 0.620369 9.42776L0.845947 7.45243C0.888624 7.08053 1.1142 6.5867 1.37636 6.31845L6.38175 1.02045C7.63157 -0.30253 8.93626 -0.339111 10.2592 0.910712C11.5822 2.16053 11.6188 3.46523 10.369 4.78821L5.3636 10.0862C5.10754 10.3606 4.63199 10.6166 4.26009 10.6776L2.29696 11.0129C2.19332 11.019 2.10187 11.0312 2.00432 11.0312ZM8.33879 0.904615C7.86934 0.904615 7.46086 1.19726 7.04629 1.63622L2.0409 6.94037C1.91896 7.0684 1.77874 7.37324 1.75435 7.55004L1.52878 9.52531C1.50439 9.7265 1.55316 9.89111 1.6629 9.99475C1.77264 10.0984 1.93725 10.135 2.13845 10.1045L4.10158 9.76923C4.27838 9.73875 4.57103 9.58018 4.69296 9.45215L9.69835 4.15415C10.4543 3.34939 10.7287 2.60559 9.62519 1.56915C9.13745 1.09971 8.71678 0.904615 8.33879 0.904615Z"
                    fill="#888888"
                  />
                  <path
                    d="M9.19847 5.80701C9.18628 5.80701 9.16799 5.80701 9.15579 5.80701C7.25362 5.61801 5.72336 4.17313 5.43071 2.28315C5.39413 2.03319 5.56484 1.80152 5.81481 1.75884C6.06477 1.72226 6.29644 1.89297 6.33912 2.14293C6.5708 3.61833 7.76575 4.75232 9.25334 4.89864C9.5033 4.92302 9.68621 5.1486 9.66182 5.39853C9.63133 5.63021 9.43014 5.80701 9.19847 5.80701Z"
                    fill="#888888"
                  />
                  <path
                    d="M11.4313 13.0004H0.457252C0.207288 13.0004 0 12.7932 0 12.5432C0 12.2932 0.207288 12.0859 0.457252 12.0859H11.4313C11.6813 12.0859 11.8885 12.2932 11.8885 12.5432C11.8885 12.7932 11.6813 13.0004 11.4313 13.0004Z"
                    fill="#888888"
                  />
                </svg>
              </span>
            </label>

            <label className="Profile_lable_tag">
              <input
                id="sellers-RemoveAccountByEmailLabelTag"
                autocomplete="off"
                className="Profile_input_tag"
                type="text"
                placeholder="OTP"
                name="otp"
                onChange={handelOtpChange}
                value={otp}
                maxLength={4}
              />
              <span className="Profile_span_tag">OTP</span>
            </label>
            <li className="profile-error-red-li">{otpValidationMessage}</li>

            <li className="deleteResendButtonemail">
              <p
                id="sellers-RemoveAccountByEmail-resendVerifyOtp"
                style={{
                  fontSize: "0.75rem",
                  margin: "0px",
                  color: "#3366cc",
                  cursor: isActive ? "default" : "pointer",
                }}
                onClick={() => {
                  if (!isActive) {
                    resendVerifyOtp();
                  }
                }}
              >
                {isActive
                  ? `Resend OTP after ${" "}
                    ${formatTime(seconds)}`
                  : "Resend OTP"}
              </p>
            </li>
          </div>
          <div className="deactivate-btn-area">
            <button
              type="button"
              id="sellers-RemoveAccountByEmail_handleOtpSubmit"
              className={`btn  ${isLoading ? "disabled-btn" : "primary-btn"}`}
              onClick={() => {
                handelOtpSubmit();
              }}
            >
              {isLoading ? (
                <img src={loadingImgPath} className="loading-img-size" />
              ) : (
                "Confirm Deactivation"
              )}
            </button>
            <button
              type="button"
              id="sellers-RemoveAccountByEmail-setDeactivateAccountByEmailModalPopup"
              className="let-me-stay-btn"
              onClick={() => {
                setDeactivateAccountByEmailModalPopup(false);
              }}
            >
              NO, LET ME STAY!
            </button>
          </div>
        </div>
      </div>

      {/* Deactivate by phone modal */}
      <div
        className="modal fade"
        style={{
          display: DeactivateAccountByPhoneModalPopup ? "block" : "none",
        }}
      >
        <div className="modal-content">
          <span
            id="sellers-Remove_Account_by_Phone_Model_popup"
            className="float-right"
            onClick={() => {
              setDeactivateAccountByPhoneModalPopup(false);
            }}
          >
            <FontAwesomeIcon
              id="sellers-Remove_Account_by_Phone_Model_popup-closeIcon"
              icon={faXmark}
              className="modal-close-icon"
            />
          </span>
          <h3 className="modal-header">Verify OTP</h3>
          <div className="modal-body">
            <label className="Profile_lable_tag">
              <input
                id="sellers-Remove_Account_by_Phone_Model_popup_inputFeild"
                autocomplete="off"
                className="Profile_input_tag"
                type="text"
                name="phone"
                value={profileData.phone}
                placeholder="Phone"
                readOnly
              />
              <span className="Profile_span_tag">Phone</span>
              <span
                id="sellers-Remove_Account_by_Phone_Model_popup-Profile_Deactivate_edit"
                className="Profile_Deactivate_edit-icon"
                onClick={() => {
                  setDeactivateAccountByPhoneModalPopup(false);
                  setDeleteSelectedValue("Phone");
                  setDeactivateModalVisible(true);
                }}
              >
                <svg
                  width="14"
                  height="15"
                  viewBox="0 0 14 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.00432 11.0312C1.63242 11.0312 1.28491 10.9032 1.03494 10.6654C0.717916 10.3666 0.565499 9.91549 0.620369 9.42776L0.845947 7.45243C0.888624 7.08053 1.1142 6.5867 1.37636 6.31845L6.38175 1.02045C7.63157 -0.30253 8.93626 -0.339111 10.2592 0.910712C11.5822 2.16053 11.6188 3.46523 10.369 4.78821L5.3636 10.0862C5.10754 10.3606 4.63199 10.6166 4.26009 10.6776L2.29696 11.0129C2.19332 11.019 2.10187 11.0312 2.00432 11.0312ZM8.33879 0.904615C7.86934 0.904615 7.46086 1.19726 7.04629 1.63622L2.0409 6.94037C1.91896 7.0684 1.77874 7.37324 1.75435 7.55004L1.52878 9.52531C1.50439 9.7265 1.55316 9.89111 1.6629 9.99475C1.77264 10.0984 1.93725 10.135 2.13845 10.1045L4.10158 9.76923C4.27838 9.73875 4.57103 9.58018 4.69296 9.45215L9.69835 4.15415C10.4543 3.34939 10.7287 2.60559 9.62519 1.56915C9.13745 1.09971 8.71678 0.904615 8.33879 0.904615Z"
                    fill="#888888"
                  />
                  <path
                    d="M9.19847 5.80701C9.18628 5.80701 9.16799 5.80701 9.15579 5.80701C7.25362 5.61801 5.72336 4.17313 5.43071 2.28315C5.39413 2.03319 5.56484 1.80152 5.81481 1.75884C6.06477 1.72226 6.29644 1.89297 6.33912 2.14293C6.5708 3.61833 7.76575 4.75232 9.25334 4.89864C9.5033 4.92302 9.68621 5.1486 9.66182 5.39853C9.63133 5.63021 9.43014 5.80701 9.19847 5.80701Z"
                    fill="#888888"
                  />
                  <path
                    d="M11.4313 13.0004H0.457252C0.207288 13.0004 0 12.7932 0 12.5432C0 12.2932 0.207288 12.0859 0.457252 12.0859H11.4313C11.6813 12.0859 11.8885 12.2932 11.8885 12.5432C11.8885 12.7932 11.6813 13.0004 11.4313 13.0004Z"
                    fill="#888888"
                  />
                </svg>
              </span>
            </label>
            <label className="Profile_lable_tag">
              <input
                id="sellers-Profile_Deactivate_editTetInputFeilds"
                autocomplete="off"
                className="Profile_input_tag"
                type="text"
                placeholder="OTP"
                name="new_password"
                onChange={handelOtpChange}
                value={otp}
                maxLength={4}
              />
              <span className="Profile_span_tag">OTP</span>
            </label>
            <li className="profile-error-red-li">{otpValidationMessage}</li>

            <li className="deleteResendButtonemail">
              <p
                id="sellers-Profile_Deactivate_editresendVerifyOtp"
                style={{
                  fontSize: "0.75rem",
                  margin: "0px",
                  color: "#3366cc",
                  cursor: isActive ? "default" : "pointer",
                }}
                onClick={() => {
                  if (!isActive) {
                    resendVerifyOtp();
                  }
                }}
              >
                {isActive
                  ? `Resend OTP after ${" "}
                    ${formatTime(seconds)}`
                  : "Resend OTP"}
              </p>
            </li>
          </div>
          <div className="modal-button text-align-right">
            <button
              id="sellers-Profile_Deactivate_edit-handelOtpSubmit"
              className={`btn margin-bottom-25 ${
                isLoading ? "disabled-btn" : "primary-btn"
              }`}
              onClick={() => {
                handelOtpSubmit();
              }}
            >
              {isLoading ? (
                <img src={loadingImgPath} className="loading-img-size" />
              ) : (
                "Confirm Deactivation"
              )}
            </button>
          </div>
          <button
            type="button"
            id="sellers-handelOtpSubmit_setDeactivateAccountByPhoneModalPopup"
            className="let-me-stay-btn"
            onClick={() => {
              setDeactivateAccountByPhoneModalPopup(false);
            }}
          >
            NO, LET ME STAY!
          </button>
        </div>
      </div>
      {/* Unable to delete Modal popup */}

      {/* Account can't be deactivate modal */}
      <div
        className="modal fade"
        style={{
          display: unableToDelete ? "block" : "none",
        }}
      >
        <div className="modal-content">
          <span
            id="sellers-unableToDeleteModalPopup"
            className="float-right"
            onClick={() => {
              setUnableToDelete(false);
            }}
          >
            <FontAwesomeIcon icon={faXmark} className="modal-close-icon" />
          </span>
          <div className="modal-body">
            <div className="unableToDeleteModalTextHolder">
              <p className="unableToDeleteModalText">
                Thank you for reaching out to deactivate your account. While
                we're sorry to see you leave, we respect your decision. Please
                bear with us as we finalize any pending orders on your behalf
                before processing your request. Your patience is greatly
                appreciated, and we're committed to ensuring a smooth transition
                for you. Rest assured, we'll have everything sorted out for you
                shortly!
              </p>
            </div>
          </div>
          <div className="ButtonContainerDiv">
            <button
              id="sellers-unableToDeleteModalPopup-unableToDeleteButton"
              className="updateyesbutton"
              onClick={() => {
                setUnableToDelete(false);
              }}
            >
              Ok, Sure
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
