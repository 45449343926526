import { toast } from "react-toastify";
import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCartShopping,
  faGift,
  faGlobe,
  faHouse,
  faList,
  faRightFromBracket,
  faTimes,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import navigation from "../../Configs/Navigation.json";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import {
  getDataFromCookies,
  removeDataFromCookie,
  setDataToCookies,
} from "../../Component/Cookies/Cookie";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Nav, NavItem } from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import ApiEndpoint from "../../Configs/APIEndpoints.json";
import Alert from "../../Configs/Alerts.json";
import InputContext from "../Auth/InputContext";
import Toast from "../../Component/Toastify/Toast";
import logo_p1 from "../../Assets/logo_p1.png";
import logo_p2 from "../../Assets/logo_p2.png";
import "./navbar.css";
import { faCreditCardAlt } from "@fortawesome/free-regular-svg-icons";

const APP_NAME = process.env.REACT_APP_NAME;
const API_HOST = process.env.REACT_APP_HOST_API_URL;
const BUYER_URL = process.env.REACT_APP_BUYER_DOMAIN;

const AppNavbar = () => {
  const [onClickEventKey, setOnClickEventKey] = useState("");
  const navigate = useNavigate();
  const { showToast } = Toast();
  const sidebarRef = useRef(null);
  const dropdownRef = useRef(null);
  const divVisiblityRef = useRef(null);
  const contextObj = useContext(InputContext);
  const sellerNameDivVisibleRef = useRef(null);
  const profileDropdownRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [sellerStatus, setSellerStatus] = useState(false);

  // handle product menu dropdown
  const [isProductDropdownOpen, setProductDropdownOpen] = useState(false);
  const openProductDropdown = () => {
    setProductDropdownOpen(true);
  };
  const closeProductDropdown = () => {
    setProductDropdownOpen(false);
  };

  // handle donation menu dropdown
  const [isDonationDropdownOpen, setDonationDropdownOpen] = useState(false);
  const openDonationDropdown = () => {
    setDonationDropdownOpen(true);
  };
  const closeDonationDropdown = () => {
    setDonationDropdownOpen(false);
  };

  // handle mobile menu dropdown
  const [isMobileProductDropdownOpen, setMobileProductDropdownOpen] =
    useState(false);
  const [isMobileDonationDropdownOpen, setMobileDonationDropdownOpen] =
    useState(false);

  // handle profile dropdown
  const [profileDropdown, setProfileDropdown] = useState(false);
  const openProfileDropdown = () => {
    setProfileDropdown(true);
  };
  const closeProfileDropdown = () => {
    setProfileDropdown(false);
  };

  const [featureFlags, setFeatureFlags] = useState({
    canShowProfile: false,
    canShowDonation: false,
    canShowProducts: false,
    canShowPayments: false,
    canShowOrders: false,
    canShowDashboard: false,
    seller_common_navigationbar: false,
  });

  // Hamburger button logic implementaion for small screen.
  useEffect(() => {
    const featureFlags = contextObj.inputValue.featureFlags;
    if (featureFlags) {
      setFeatureFlags((prev) => ({
        ...prev,
        canShowProfile: featureFlags.seller_profile,
        canShowDonation: featureFlags.seller_donation,
        canShowProducts: featureFlags.seller_products,
        canShowPayments: featureFlags.seller_payments,
        canShowOrders: featureFlags.seller_orders,
        canShowDashboard: featureFlags.seller_dashboard,
        canShowSellerNavigationBar: featureFlags.seller_common_navigationbar,
      }));
    }

    const handleOutsideClick = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        !event.target.classList.contains("navbar__hamburger")
      ) {
        setMobileMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [contextObj.inputValue.featureFlags]);

  const handleHamburgerClick = () => {
    setFullName(getDataFromCookies("fullName"));
    setMobileMenuOpen(!isMobileMenuOpen);
    setIsOpen(true);
  };

  const handleCloseClick = () => {
    setMobileMenuOpen(false);
    setIsOpen(false);
  };

  // page load functions
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    const handleClickOutsideForWeb = (event) => {
      if (
        divVisiblityRef.current &&
        dropdownRef.current &&
        !divVisiblityRef.current.contains(event.target) &&
        !dropdownRef.current.contains(event.target)
      ) {
        setProductDropdownOpen(false);
        setDonationDropdownOpen(false);
      }
    };

    const handleClickOutsideForProfile = (event) => {
      if (
        sellerNameDivVisibleRef.current &&
        profileDropdownRef.current &&
        !sellerNameDivVisibleRef.current.contains(event.target) &&
        !profileDropdownRef.current.contains(event.target)
      ) {
        setProfileDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    document.addEventListener("click", handleClickOutsideForWeb);
    document.addEventListener("click", handleClickOutsideForProfile);
    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("click", handleClickOutsideForWeb);
      document.removeEventListener("click", handleClickOutsideForProfile);
    };
  }, [
    dropdownRef,
    divVisiblityRef,
    sellerNameDivVisibleRef,
    profileDropdownRef,
  ]);
  const closeDropdown = () => {
    setIsOpen(false);
  };

  const handleLogout = async () => {
    const toastId = showToast("info", "Logging out...", { autoClose: false });

    const token = getDataFromCookies("R3p7uLw9Xk");
    if (!token) {
      showToast("info", Alert.alertForSomethingWrong);
      navigate(`${navigation.home}`);
      window.location.reload();
    }
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellersLogoutApi}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const res = await response.json();

    if (res.status === 200 || res.status === 401) {
      removeDataFromCookie("R3p7uLw9Xk");
      removeDataFromCookie("isLoggedIn");
      removeDataFromCookie("fullName");
      removeDataFromCookie("firstChar");
      removeDataFromCookie("email");

      toast.dismiss(toastId);
      setTimeout(() => {
        showToast("success", Alert.alertForLogout);
      }, 500);
    } else {
      showToast("info", "Something went wrong");
    }
  };

  const location = useLocation();
  const isStartWith = (path) => {
    return location.pathname.startsWith(path) ? "active" : "";
  };
  const isMatched = (path) => {
    return location.pathname === path ? "active" : "";
  };

  const [fullName, setFullName] = useState("Seller Identity");
  const [firstName, setFirstName] = useState("First Name");
  const [firstChar, setFirstChar] = useState("");

  // fetch profile details
  const fetchProfileData = async () => {
    const url = `${API_HOST}/${ApiEndpoint.SellersProfileApi}`;
    const token = getDataFromCookies("R3p7uLw9Xk");
    if (!token) {
      navigate(`${navigation.home}`);
    }
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await response.json();
    if (res.status === 200) {
      const profileData = res.data;

      if (profileData.status == 1) {
        contextObj.setInput("isSellerVerified", "true");
      }
      if (profileData.is_owner == 1) {
        contextObj.setInput("isBusinessOwner", true);
      }

      // handle names
      const fullName = profileData.f_name + " " + profileData.l_name;
      const firstName = profileData.f_name;

      setFullName(fullName);
      setFirstName(firstName);

      // handle firstChar
      let firstChar = "";
      if (profileData.f_name) {
        firstChar = firstChar + profileData.f_name.charAt(0).toUpperCase();
      }
      setFirstChar(firstChar);

      // handle email
      let email = "";
      if (profileData.email) {
        email = profileData.email;
      }
      if (profileData.pincode) {
        setDataToCookies("pincode", profileData.pincode, "/");
      }

      setDataToCookies("email", email, "/");
      setDataToCookies("fullName", fullName, "/");
      setDataToCookies("firstChar", firstChar, "/");

      contextObj.setInput("email", email);
      contextObj.setInput("fullName", fullName);
      contextObj.setInput("firstChar", firstChar);
    }
  };
  useEffect(() => {
    fetchProfileData();
  }, []);

  //Seller Profile Status
  useEffect(() => {
    if (contextObj.inputValue.isSellerVerified == "true") {
      setSellerStatus(false);
    } else {
      setSellerStatus(true);
    }
  }, [contextObj.inputValue.isSellerVerified]);

  // Handle seller profile info
  useEffect(() => {
    setFirstName(contextObj.inputValue.firstName);
  }, [contextObj.inputValue.firstName]);
  useEffect(() => {
    setFirstChar(contextObj.inputValue.firstChar);
  }, [contextObj.inputValue.firstChar]);

  return (
    <>
      {featureFlags.canShowSellerNavigationBar && (
        <>
          <div className="top-navbar theme">
            {/* Mobile view Hamburger */}
            <button
              className={`navbar__hamburger ${isMobileMenuOpen ? "open" : ""}`}
              onClick={handleHamburgerClick}
            >
              <FontAwesomeIcon icon={faBars} />
            </button>

            {/* App logo */}
            <div
              className="brand-name"
              onClick={() => {
                navigate("/");
              }}
            >
              <img
                src={logo_p1}
                alt="logo"
                style={{
                  width: "40px",
                }}
              />
              <img
                style={{
                  width: "70px",
                }}
                src={logo_p2}
                alt="logo"
              />
            </div>

            {/* Web View Menu list */}
            <ul className="menu-list">
              {featureFlags.canShowDashboard && (
                <li
                  eventKey="dashboard"
                  id="sellers-dashboardca-forWeb"
                  className={`menu-item ${isStartWith("/dashboard")}`}
                  onClick={() => {
                    setOnClickEventKey("dashboard");
                    navigate("/dashboard");
                  }}
                >
                  Dashboard
                </li>
              )}

              {featureFlags.canShowProducts && (
                <li
                  id="sellers-ProductButton-forWeb"
                  className={`menu-item ${isStartWith("/products")}`}
                  ref={divVisiblityRef}
                  onMouseEnter={openProductDropdown}
                  onMouseLeave={closeProductDropdown}
                  onClick={() => {
                    navigate("/products");
                  }}
                >
                  Products
                  <FontAwesomeIcon
                    className="menu-dropdown-icon"
                    icon={isProductDropdownOpen ? faAngleUp : faAngleDown}
                  />
                  {isProductDropdownOpen && (
                    <ol className="menu-dropdown" ref={dropdownRef}>
                      <li
                        id="sellers-ProductListButton-forWeb"
                        onClick={() => {
                          closeProductDropdown();
                          navigate("/products");
                        }}
                        className={`${isMatched("/products")}`}
                      >
                        Product List
                      </li>
                      {sellerStatus == false ? (
                        <li
                          classList="Products-ListButton-AddNewProduct"
                          id="New-Products-Add-Button"
                          onClick={(e) => {
                            closeProductDropdown();
                            e.stopPropagation();
                            navigate("/products/new");
                          }}
                          className={`${isMatched("/products/new")}`}
                        >
                          Add New Product
                        </li>
                      ) : null}
                    </ol>
                  )}
                </li>
              )}

              {featureFlags.canShowOrders && (
                <li
                  id="sellers-ordersButton-forWeb"
                  eventKey="orders"
                  onClick={() => {
                    setOnClickEventKey("orders");
                    navigate("/orders");
                  }}
                  className={`menu-item ${isStartWith(
                    window.location.pathname.match("/orders") ||
                      window.location.pathname.match(/^\/order-details\/\d+$/)
                  )}`}
                >
                  Orders
                </li>
              )}

              {featureFlags.canShowPayments && (
                <li
                  id="sellers-paymentsButton-forWeb"
                  onClick={() => {
                    setOnClickEventKey("payments");
                    navigate("/payments");
                  }}
                  eventKey="payments"
                  className={`menu-item ${isStartWith("/payments")}`}
                >
                  Payments
                </li>
              )}

              {featureFlags.canShowDonation && (
                <li
                  id="sellers-donationButton-forWeb"
                  className={`menu-item ${isStartWith(
                    window.location.pathname.match("/donations") ||
                      window.location.pathname.match("/ngos") ||
                      window.location.pathname.match(/^\/ngo-donate\/\d+$/)
                  )}`}
                  ref={divVisiblityRef}
                  onMouseEnter={openDonationDropdown}
                  onMouseLeave={closeDonationDropdown}
                  onClick={() => {
                    navigate("/donations");
                  }}
                >
                  Donation
                  <FontAwesomeIcon
                    className="menu-dropdown-icon"
                    icon={isDonationDropdownOpen ? faAngleUp : faAngleDown}
                  />
                  {isDonationDropdownOpen && (
                    <ol className="menu-dropdown" ref={dropdownRef}>
                      <li
                        id="sellers-DonationListButton-forWeb"
                        onClick={() => {
                          closeDonationDropdown();
                          navigate("/donations");
                        }}
                        className={`${isMatched("/donations")}`}
                      >
                        Donation List
                      </li>
                      {sellerStatus == false ? (
                        <li
                          onClick={(e) => {
                            closeDonationDropdown();
                            e.stopPropagation();
                            navigate(navigation.ngos);
                          }}
                          className={`${isMatched("/ngos")}`}
                        >
                          Add New Donation
                        </li>
                      ) : null}
                    </ol>
                  )}
                </li>
              )}

              <li
                id="shop_On_Navigation_Button"
                className="menu-item"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  window.open(BUYER_URL, "_blank");
                }}
              >
                Shop on {APP_NAME}
              </li>
            </ul>

            {/* Mobile Screen Profile Icon */}
            <div
              className="user-info"
              onClick={() => {
                navigate(`${navigation.profile}`);
              }}
            >
              <span className="first-char-seller">{firstChar}</span>
            </div>

            {/* Profile Menu */}
            <div className="seller-navprofile-area">
              <div
                id="sellerNameMainDiv"
                className={`seller-name-placeholder menu-item ${isStartWith(
                  "/profile"
                )}`}
                ref={sellerNameDivVisibleRef}
                onMouseEnter={openProfileDropdown}
                onMouseLeave={closeProfileDropdown}
                onClick={() => {
                  navigate("/profile");
                }}
              >
                <p className="username-navmenu">{firstName}</p>
                {profileDropdown && (
                  <ol className="navbar__dropdown" ref={profileDropdownRef}>
                    {featureFlags.canShowProfile && (
                      <li
                        id="Navbar_Manage_Profile_Button"
                        onClick={() => {
                          navigate("/profile");
                        }}
                      >
                        Manage Profile
                      </li>
                    )}
                    <li
                      id="Navbar_logout_button"
                      onClick={async (e) => {
                        e.stopPropagation();
                        await handleLogout();
                        setTimeout(() => {
                          navigate(`${navigation.home}`);
                        }, 1000);
                      }}
                    >
                      Logout
                    </li>
                  </ol>
                )}
              </div>
            </div>
          </div>

          {/* Mobile Screen Sidebar */}
          <div className={`${isMobileMenuOpen ? "open" : ""}`}>
            {isMobileMenuOpen && (
              <div className="sidebar-container">
                <div ref={sidebarRef} className="sidebar">
                  <Nav
                    onSelect={(selected) => {
                      navigate(`${navigation.home}` + selected);
                    }}
                  >
                    <div className="mobile-greetings">
                      <p className="hi-seller">
                        Hello,
                        <span>{fullName}</span>
                      </p>
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={handleCloseClick}
                        className="sidebar-close-icon"
                      />
                    </div>

                    {featureFlags.canShowDashboard && (
                      <NavItem
                        eventKey="dashboard"
                        onClick={() => {
                          setOnClickEventKey("dashboard");
                          setMobileMenuOpen(false);
                        }}
                      >
                        <li
                          id="sellers-dashboard-forMobile"
                          className={`mobile-menu-item ${isStartWith(
                            "/dashboard"
                          )}`}
                        >
                          <FontAwesomeIcon
                            icon={faHouse}
                            className="mobile-menu-icon"
                          />
                          Dashboard
                        </li>
                      </NavItem>
                    )}
                    {featureFlags.canShowProducts && (
                      <>
                        <NavItem
                          eventKey="products"
                          onClick={(e) => {
                            setOnClickEventKey("products");
                            e.preventDefault();
                            setMobileProductDropdownOpen(
                              !isMobileProductDropdownOpen
                            );
                          }}
                        >
                          <li
                            id="sellers-ProductButton-forMobile"
                            className={`mobile-menu-item ${isStartWith(
                              "/products"
                            )}`}
                          >
                            <FontAwesomeIcon
                              icon={faList}
                              className="mobile-menu-icon"
                            />
                            Products
                            <FontAwesomeIcon
                              className="mobile-menu-dropdown-icon"
                              icon={
                                isMobileProductDropdownOpen
                                  ? faAngleUp
                                  : faAngleDown
                              }
                            />
                          </li>
                        </NavItem>

                        {isMobileProductDropdownOpen && (
                          <>
                            <NavItem>
                              <li
                                id="sellers-ProductListButton-forMobile"
                                onClick={(e) => {
                                  navigate("/products");
                                  setMobileMenuOpen(false);
                                  setMobileProductDropdownOpen(false);
                                }}
                                className={`mobile-menu-item child-item ${isMatched(
                                  "/products"
                                )}`}
                              >
                                <svg
                                  style={{ marginRight: "10px" }}
                                  width="15"
                                  height="14"
                                  viewBox="0 0 15 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10.25 13L14 9.25L10.25 5.5"
                                    stroke="#A5A5A5"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M14 8.97481H8.23546C-4.76837e-07 9.5 1.02978 5.72891 1.02978 1.72496V0.999977"
                                    stroke="#A5A5A5"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                Product List
                              </li>
                            </NavItem>

                            {sellerStatus === false ? (
                              <NavItem>
                                <li
                                  id="sellers-AddNewProductButton-forMobile"
                                  className={`mobile-menu-item child-item ${isMatched(
                                    "/products/new"
                                  )}`}
                                  onClick={(e) => {
                                    navigate("/products/new");
                                    setMobileMenuOpen(false);
                                    setMobileProductDropdownOpen(false);
                                  }}
                                >
                                  <svg
                                    style={{ marginRight: "10px" }}
                                    width="15"
                                    height="14"
                                    viewBox="0 0 15 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M10.25 13L14 9.25L10.25 5.5"
                                      stroke="#A5A5A5"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M14 8.97481H8.23546C-4.76837e-07 9.5 1.02978 5.72891 1.02978 1.72496V0.999977"
                                      stroke="#A5A5A5"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  Add New Product
                                </li>
                              </NavItem>
                            ) : null}
                          </>
                        )}
                      </>
                    )}

                    {featureFlags.canShowOrders && (
                      <NavItem
                        eventKey="orders"
                        onClick={() => {
                          setOnClickEventKey("orders");
                          setMobileMenuOpen(false);
                        }}
                      >
                        <li
                          id="sellers-ordersButton-forMobile"
                          className={`mobile-menu-item ${isStartWith(
                            "/orders"
                          )}`}
                        >
                          <FontAwesomeIcon
                            icon={faCartShopping}
                            className="mobile-menu-icon"
                          />
                          Orders
                        </li>
                      </NavItem>
                    )}

                    {featureFlags.canShowPayments && (
                      <NavItem
                        eventKey="payments"
                        onClick={() => {
                          setOnClickEventKey("payments");
                          setMobileMenuOpen(false);
                        }}
                      >
                        <li
                          id="sellers-paymentsButton-forMobile"
                          className={`mobile-menu-item ${isStartWith(
                            "/payments"
                          )}`}
                        >
                          <FontAwesomeIcon
                            icon={faCreditCardAlt}
                            className="mobile-menu-icon"
                          />
                          Payments
                        </li>
                      </NavItem>
                    )}

                    {featureFlags.canShowDonation && (
                      <>
                        <NavItem
                          eventKey="donations"
                          onClick={(e) => {
                            setOnClickEventKey("donations");
                            e.preventDefault();
                            setMobileDonationDropdownOpen(
                              !isMobileDonationDropdownOpen
                            );
                          }}
                        >
                          <li
                            id="sellers-DonationButton-forMobile"
                            className={`mobile-menu-item ${isStartWith(
                              window.location.pathname.match("/donations") ||
                                window.location.pathname.match("/ngos") ||
                                window.location.pathname.match(
                                  /^\/ngo-donate\/\d+$/
                                )
                            )}`}
                          >
                            <FontAwesomeIcon
                              icon={faList}
                              className="mobile-menu-icon"
                            />
                            Donation
                            <FontAwesomeIcon
                              className="mobile-menu-dropdown-icon"
                              icon={
                                isMobileDonationDropdownOpen
                                  ? faAngleUp
                                  : faAngleDown
                              }
                            />
                          </li>
                        </NavItem>

                        {isMobileDonationDropdownOpen && (
                          <>
                            <NavItem>
                              <li
                                id="sellers-DonationListButton-forMobile"
                                onClick={(e) => {
                                  navigate("/donations");
                                  setMobileMenuOpen(false);
                                  setMobileDonationDropdownOpen(false);
                                }}
                                className={`mobile-menu-item child-item ${isMatched(
                                  "/donations"
                                )}`}
                              >
                                <svg
                                  style={{ marginRight: "10px" }}
                                  width="15"
                                  height="14"
                                  viewBox="0 0 15 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10.25 13L14 9.25L10.25 5.5"
                                    stroke="#A5A5A5"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M14 8.97481H8.23546C-4.76837e-07 9.5 1.02978 5.72891 1.02978 1.72496V0.999977"
                                    stroke="#A5A5A5"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                Donation List
                              </li>
                            </NavItem>

                            {sellerStatus === false ? (
                              <NavItem>
                                <li
                                  id="sellers-AddNewProductButton-forMobile"
                                  className={`mobile-menu-item child-item ${isMatched(
                                    "/ngos"
                                  )}`}
                                  onClick={(e) => {
                                    navigate("/ngos");
                                    setMobileMenuOpen(false);
                                    setMobileDonationDropdownOpen(false);
                                  }}
                                >
                                  <svg
                                    style={{ marginRight: "10px" }}
                                    width="15"
                                    height="14"
                                    viewBox="0 0 15 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M10.25 13L14 9.25L10.25 5.5"
                                      stroke="#A5A5A5"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M14 8.97481H8.23546C-4.76837e-07 9.5 1.02978 5.72891 1.02978 1.72496V0.999977"
                                      stroke="#A5A5A5"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  Add New Donation
                                </li>
                              </NavItem>
                            ) : null}
                          </>
                        )}
                      </>
                    )}

                    <NavItem
                      eventKey={onClickEventKey}
                      id="sellers-linkToNavigateOn-BuyerFlow"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        window.open(BUYER_URL, "_blank", "noreferrer");

                        setMobileMenuOpen(false);
                      }}
                    >
                      <li className="mobile-menu-item">
                        <FontAwesomeIcon
                          icon={faGlobe}
                          className="mobile-menu-icon"
                        />
                        Shop on {APP_NAME}
                        <FontAwesomeIcon
                          className="mobile-menu-link-icon"
                          icon={faAngleUp}
                        />
                      </li>
                    </NavItem>

                    <NavItem
                      className="mobile-sidebar-profile"
                      eventKey="profile"
                      onClick={() => {
                        setOnClickEventKey("profile");
                        setMobileMenuOpen(false);
                      }}
                    >
                      <li
                        className={`mobile-menu-item ${isStartWith(
                          "/profile"
                        )}`}
                      >
                        <FontAwesomeIcon
                          icon={faUser}
                          className="mobile-menu-icon"
                        />
                        Profile
                      </li>
                    </NavItem>

                    <div
                      onClick={async (e) => {
                        e.stopPropagation();
                        await handleLogout();
                        setTimeout(() => {
                          navigate(`${navigation.home}`);
                        }, 1000);
                      }}
                    >
                      <li className="mobile-menu-item mobile-sidebar-logout">
                        <FontAwesomeIcon
                          icon={faRightFromBracket}
                          className="mobile-menu-icon"
                        />
                        Logout
                      </li>
                    </div>
                  </Nav>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
export default AppNavbar;
