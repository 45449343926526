import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import navigation from "../../../Configs/Navigation.json";
import ApiEndpoint from "../../../Configs/APIEndpoints.json";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Component/Cookies/Cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import logo_p1 from "../../../Assets/logo_p1.png";
import logo_p2 from "../../../Assets/logo_p2.png";
import Login from "../../Auth/Login/Login";
import ForgotPassword from "../../Auth/ForgotPassword/ForgotPassword";
import InputContext from "../../Auth/InputContext";
import "./header.css";
import Toast from "../../../Component/Toastify/Toast";
import Alert from "../../../Configs/Alerts.json";
import publicRoutesData from "../../../Configs/publicRoutes.json";
const BUYER_URL = process.env.REACT_APP_BUYER_DOMAIN;
const API_HOST = process.env.REACT_APP_HOST_API_URL;

const Header = () => {
  const { showToast } = Toast();
  const location = useLocation();
  const navRef = useRef(null);
  const navigate = useNavigate();
  const contextObj = useContext(InputContext);
  const token = getDataFromCookies("R3p7uLw9Xk");
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 900) {
        // setNavOpen(false);
        document.querySelector(".nav-list").style.display = "block";
      } else {
        closeToggleNav();
      }
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [hasToken, setHasToken] = useState(false);
  const [isNavOpen, setNavOpen] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showForgotPassModal, setShowForgotPassModal] = useState(false);

  // handle Login or Forgot Password Modal
  useEffect(() => {
    if (showLoginModal || showForgotPassModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showLoginModal, showForgotPassModal]);

  // handle Mobile Hamburger
  const openToggleNav = () => {
    setNavOpen(true);
    document.querySelector(".nav-list").style.display = "block";
    setHasToken(getDataFromCookies("R3p7uLw9Xk") ? true : false);
  };
  const closeToggleNav = () => {
    setNavOpen(false);
    document.querySelector(".nav-list").style.display = "none";
    setHasToken(getDataFromCookies("R3p7uLw9Xk") ? true : false);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isNavOpen &&
        navRef.current &&
        !navRef.current.contains(event.target)
      ) {
        closeToggleNav();
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isNavOpen]);

  // get seller profile status
  const profileStatus = async () => {
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellersProfileStatusApi}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    const res = await response.json();
    if (res.status === 200) {
      contextObj.setInput("isSellerVerified", "true");
      setHasToken(true);
    } else if (res.status === 404) {
      contextObj.setInput("isSellerVerified", "false");
      setHasToken(true);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      let path = location.pathname;
      if (path !== "/") {
        contextObj.setInput("prevPath", path);
      }
      removeDataFromCookie("R3p7uLw9Xk", "/");
      removeDataFromCookie("isLoggedIn", "/");
      removeDataFromCookie("fullName");
      removeDataFromCookie("firstChar");
      removeDataFromCookie("email");
      setHasToken(false);
      contextObj.setInput("isSignupNavigation", true);
      navigate(`${navigation.home}`);
      if (location.pathname !== "/") {
        showToast("info", Alert.alertForLoginExpired);
      }
    }
  };
  useEffect(() => {
    let path = location.pathname;

    console.log("publicRoute ========>", publicRoutesData.publicRoutes);
    console.log("path =====>", path);
    console.log(
      "publicRoutesData.publicRoutes.includes(path) ========>",
      publicRoutesData.publicRoutes.includes(path)
    );

    if (!publicRoutesData.publicRoutes.includes(path)) {
      profileStatus();
    }
  }, []);

  // Set Active Token
  useEffect(() => {
    setHasToken(getDataFromCookies("R3p7uLw9Xk"));
  }, []);

  // Handle Login Signup Dashboard Redirection
  const openSignupPage = () => {
    navigate(navigation.signup);
  };
  const openLoginPage = () => {
    setShowLoginModal(true);
  };
  const openDashboard = () => {
    navigate(navigation.dashbord);
  };
  useEffect(() => {
    if (contextObj.inputValue.isSignupNavigation === true) {
      setShowLoginModal(true);
      contextObj.setInput("isSignupNavigation", false);
    }
  }, [
    contextObj.inputValue.isSignupNavigation,
    contextObj.inputValue.showAuthModal,
  ]);

  return (
    <section className="navigation">
      <div className="nav-container">
        <div
          className="brand-name"
          onClick={() => {
            navigate("/");
            window.scrollTo(0, 0);
          }}
        >
          <img
            style={{
              width: "40px",
            }}
            src={logo_p1}
            alt="logo"
          />
          <img
            style={{
              width: "70px",
            }}
            src={logo_p2}
            alt="logo"
          />
        </div>

        <menu ref={navRef}>
          <div className="nav-mobile">
            <button
              id="header_toggle_button"
              className={isNavOpen ? "active" : ""}
              onClick={() => {
                isNavOpen ? closeToggleNav() : openToggleNav();
              }}
            >
              <FontAwesomeIcon
                icon={isNavOpen ? faTimes : faBars}
                style={{ color: "#ffffff", height: "24px" }}
              />
            </button>
          </div>
          <ul className="nav-list">
            <li>
              <a
                id="seller-howItWorks-Text"
                href="#how-it-works"
                onClick={() => {
                  navigate("/");
                  if (isNavOpen) closeToggleNav();
                }}
              >
                How it works
              </a>
            </li>
            <li>
              <a
                id="seller-pricing-Text"
                href="#pricing"
                onClick={() => {
                  navigate("/");
                  if (isNavOpen) closeToggleNav();
                }}
              >
                Pricing
              </a>
            </li>
            <li>
              <a
                id="seller-FaqText"
                href="#faq-section"
                onClick={() => {
                  navigate("/");
                  if (isNavOpen) closeToggleNav();
                }}
              >
                FAQ's
              </a>
            </li>
            <li>
              <a
                id="seller-helpAndSupportText"
                href="#help-and-support"
                onClick={() => {
                  navigate("/");
                  if (isNavOpen) closeToggleNav();
                }}
              >
                Help & Support
              </a>
            </li>
            <li style={{ cursor: "pointer" }}>
              <a
                id="seller-shopOnJoyRejoyText"
                onClick={() => {
                  window.open(BUYER_URL, "_blank");
                  if (isNavOpen) closeToggleNav();
                }}
              >
                Shop on JOYREJOY
              </a>
            </li>

            <li>
              <div className="action-btn-menu">
                <div className="action-btn-line">
                  {hasToken ? (
                    <button
                      id="header-mobile-dashboard-btn"
                      className="btn signup-button"
                      onClick={() => {
                        openDashboard();
                        if (isNavOpen) closeToggleNav();
                      }}
                    >
                      Dashboard
                    </button>
                  ) : (
                    <>
                      <button
                        id="header-mobile-login-btn"
                        className="btn login-button"
                        onClick={() => {
                          openLoginPage();
                          if (isNavOpen) closeToggleNav();
                        }}
                      >
                        Login
                      </button>
                      <button
                        id="header-mobile-signup-btn"
                        className="btn signup-button"
                        onClick={() => {
                          openSignupPage();
                          if (isNavOpen) closeToggleNav();
                        }}
                      >
                        Signup
                      </button>
                    </>
                  )}
                </div>
              </div>
            </li>
          </ul>
        </menu>
        <div className="action-btn-area">
          {hasToken ? (
            <button
              id="header-web-dashboard-btn"
              className="btn signup-button"
              onClick={() => {
                openDashboard();
                if (isNavOpen) closeToggleNav();
              }}
            >
              Dashboard
            </button>
          ) : (
            <>
              <button
                type="button"
                id="header-web-login-btn"
                className="login-button"
                onClick={() => {
                  openLoginPage();
                  if (isNavOpen) closeToggleNav();
                }}
              >
                Login
              </button>
              <button
                type="button"
                id="header-web-signup-btn"
                className="signup-button"
                onClick={() => {
                  openSignupPage();
                  if (isNavOpen) closeToggleNav();
                }}
              >
                Signup
              </button>
            </>
          )}
        </div>
      </div>
      {showLoginModal && (
        <Login
          onClose={setShowLoginModal}
          showForgotPassModal={setShowForgotPassModal}
        />
      )}
      {showForgotPassModal && (
        <ForgotPassword
          onClose={setShowForgotPassModal}
          showLoginModal={setShowLoginModal}
        />
      )}
    </section>
  );
};

export default Header;
