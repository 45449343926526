import React, { useEffect, useState, useMemo, useContext } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faXmark,
  faEdit,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import InputContext from "../Auth/InputContext";
import navigation from "../../Configs/Navigation.json";
import ApiEndpoint from "../../Configs/APIEndpoints.json";
import Alert from "../../Configs/Alerts.json";
import loadingImgPath from "../../Assets/rolling.svg";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../Component/Cookies/Cookie";
import Toast from "../../Component/Toastify/Toast";
import imagePlaceHolder from "../../Assets/image-placeholder.png";
import "./product.css";
import InfiniteScroll from "react-infinite-scroll-component";
import Paginate from "../../Component/Pagination/Paginate";
const API_HOST = process.env.REACT_APP_HOST_API_URL;
const APP_INFRA = process.env.REACT_APP_INFRA.toLowerCase();
const S3IMG_HOST = process.env.REACT_APP_S3BUCKET_IMG_HOST;
const IMG_HOST =
  APP_INFRA == "staging" || APP_INFRA == "production" ? S3IMG_HOST : API_HOST;

const ProductList = () => {
  const location = useLocation();
  let [searchQuery, setSearchQuery] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  let [isInMobileView, setIsInMobileView] = useState(window.innerWidth <= 600);
  const productsPerAPIResponse = 15;
  const [lastPage, setLastPage] = useState(null);
  let [data, setData] = useState([]);
  const [deleteItem, setDeleteItem] = useState(null);
  const [showDeleteSuccessModal, setShowDeleteSuccessModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [sellerStatus, setSellerStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  let [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [scroll, setScroll] = useState(true);
  let [totalProducts, setTotalProducts] = useState(0);
  let [currentPageIndex, setCurrentPageIndex] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState("");
  const { showToast } = Toast();
  const navigate = new useNavigate();
  const contextObj = useContext(InputContext);
  const [featureFlags, setFeatureFlags] = useState({
    canAddProducts: false,
    canEditProducts: false,
    canDeleteProducts: false,
  });

  useEffect(() => {
    if (showDeleteModal && deleteItem) {
      document.body.style.overflow = "hidden";
      document.body.style.WebkitOverflowScrolling = "hidden";
    } else {
      document.body.style.overflow = "auto";
      document.body.style.WebkitOverflowScrolling = "scroll";
    }
  }, [showDeleteModal, deleteItem]);

  const handleURLChange = (data) => {
    window.scrollTo(0, 0);
    if (searchQuery !== "") {
      data = data + "&search=" + searchQuery;
    }

    fetchData(data);
  };

  useEffect(() => {
    const checkIsInMobileView = async () => {
      setIsInMobileView(window.innerWidth <= 600);
    };

    checkIsInMobileView();

    window.addEventListener("resize", checkIsInMobileView);

    return () => {
      window.removeEventListener("resize", checkIsInMobileView);
    };
  }, []);

  const fetchData = async (url) => {
    if (!url) return setLoading(false);

    const token = getDataFromCookies("R3p7uLw9Xk");
    const response = await fetch(`${url}&per_page=${productsPerAPIResponse}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const res = await response.json();

    if (res.status === 200) {
      setScroll(res.data.current_page < res.data.last_page);
      setTotalProducts(res.data.total || 0);
      setPagination(res.data);
      setLastPage(res.data.last_page);
      setCurrentPage(res.data.current_page);
      const dataArray = res.data.data;
      if (window.innerWidth <= 600) {
        let updatedData = [...filteredData, ...dataArray];
        setFilteredData(updatedData);
      } else {
        setData(dataArray);
      }
      setSellerStatus(false);
      contextObj.setInput("isSellerVerified", "true");
    } else if (res.status === 401) {
      if (res.message === "You are not authorized.") {
        contextObj.setInput("prevPath", location.pathname);
        removeDataFromCookie("R3p7uLw9Xk", "/");
        removeDataFromCookie("isLoggedIn", "/");
        removeDataFromCookie("fullName");
        removeDataFromCookie("firstChar");
        removeDataFromCookie("email");
        contextObj.setInput("isSignupNavigation", true);
        navigate(`${navigation.home}`);
        showToast("info", Alert.alertForLoginExpired);
      } else if (res.message.includes("not verified")) {
        setSellerStatus(true);
        contextObj.setInput("isSellerVerified", "false");
      }
    } else if (res.status === 404) {
      setData([]);
      setFilteredData([]);
      setPagination("");
      contextObj.setInput("isSellerVerified", "true");
    }

    setLoading(false);
    setSearchLoading(false);
  };

  // handle search api calls
  useEffect(() => {
    setSearchLoading(true);
    const debounceTimeout = setTimeout(() => {
      setLoading(true);
      data = [];
      currentPage = 1;
      filteredData = [];
      let url = `${API_HOST}/${ApiEndpoint.SellersProductsApi}?page_no=${currentPage}`;
      if (searchQuery !== "") {
        url = url + "&search=" + searchQuery;

        fetchData(url);
      } else {
        fetchData(url);
      }
    }, 1000);
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [searchQuery, isInMobileView]);

  // Seller Profile Status
  useEffect(() => {
    if (contextObj.inputValue.isSellerVerified === "true") {
      setSellerStatus(false);
    }
  }, [contextObj.inputValue.isSellerVerified]);

  useEffect(() => {
    const token = getDataFromCookies("R3p7uLw9Xk");
    if (!token) {
      navigate(`${navigation.home}`);
    }

    const featureFlags = contextObj.inputValue.featureFlags;
    if (featureFlags) {
      setFeatureFlags((prev) => ({
        ...prev,
        canAddProducts: featureFlags.seller_products_add,
        canEditProducts: featureFlags.seller_products_edit,
        canDeleteProducts: featureFlags.seller_products_delete,
      }));
    }
  }, [contextObj.inputValue.featureFlags]);

  useEffect(() => {
    if (
      contextObj.inputValue.navigation == "true" &&
      contextObj.inputValue.navigationFrom == "UpdateProduct"
    ) {
      contextObj.setInput("navigation", "false");
      contextObj.setInput("navigationFrom", "");
      showToast("success", Alert.alertForProductUpdation);
    }
  }, []);

  // delete product
  const handleConfirmDelete = async (id) => {
    const token = getDataFromCookies("R3p7uLw9Xk");
    if (!token) {
      navigate(`${navigation.home}`);
    }

    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellersProductsApi}/${id}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const res = await response.json();

    if (res.status === 200) {
      setDeleteItem(null);
      setShowDeleteSuccessModal(true);
      showToast("success", Alert.alertForProductDeletion);
      let dataToFilter;
      if (window.innerWidth <= 600) {
        dataToFilter = [...filteredData];
      } else {
        dataToFilter = [...data];
      }
      const updatedData = dataToFilter.filter((product) => product.id !== id);
      setData(updatedData);
      setFilteredData(updatedData);
      if (totalProducts > 0) {
        console.log("totalProducts====>", totalProducts);
        setTotalProducts(totalProducts - 1);
        setPagination((prev) => ({
          ...prev,
          to: pagination.to - 1,
          total: pagination.total - 1,
        }));
      }
      if (totalProducts == 1) {
        setPagination("");
      }
    } else if (res.status === 404) {
      showToast("error", res.message);
      setData([]);
      setFilteredData([]);
      setPagination("");
    } else {
      showToast("info", "Something went wrong");
    }
    setLoading(false);
  };

  const handleCancelDelete = () => {
    setDeleteItem(null);
  };

  const handleDeleteClickpopup = (product) => {
    setDeleteItem(product);
    setShowDeleteModal(true);
  };

  const handleEdit = (slug) => {
    navigate(`${navigation.products}/${slug}`);
    return;
  };

  const columns = useMemo(
    () => {
      const columnsArray = [
        {
          Header: "S No.",
          filterable: true,
          accessor: (row, index) =>
            index + 1 + (currentPage - 1) * productsPerAPIResponse,
        },
        {
          Header: "",
          Footer: "",
          accessor: "thumb_image",
          filterable: false,
          Cell: ({ value }) =>
            value ? (
              <img
                id="sellers-productImage"
                src={`${IMG_HOST}/${value}`}
                onError={(e) => {
                  e.target.src = imagePlaceHolder;
                }}
              />
            ) : (
              <img id="sellers-productImage" src={imagePlaceHolder} />
            ),
        },
        {
          Header: "Product",
          Footer: "Product",
          accessor: "name",
          Cell: ({ value, row }) => (
            <div
              id={`sellers-productName-headingDiv_${row.index}`}
              title={value}
              style={{
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
                maxWidth: "400px",
              }}
            >
              {value}
            </div>
          ),
        },
        {
          Header: "Price",
          Footer: "Price",
          accessor: "product_price",
          filterable: true,
          Cell: ({ value }) => (
            <div className="cell-container">
              {value && `₹${value.toLocaleString()}`}
            </div>
          ),
        },
        {
          Header: "Status",
          Footer: "Status",
          Cell: ({ row }) => {
            const denied_note = row.original.denied_note;
            const value = row.original.status;
            let backgroundColor;
            let color;
            if (value === "Unverified") {
              backgroundColor = "#F8D294";
            } else if (value === "Verified") {
              backgroundColor = "lightGreen";
            } else if (value === "Verified and Published") {
              backgroundColor = "#CDFEE1";
            } else if (value === "Rejected") {
              backgroundColor = "#FFC9C9";
            } else if (value === "Hold") {
              backgroundColor = "#FFB131";
            } else if (value === "Sold") {
              backgroundColor = "#CCCCCC";
            } else if (value === "Donated") {
              backgroundColor = "#E2CCFF";
            } else if (value === "Draft") {
              backgroundColor = "#E0F0FF";
            } else if (value === "Unavailable") {
              backgroundColor = "#c9c9c9";
            }
            return (
              <div className="product-status-cell">
                <p
                  id="sellers-productStatus"
                  title="Status"
                  className="product-status"
                  style={{ backgroundColor, color, whiteSpace: "nowrap" }}
                >
                  {value}
                </p>
                <p className="product-denied-note-line">{denied_note}</p>
              </div>
            );
          },
        },
      ];
      if (featureFlags.canEditProducts || featureFlags.canDeleteProducts) {
        columnsArray.push({
          Header: "Action",
          Footer: "Action",

          Cell: ({ row }) => {
            const name = row.original.name;
            const id = row.original.id;
            const slug = row.original.slug;
            const status = row.original.status;
            const isDeleting = deleteItem === id;
            const notDeletable = status === "Hold";
            const notEditable =
              status === "Hold" ||
              status === "Sold" ||
              status === "Donated" ||
              status === "Unavailable" ||
              status === "Blocked";

            return isDeleting ? (
              <div>
                {showDeleteModal && (
                  <div
                    className="modal fade"
                    style={{
                      display: showDeleteModal ? "block" : "none",
                    }}
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <p>Delete Product</p>
                        <button
                          className="modal-close-icon"
                          onClick={handleCancelDelete}
                        >
                          <FontAwesomeIcon icon={faXmark} />
                        </button>
                      </div>
                      <div className="modal-body">
                        <p>
                          Do you want to delete{" "}
                          <span className="bold">{name}</span> item?
                        </p>
                      </div>
                      <div className="modal-buttons">
                        <button
                          id="sellers-cancelDelete-Button"
                          className="btn secondary-btn"
                          onClick={handleCancelDelete}
                        >
                          Cancel
                        </button>
                        <button
                          id="sellers-deleteConfirmation-Button"
                          className="btn primary-btn"
                          onClick={() => handleConfirmDelete(id)}
                        >
                          Yes, delete it
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div
                style={{
                  width: "65px",
                }}
              >
                {featureFlags.canEditProducts && (
                  <button
                    id="sellers-editProductButton"
                    onClick={() => handleEdit(slug)}
                    className={` ${
                      notEditable
                        ? "edit-button-disabled"
                        : "action-icon edit-button"
                    }`}
                    disabled={notEditable}
                    title={notEditable ? "Not allowed" : "Edit"}
                    style={{
                      opacity: notEditable ? 0.5 : 1,
                      cursor: notEditable ? "not-allowed" : "pointer",
                    }}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                )}
                {featureFlags.canDeleteProducts && (
                  <button
                    id="seller-deleteModelPopup-OpenButton"
                    onClick={() => handleDeleteClickpopup(id)}
                    className={` ${
                      notDeletable
                        ? "trash-button-disabled"
                        : "action-icon trash-button"
                    }`}
                    title={notDeletable ? "Not allowed" : "Delete"}
                    style={{
                      opacity: notDeletable ? 0.5 : 1,
                      cursor: notDeletable ? "not-allowed" : "pointer",
                    }}
                    disabled={notDeletable}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                )}
              </div>
            );
          },
        });
      }
      return columnsArray;
    },

    [deleteItem, showDeleteSuccessModal, featureFlags, currentPage] // Add deleteItem as a dependency
  );

  let {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
    gotoPage,
    totalPageCount,
    canFirstPage,
    canPreviousPage,
    canNextPage,
    canLastPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: currentPageIndex,
        pageSize: productsPerAPIResponse,
        globalFilter: "",
      },
      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
      autoResetFilters: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter, pageIndex, pageSize } = state;

  const StatusCell = ({ value }) => {
    let backgroundColor;
    let color;

    if (value === "Unverified") {
      backgroundColor = "#F8D294";
    } else if (value === "Verified") {
      backgroundColor = "lightGreen";
    } else if (value === "Verified and Published") {
      backgroundColor = "#CDFEE1";
    } else if (value === "Rejected") {
      backgroundColor = "#FFC9C9";
    } else if (value === "Hold") {
      backgroundColor = "#FFB131";
    } else if (value === "Sold") {
      backgroundColor = "#CCCCCC";
    } else if (value === "Donated") {
      backgroundColor = "#E2CCFF";
    } else if (value === "Draft") {
      backgroundColor = "#E0F0FF";
    } else if (value === "Unavailable") {
      backgroundColor = "#EDEDED";
    }

    return (
      <p
        id="sellers-statusOfProduct-forMobile"
        title="Status"
        className="product-status"
        style={{ backgroundColor, color, whiteSpace: "nowrap" }}
      >
        {value}
      </p>
    );
  };

  if (!data) {
    return null;
  }

  const formatPrice = (number) => {
    if (number === null || number === undefined) {
      return "";
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div className="full-container">
      <div className="container">
        {sellerStatus && (
          <div className="seller-verification-line">
            <p>
              Oops! Looks like your profile is not yet verified. You might not
              be able to perform any operation related to products until your
              profile is verified. Please make sure all the required information
              is filled in{" "}
              <span
                className="complete-your-profile"
                onClick={() => {
                  navigate(`${navigation.profile}`);
                }}
              >
                your profile.
              </span>{" "}
              Please note that it might take sometime for JOYREJOY to verify
              your profile. In case of any query related to your profile status,
              please contact JOYREJOY.{" "}
            </p>
          </div>
        )}
        <div className="container-heading product-list-heading">
          <h2 className="heading-ProductList">Products</h2>
          <div className="filter-addProductDiv">
            {/* search bar */}
            <div className="search-bar">
              <div className="fontAwsomeDiv">
                {searchLoading ? (
                  <img src={loadingImgPath} className="search-loading-img" />
                ) : (
                  <FontAwesomeIcon className="SearchIconDiv" icon={faSearch} />
                )}
              </div>
              <input
                id="seller-searchForProducts"
                placeholder="Search..."
                className="search-input"
                name="search-query"
                value={searchQuery}
                onChange={(e) => {
                  e.preventDefault();
                  const val = e.target.value;
                  setSearchQuery(val);
                }}
              />
            </div>

            {sellerStatus && featureFlags.canAddProducts ? (
              <button
                id="sellers-addNewProduct-Button"
                type="button"
                className="addProductBtn"
                disabled={true}
                style={{
                  cursor: "not-allowed",
                  opacity: "0.7",
                }}
              >
                Add product
              </button>
            ) : sellerStatus === false && featureFlags.canAddProducts ? (
              <button
                type="button"
                id="sellers-addNewProduct-Button"
                className="addProductBtn"
                onClick={() => {
                  navigate(navigation.newProduct);
                }}
              >
                Add product
              </button>
            ) : null}
          </div>
        </div>
        <div className="InnerTableContainer">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  className="TableHeading"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column, index) => (
                    <th>
                      <div
                        id={`sellersTableHeader_${index}`}
                        className="header-cell"
                        style={{
                          display: "flex",
                        }}
                      >
                        <span>{column.render("Header")}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              {...getTableBodyProps()}
              style={{ backgroundColor: "#FFFFFF" }}
            >
              {loading ? (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="loadingIconDivForProductPage">
                      <img
                        className="PageLoaingImageMobileForProduct"
                        src={loadingImgPath}
                      />
                    </div>
                  </td>
                </tr>
              ) : page.length > 0 ? (
                page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <tr
                      id={`sellers-ordertable_${index}`}
                      className="TableBodyData"
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell) => (
                        <td
                          id={`sellers-ordertableColumn_${index}`}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">No data</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="pagination-container">
          {pagination && (
            <Paginate
              pagination={pagination}
              pageChange={handleURLChange}
              pageName={"Seller Products"}
            />
          )}
        </div>

        {/* for mobile view product list */}
        <div className="productContainerForMobileView">
          {isInMobileView && (
            <div className="mobile-product-container">
              <InfiniteScroll
                dataLength={filteredData.length}
                next={() => {
                  if (lastPage && currentPage !== lastPage) {
                    currentPage = currentPage + 1;
                    fetchData(
                      `${API_HOST}/${ApiEndpoint.SellersProductsApi}?page_no=${currentPage}`
                    );
                  }
                }}
                hasMore={scroll}
              >
                {loading ? (
                  <div className="loadingIconDivForProductPage">
                    <img
                      id="sellers-LoadingImg"
                      className="loader"
                      src={loadingImgPath}
                    />
                  </div>
                ) : filteredData.length === 0 ? (
                  <p className="NoDataFoundDiv">No data</p>
                ) : (
                  <>
                    {filteredData &&
                      filteredData.map((Product, index) => (
                        <div key={index}>
                          <div className="productInnerContainerForMobileView">
                            <div className="imageDivForMobile">
                              <div className="imageInnerDivForMobile">
                                <img
                                  id="sellers-produtImage-forMobile"
                                  className="imageViewForMobile"
                                  src={
                                    Product.thumb_image
                                      ? IMG_HOST + "/" + Product.thumb_image
                                      : imagePlaceHolder
                                  }
                                  onError={(e) => {
                                    e.target.src = imagePlaceHolder;
                                  }}
                                  alt="image"
                                />
                              </div>
                            </div>
                            <div className="productDetailsForMobile">
                              <div
                                id="seller-productName-forMobile"
                                className="productNameDiv"
                              >
                                {Product.name.length > 65 ? (
                                  <p id="seller-productName-forMobile">
                                    {Product.name.substring(0, 65)}
                                    ...
                                  </p>
                                ) : (
                                  Product.name
                                )}
                              </div>
                              {Product.product_price && (
                                <div className="priceDivForSeller">
                                  <p className="spanTagOfProductList">
                                    ₹{formatPrice(Product.product_price)}
                                  </p>
                                </div>
                              )}
                              <div className="statusOfProductDiv">
                                <p>
                                  <StatusCell value={Product.status} />
                                </p>
                              </div>
                            </div>
                          </div>
                          {/* here are the divs for edit and delete button. */}
                          <div className="productsBttonDiv">
                            <div style={{ width: "100%" }}>
                              <button
                                type="button"
                                id="sellers-productEditButton-forMobile"
                                onClick={() => handleEdit(Product.slug)}
                                className={`Edit-DeleteButton product-edit-button ${
                                  [
                                    "Hold",
                                    "Sold",
                                    "Donated",
                                    "Unavailable",
                                    "Blocked",
                                  ].includes(Product.status)
                                    ? "disabled"
                                    : ""
                                }`}
                                disabled={[
                                  "Hold",
                                  "Sold",
                                  "Donated",
                                  "Unavailable",
                                  "Blocked",
                                ].includes(Product.status)}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                                <span style={{ marginLeft: "5px" }}>Edit</span>
                              </button>
                            </div>
                            <div style={{ width: "100%" }}>
                              <button
                                type="button"
                                id="sellers-productDeleteButton-forMobile"
                                onClick={() => {
                                  handleDeleteClickpopup(Product);
                                }}
                                className={`Edit-DeleteButton delete-button ${
                                  ["Hold"].includes(Product.status)
                                    ? "disabled"
                                    : ""
                                }`}
                                disabled={["Hold"].includes(Product.status)}
                              >
                                <FontAwesomeIcon icon={faTrashAlt} />
                                <span style={{ marginLeft: "5px" }}>
                                  Delete
                                </span>
                              </button>
                            </div>
                          </div>
                          {deleteItem && (
                            <div
                              className="modal fade"
                              style={{
                                display: showDeleteModal ? "block" : "none",
                              }}
                            >
                              <div className="modal-content">
                                <div className="modal-header">
                                  <p>Delete Product</p>
                                  <button
                                    className="modal-close-icon"
                                    onClick={handleCancelDelete}
                                  >
                                    <FontAwesomeIcon icon={faXmark} />
                                  </button>
                                </div>

                                <div className="modal-body">
                                  <p>
                                    Do you want to delete{" "}
                                    <span className="bold">
                                      {deleteItem.name}
                                    </span>{" "}
                                    item?
                                  </p>
                                </div>
                                <div className="modal-buttons">
                                  <button
                                    id="sellers-confirmDeletePopup-buttonForMobile"
                                    className="btn primary-btn"
                                    onClick={() =>
                                      handleConfirmDelete(deleteItem.id)
                                    }
                                  >
                                    Yes, delete it
                                  </button>
                                  <button
                                    id="sellers-cancelDelete-Button"
                                    className="btn secondary-btn"
                                    onClick={handleCancelDelete}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    <div>
                      {scroll && (
                        <div className="loading-button-order-div">
                          <p className="loading-button-order-table-mobile">
                            Loading...
                          </p>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </InfiniteScroll>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductList;
