import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// import files
import InputContext from "../InputContext";
import { setDataToCookiesWithExpirationTime } from "../../../Component/Cookies/Cookie";
import navigation from "../../../Configs/Navigation.json";
import ApiEndpoint from "../../../Configs/APIEndpoints.json";
import Toast from "../../../Component/Toastify/Toast";
import ButtonImg from "../../../Assets/rolling.svg";

// import Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";

import "./signup.css";
import TermsConditionsText from "../Login/TermsConditionsText";
// import seller_sign_up from "../../../Assets/seller_sign_up.png";
import seller_sign_up from "../../../Assets/SignUpSellerRevised.jpg";
// constant
const API_HOST = process.env.REACT_APP_HOST_API_URL;

const Signup = () => {
  const { showToast } = Toast();
  const contextObj = useContext(InputContext);
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [searchParams] = useSearchParams();
  const [
    showEmailVerificationAndSetNameAndPassword,
    setShowEmailVerificationAndSetNameAndPassword,
  ] = useState(false);
  const [showcurrent_password, setShowcurrent_password] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [validMobileNumber, SetValidMobileNumber] = useState("");
  const [isSendMobileOtp, setIsSendMobileOtp] = useState(false);
  const [IsValidOtp, setIsValidOtp] = useState(false);
  const [IsValidEmail, setIsValidEmail] = useState(false);
  const [otpVerifyToken, setOtpVerifyToken] = useState("");
  const [otpVerifyTokenEmail, setOtpVerifyTokenEmail] = useState("");
  const [disableContinueButton, setDisableContinueButton] = useState(false);
  const [mobileOtpButtonDisable, setMobileOtpButtonDisable] = useState(false);
  const [signupData, setSignupData] = useState({
    phone: "",
    otp: "",
    email: "",
    name: "",
    password: "",
  });
  const [disableResendOTP, setDisableResendOTP] = useState(false);
  const [errorForPasword, setErrorForPasword] = useState("");
  const [errorForPhone, setErrorForPhone] = useState("");
  const [errorForOtp, SetErrorForOtp] = useState("");
  const [errorForEmail, setErrorForEmail] = useState("");
  const [seconds, setSeconds] = useState(60);
  const [isActive, setIsActive] = useState(false);
  const [isActiveSecond, setIsActiveSecond] = useState(false);
  const [secondsContinue, setSecondsContinue] = useState(60);
  const [sendOtpDisable, SetSendOtpDisable] = useState(false);
  const [submitButtonDisable, setSubmitButtonDisable] = useState(false);
  const [passwordContainUpperCaseLatter, setPasswordContainUpperCaseLatter] =
    useState(false);
  const [passwordContainLowerCaseLatter, setPasswordContainLowerCaseLatter] =
    useState(false);
  const [passwordContainNumber, setPasswordContainNumber] = useState(false);
  const [passwordContainSpecialChar, setPasswordContainSpecialChar] =
    useState(false);

  const [
    passwordContainLengthGreaterThanEight,
    setPasswordContainLengthGreaterThanEight,
  ] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [isValidName, setIsValidName] = useState(false);
  const [emailAndOtpError, setEmailAndOtpError] = useState("");
  const { setInput } = useContext(InputContext);
  // Input Handle For Validation Mobile Number
  const handleMobileNumberInput = (event) => {
    let { name, value } = event.target;
    if (name === "phone") {
      const trimmedValue = value.replace(/[^0-9]/g, "");
      if (trimmedValue.length === 10 && /^[6789]\d{9}$/.test(trimmedValue)) {
        setIsValidPhone(true);
      } else {
        setIsValidPhone(false);
      }
      if (
        trimmedValue.length === 10 &&
        /^[6789]\d{9}$/.test(trimmedValue) === false
      ) {
        setErrorForPhone("Enter Valid Mobile Number");
      } else {
        setErrorForPhone("");
      }
      setSignupData((prevData) => ({
        ...prevData,
        [name]: trimmedValue,
      }));
    }
  };

  // UseEfeect to set the email or phone value
  useEffect(() => {
    if (inputRef.current !== null) inputRef.current.focus();

    // login from home page
    const fromHomePage = searchParams.get("from") === "home";
    let mobile, email;
    let input = sessionStorage.getItem("home_input");
    if (input && input.length == 10) {
      mobile = input;
    } else {
      email = input;
    }
    if (fromHomePage && input.length === 10) {
      setSignupData((prevData) => ({
        ...prevData,
        phone: mobile,
      }));
      setIsValidPhone(true);
    } else {
      setSignupData((prevData) => ({
        ...prevData,
        email: email,
      }));
      setIsValidEmail(true);
    }
  }, [contextObj]);

  // Formated Time Zone
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const formatTimesecond = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  // Start Timer
  const startTimer = () => {
    setIsActive(true);
  };

  const startsecondtimer = () => {
    setIsActiveSecond(true);
  };
  // Reset Timer
  const resetTimer = () => {
    setIsActive(false);
    setSeconds(60);
  };

  const resetTimersecond = () => {
    setIsActiveSecond(false);
    setSecondsContinue(60);
  };

  // Resend otp timer setting
  useEffect(() => {
    let interval = null;

    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
    if (seconds === 0) {
      resetTimer();
    }

    return () => clearInterval(interval);
  }, [isActive, seconds]);

  // Resend otp timer setting
  useEffect(() => {
    let interval = null;
    if (isActiveSecond && secondsContinue > 0) {
      interval = setInterval(() => {
        setSecondsContinue(secondsContinue - 1);
      }, 1000);
    }

    if (secondsContinue === 0) {
      resetTimersecond();
    }
    return () => clearInterval(interval);
  }, [isActiveSecond, secondsContinue]);
  // To implement an API for sending an OTP during the signup process.

  const signuphandleSendOtp = async () => {
    const Payload = {};
    Payload.phone = signupData.phone;
    SetValidMobileNumber(signupData.phone);
    const PayloadParam = JSON.stringify(Payload);
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellerAuthRegisterMobileSendOtp}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: PayloadParam,
      }
    );
    const res = await response.json();
    setMobileOtpButtonDisable(false);
    if (res.status === 201) {
      setIsActive(true);
      SetSendOtpDisable(true);
      startTimer();
      setOtpVerifyToken(res.data);
      setIsSendMobileOtp(true);
      setMobileOtpButtonDisable(false);
    } else if (res.status === 400) {
      setMobileOtpButtonDisable(false);
      setErrorForPhone("The mobile number has already been taken.");
    }
  };

  // To implement an API for Re-sending an OTP during the signup process.

  const signuphandleReSendOtp = async () => {
    const Payload = {};
    Payload.phone = signupData.phone;
    Payload.otp_verify_token = otpVerifyToken;
    SetValidMobileNumber(signupData.phone);
    const PayloadParam = JSON.stringify(Payload);
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellerAuthRegisterMobileResendOtp}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: PayloadParam,
      }
    );
    const res = await response.json();
    setMobileOtpButtonDisable(false);
    if (res.status === 201) {
      setIsActive(true);
      SetSendOtpDisable(true);
      startTimer();
      setOtpVerifyToken(res.data);
      setMobileOtpButtonDisable(false);
    } else if (res.status === 400) {
      setErrorForPhone("The mobile number has already been taken.");
      setMobileOtpButtonDisable(false);
    }
    setMobileOtpButtonDisable(false);
  };

  // Input Hendle For Validation email and Otp

  const handleChange = (event) => {
    let { name, value } = event.target;
    if (name === "otp") {
      SetErrorForOtp("");
      const trimmedValue = value.replace(/[^0-9]/g, "");
      setSignupData((prevData) => ({
        ...prevData,
        [name]: trimmedValue,
      }));
      if (trimmedValue.length === 4 && /^\d+$/.test(trimmedValue)) {
        setIsValidOtp(true);
      } else {
        setIsValidOtp(false);
      }
    }

    if (name === "email") {
      setErrorForEmail("");
      const trimmedValue = value.trim();
      if (/^\S+@\S+\.\S+$/.test(trimmedValue)) {
        setIsValidEmail(true);
      } else {
        setIsValidEmail(false);
      }
      setSignupData((prevData) => ({
        ...prevData,
        [name]: trimmedValue,
      }));
    }
    setDisableContinueButton(false);
  };

  // API Integration For Validate Mobile Otp and send verification Mail to email.
  const handleSignUpContinueButton = async () => {
    const payload = {};
    payload.phone = signupData.phone;
    payload.otp_verify_token = otpVerifyToken;
    payload.otp = signupData.otp;
    payload.email = signupData.email;

    const PayloadParam = JSON.stringify(payload);
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellerAuthRegisterVerifyMobileOtpAndSendOtponEmail}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: PayloadParam,
      }
    );
    const res = await response.json();
    if (res.status === 201) {
      startsecondtimer();
      setIsActiveSecond(true);
      setOtpVerifyTokenEmail(res.data);
      setShowEmailVerificationAndSetNameAndPassword(true);
      setDisableContinueButton(false);
    } else if (res.status === 401) {
      setDisableContinueButton(false);
      SetErrorForOtp(res.message);
    } else if (res.status === 400) {
      if (res.errors.email) {
        setErrorForEmail(res.errors.email[0]);
        setDisableContinueButton(false);
      }
      if (res.errors.otp_verify_token) {
        SetErrorForOtp(res.errors.otp_verify_token[0]);
        setDisableContinueButton(false);
      }
    } else if (res.errors.otp_verify_token) {
      SetErrorForOtp(res.errors.otp_verify_token[0]);
      setDisableContinueButton(false);
    }
  };

  // API Integration For Resend Verification Email to Validate Email

  const handelResendOtpForEmailVerifaction = async () => {
    const payload = {
      email: signupData.email,
      verification_token: otpVerifyTokenEmail,
    };

    const AssignParams = JSON.stringify(payload);

    try {
      const response = await fetch(
        `${API_HOST}/${ApiEndpoint.SellerAuthRegisterEmailResendOtp}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: AssignParams,
        }
      );
      const res = await response.json();

      if (res.status === 200) {
        startsecondtimer();
        setIsActiveSecond(true);
        setOtpVerifyTokenEmail(res.data);
      } else if (
        res.status === 400 ||
        res.status === 429 ||
        res.status === 403
      ) {
        setErrorForEmail(res.message);
      }
    } catch (error) {
      console.error("Error resending OTP for email verification:", error);
      setErrorForEmail("An unexpected error occurred. Please try again later.");
    } finally {
      setDisableResendOTP(false);
    }
  };

  //

  const handleInputChange = (event) => {
    let { name, value } = event.target;
    if (name === "name") {
      value = event.target.value.replace(
        /[#@!?><,~|\\[\]{:;"/+\-$%^&*()_=}\d]/g,
        ""
      );
      if (value.length >= 2) {
        setIsValidName(true);
      } else {
        setIsValidName(false);
      }
      if (value.trim() !== "" || value == "") {
        setSignupData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
    if (name === "password") {
      setErrorForPasword("");
      value = event.target.value.replace(
        /[\[\],+":<>=()_;~`|'?\-\/\\{}\.\s]/g,
        ""
      );
      // Check password strength and update state variables
      const containsUpperCase = /[A-Z]/.test(value);
      const containsLowerCase = /[a-z]/.test(value);
      const containsNumber = /\d/.test(value);
      const containsSpecialChar = /[!@#$%^&*{}|<>]/.test(value);
      const isLengthGreaterThanEight = value.length >= 8;

      setPasswordContainUpperCaseLatter(containsUpperCase);
      setPasswordContainNumber(containsNumber);
      setPasswordContainSpecialChar(containsSpecialChar);
      setPasswordContainLengthGreaterThanEight(isLengthGreaterThanEight);
      setPasswordContainLowerCaseLatter(containsLowerCase);
      // Set state variables to false if criteria are not met
      if (
        containsUpperCase &&
        containsLowerCase &&
        containsNumber &&
        containsSpecialChar &&
        isLengthGreaterThanEight
      ) {
        setIsValidPassword(true);
      } else {
        setIsValidPassword(false);
      }

      setSignupData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // API Integration for create Seller Account
  const createSellerAccount = async () => {
    const payload = {};
    payload.name = signupData.name;
    payload.password = signupData.password;
    payload.email = signupData.email;
    payload.verify_token = otpVerifyTokenEmail;

    const AssignParams = JSON.stringify(payload);
    try {
      const response = await fetch(
        `${API_HOST}/${ApiEndpoint.SellerAuthRegisterCreateAccountSuccesfull}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: AssignParams,
        }
      );
      const res = await response.json();
      if (res.status === 201) {
        showToast("success", res.message);
        const expiryDate = new Date();
        setSubmitButtonDisable(false);
        expiryDate.setFullYear(expiryDate.getFullYear() + 1);
        setDataToCookiesWithExpirationTime(
          "R3p7uLw9Xk",
          res.data,
          expiryDate,
          "/"
        );
        setDataToCookiesWithExpirationTime("isLoggedIn", true, expiryDate, "/");
        contextObj.setInput("navigation", "true");
        contextObj.setInput("navigationFrom", "logInPage");
        // Delay navigation by 10 seconds
        setTimeout(() => {
          navigate(`${navigation.dashbord}`);
        }, 3000);
      } else if (res.status === 400) {
        if (res.errors.email) {
          setEmailAndOtpError(res.errors.email[0]);
        }
        if (res.errors.password) {
          setErrorForPasword(res.errors.password[0]);
        }
      } else if (res.status === 403) {
        setEmailAndOtpError(res.message);
      }
      setSubmitButtonDisable(false);
    } catch (err) {
      setSubmitButtonDisable(false);
      if (err.message.includes("Failed to fetch")) {
        setEmailAndOtpError(err.message);
      }
    }
    setSubmitButtonDisable(false);
  };

  const handleKeyPress = (event) => {
    if (
      signupData.phone.length === 10 &&
      /^[6789]\d{9}$/.test(signupData.phone)
    ) {
      if (event.key === "Enter") {
        {
          sendOtpDisable === false
            ? signuphandleSendOtp()
            : signuphandleReSendOtp();
        }
      }
    }
  };

  return (
    <>
      <div className="SignUp_Container">
        <div className="signup_container_C1">
          <img
            src={seller_sign_up}
            alt=""
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </div>
        <div className="signup_container_C2">
          <div className="signup_container_c2_c1">
            <h1 className="signup_Joyrejoy_heading">JOYREJOY</h1>
            <div className="signup_JoyRejoy_TextContainer">
              <p className="signup_joyRejoy_para">Welcome</p>
              <li className="signup_Joyrejoy_lsit">
                {" "}
                Create your account to start selling
              </li>
            </div>
            {showEmailVerificationAndSetNameAndPassword == false ? (
              <div>
                {isActive && (
                  <div className="Signup_OtpSend_c1">
                    <li className="Signup_OtpSend_list">
                      We have sent an OTP to your mobile number:{" "}
                      {validMobileNumber}
                    </li>
                  </div>
                )}
                <div className="signup_Joyrejoy_Phone_container">
                  <label className="signup_Joyrejoy_lable_tag">
                    <input
                      id="sellers-enterMobile-forSignUp"
                      type="tel"
                      placeholder="Enter mobile number"
                      className="signup_Joyrejoy_input_tag"
                      name="phone"
                      autoComplete="off"
                      maxLength={10}
                      value={signupData.phone}
                      onChange={handleMobileNumberInput}
                      onKeyPress={handleKeyPress}
                    />
                    <span className="signup_Joyrejoy_span_tag">
                      Enter mobile number
                    </span>
                  </label>

                  <>
                    {isValidPhone === true ? (
                      <>
                        {sendOtpDisable === false ? (
                          <>
                            <button
                              id="sellers-otpButton-forSignup"
                              className="signup_Joyrejoy_SendOtpButtonactive"
                              onClick={() => {
                                signuphandleSendOtp();
                                setMobileOtpButtonDisable(true);
                              }}
                              disabled={mobileOtpButtonDisable}
                            >
                              {mobileOtpButtonDisable ? (
                                <img
                                  className="Signup_button_loading_Img"
                                  src={ButtonImg}
                                  alt=""
                                />
                              ) : (
                                "Send OTP"
                              )}
                            </button>
                          </>
                        ) : (
                          <>
                            {isActive === false ? (
                              <>
                                <button
                                  id="sellers-ResendOtpButton-forSignup"
                                  className="signup_Joyrejoy_SendOtpButtonactive"
                                  onClick={() => {
                                    signuphandleReSendOtp();
                                    setMobileOtpButtonDisable(true);
                                  }}
                                  disabled={mobileOtpButtonDisable}
                                >
                                  {mobileOtpButtonDisable ? (
                                    <img
                                      className="Signup_button_loading_Img"
                                      src={ButtonImg}
                                      alt=""
                                    />
                                  ) : (
                                    "Resend OTP"
                                  )}
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  id="sellers-ResendOtpButton-forSignup"
                                  className="signup_Joyrejoy_SendOtpButton_disable"
                                >
                                  Resend in {formatTime(seconds)}
                                </button>
                              </>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {sendOtpDisable === false ? (
                          <button
                            id="sellers-otpButton-forSignup"
                            className="signup_Joyrejoy_SendOtpButton_disable"
                          >
                            Send OTP
                          </button>
                        ) : (
                          <>
                            <button
                              id="sellers-ResendOtpButton-forSignup"
                              className="signup_Joyrejoy_SendOtpButton_disable"
                            >
                              Resend OTP
                            </button>
                          </>
                        )}
                      </>
                    )}
                  </>
                </div>
                <li className="Signup_error_Message">{errorForPhone}</li>
                <label className="signup_Joyrejoy_lable_tag">
                  <input
                    id="sellers-enterOtp-forSignup"
                    type="text"
                    placeholder="Enter OTP"
                    className="signup_Joyrejoy_input_tag"
                    maxLength={4}
                    name="otp"
                    autoComplete="off"
                    onChange={handleChange}
                    value={signupData.otp}
                  />
                  <span className="signup_Joyrejoy_span_tag">Enter OTP</span>
                </label>
                <li className="Signup_error_Message">{errorForOtp}</li>
                <label className="signup_Joyrejoy_lable_tag">
                  <input
                    id="sellers-emailAddress-forSignUp"
                    type="text"
                    placeholder="Email address"
                    className="signup_Joyrejoy_input_tag"
                    name="email"
                    autoComplete="off"
                    value={signupData.email}
                    onChange={handleChange}
                  />
                  <span className="signup_Joyrejoy_span_tag">
                    Email address
                  </span>
                </label>
                <li className="Signup_error_Message">{errorForEmail}</li>
                <div className="signup_Button_Container">
                  {isValidPhone == true &&
                  IsValidOtp == true &&
                  isSendMobileOtp == true &&
                  IsValidEmail == true ? (
                    <>
                      <button
                        id="sellers-continueFor-RegisterButton"
                        className="signup_Continue_Button_active"
                        onClick={() => {
                          handleSignUpContinueButton();
                          setDisableContinueButton(true);
                        }}
                        disabled={disableContinueButton}
                      >
                        {disableContinueButton ? (
                          <img
                            className="Signup_button_loading_Img"
                            src={ButtonImg}
                            alt=""
                          />
                        ) : (
                          "Continue"
                        )}
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        id="sellers-continueFor-RegisterButton"
                        className="signup_Continue_Button_disable"
                      >
                        Continue
                      </button>
                    </>
                  )}
                </div>
                <div className="TermsConditionsTextHolder">
                  {" "}
                  <span>
                    <p className="Terms_Conditions_Text">
                      By continuing, I agree to{" "}
                      <span
                        onClick={() => {
                          navigate("/terms-of-use");
                        }}
                        className="openPolicyDocs"
                      >
                        Terms of Use
                      </span>{" "}
                      &{" "}
                      <span
                        onClick={() => {
                          navigate("/privacy-policy");
                        }}
                        className="openPolicyDocs"
                      >
                        Privacy Policy
                      </span>
                    </p>
                  </span>
                </div>
                <div>
                  <div className="loginsignupbuttoncontainer">
                    <div className="HrLineForLine"></div>
                    <li
                      id="seller-alreadyHaving-AccountButton"
                      className="Signup_Login_Link_Text"
                    >
                      Already have an account?
                    </li>
                    <div className="HrLineForLine"></div>
                  </div>
                  <button
                    id="sellers-continueTo-LoginButton"
                    className="Login-signup-quick-line-button"
                    onClick={() => {
                      navigate("/");
                      contextObj.setInput("isSignupNavigation", true);
                    }}
                  >
                    Log in
                  </button>
                </div>
              </div>
            ) : (
              <>
                {isActiveSecond && (
                  <div className="Signup_EmailSend_Verification_Container">
                    <li className="signup_List_text_Container">
                      We have sent you an Verification URL to your email
                      address: {signupData.email}
                    </li>
                  </div>
                )}
                <div>
                  <label className="signup_Joyrejoy_lable_tag">
                    <input
                      id="sellers-emailAddress-forSignUp"
                      type="text"
                      placeholder="Email address"
                      className="signup_Joyrejoy_input_tag"
                      name="email"
                      autoComplete="off"
                      value={signupData.email}
                      readOnly
                    />
                    <span className="signup_Joyrejoy_span_tag">
                      Email address
                    </span>
                    <span
                      className="Profile_Deactivate_edit-icon"
                      onClick={() => {
                        setShowEmailVerificationAndSetNameAndPassword(false);
                        setIsActive(false);
                        SetSendOtpDisable(false);
                        setIsValidEmail(false);
                        setIsValidPhone(false);
                        setIsValidOtp(false);
                        setErrorForPasword("");
                        setErrorForEmail("");
                        setEmailAndOtpError("");
                        setPasswordContainUpperCaseLatter(false);
                        setPasswordContainNumber(false);
                        setPasswordContainSpecialChar(false);
                        setPasswordContainLengthGreaterThanEight(false);
                        setPasswordContainLowerCaseLatter(false);
                        setIsValidPassword(false);
                        setIsValidName(false);
                        setShowcurrent_password(false);
                        setSignupData((prevData) => ({
                          ...prevData,
                          phone: "",
                          otp: "",
                          email: "",
                          name: "",
                          password: "",
                        }));
                      }}
                    >
                      <svg
                        width="14"
                        height="15"
                        viewBox="0 0 14 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.00432 11.0312C1.63242 11.0312 1.28491 10.9032 1.03494 10.6654C0.717916 10.3666 0.565499 9.91549 0.620369 9.42776L0.845947 7.45243C0.888624 7.08053 1.1142 6.5867 1.37636 6.31845L6.38175 1.02045C7.63157 -0.30253 8.93626 -0.339111 10.2592 0.910712C11.5822 2.16053 11.6188 3.46523 10.369 4.78821L5.3636 10.0862C5.10754 10.3606 4.63199 10.6166 4.26009 10.6776L2.29696 11.0129C2.19332 11.019 2.10187 11.0312 2.00432 11.0312ZM8.33879 0.904615C7.86934 0.904615 7.46086 1.19726 7.04629 1.63622L2.0409 6.94037C1.91896 7.0684 1.77874 7.37324 1.75435 7.55004L1.52878 9.52531C1.50439 9.7265 1.55316 9.89111 1.6629 9.99475C1.77264 10.0984 1.93725 10.135 2.13845 10.1045L4.10158 9.76923C4.27838 9.73875 4.57103 9.58018 4.69296 9.45215L9.69835 4.15415C10.4543 3.34939 10.7287 2.60559 9.62519 1.56915C9.13745 1.09971 8.71678 0.904615 8.33879 0.904615Z"
                          fill="#888888"
                        />
                        <path
                          d="M9.19847 5.80701C9.18628 5.80701 9.16799 5.80701 9.15579 5.80701C7.25362 5.61801 5.72336 4.17313 5.43071 2.28315C5.39413 2.03319 5.56484 1.80152 5.81481 1.75884C6.06477 1.72226 6.29644 1.89297 6.33912 2.14293C6.5708 3.61833 7.76575 4.75232 9.25334 4.89864C9.5033 4.92302 9.68621 5.1486 9.66182 5.39853C9.63133 5.63021 9.43014 5.80701 9.19847 5.80701Z"
                          fill="#888888"
                        />
                        <path
                          d="M11.4313 13.0004H0.457252C0.207288 13.0004 0 12.7932 0 12.5432C0 12.2932 0.207288 12.0859 0.457252 12.0859H11.4313C11.6813 12.0859 11.8885 12.2932 11.8885 12.5432C11.8885 12.7932 11.6813 13.0004 11.4313 13.0004Z"
                          fill="#888888"
                        />
                      </svg>
                    </span>
                  </label>
                  <li className="Signup_error_Message">{emailAndOtpError}</li>
                  <li className="Signup_error_Message">{errorForEmail}</li>
                  {isActiveSecond === false ? (
                    <>
                      <li
                        id="sellers-resendVarification-emailButton"
                        className="ResendOtpContainer"
                      >
                        <span
                          onClick={() => {
                            if (!disableResendOTP) {
                              setDisableResendOTP(true);
                              handelResendOtpForEmailVerifaction();
                              setEmailAndOtpError("");
                            }
                          }}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          {" "}
                          {disableResendOTP ? (
                            <img
                              className="Signup_button_loading_Img"
                              src={ButtonImg}
                              alt=""
                              style={{
                                height: "12px",
                              }}
                            />
                          ) : (
                            "Resend Verification Email"
                          )}
                        </span>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="ResendOtpContainer">
                        <span
                          style={{
                            color: "#8e96a3",
                            cursor: "default",
                          }}
                        >
                          {" "}
                          Resend Verification Email in{" "}
                          {formatTimesecond(secondsContinue)}
                        </span>
                      </li>
                    </>
                  )}
                  <label className="signup_Joyrejoy_lable_tag">
                    <input
                      id="sellers-nameForSignUp-inputFeild"
                      type="text"
                      placeholder="Full name"
                      className="signup_Joyrejoy_input_tag"
                      name="name"
                      maxLength={50}
                      autoComplete="off"
                      value={signupData.name}
                      onChange={handleInputChange}
                    />
                    <span className="signup_Joyrejoy_span_tag">Full name</span>
                  </label>
                  <label className="signup_Joyrejoy_lable_tag">
                    <input
                      type="password"
                      id="current_passwordInputTag"
                      placeholder="Set password"
                      className="signup_Joyrejoy_input_tag"
                      name="password"
                      value={signupData.password}
                      maxLength={16}
                      autoComplete="off"
                      onChange={handleInputChange}
                    />
                    <span className="signup_Joyrejoy_span_tag">
                      Set password
                    </span>
                    <span className="password-eye-icons-password">
                      {showcurrent_password ? (
                        <FontAwesomeIcon
                          icon={faEye}
                          className="fontassowmicon"
                          style={{ color: "rgb(177, 177, 177)" }}
                          onClick={() => {
                            setShowcurrent_password(false);
                            const x = document.getElementById(
                              "current_passwordInputTag"
                            );
                            x.type = "password";
                          }}
                          tabindex="0"
                        />
                      ) : (
                        <FontAwesomeIcon
                          className="fontassowmicon"
                          icon={faEyeSlash}
                          style={{ color: "rgb(177, 177, 177)" }}
                          onClick={() => {
                            setShowcurrent_password(true);
                            const x = document.getElementById(
                              "current_passwordInputTag"
                            );
                            x.type = "text";
                          }}
                          tabindex="0"
                        />
                      )}
                    </span>
                  </label>
                  <li className="Signup_error_Message">{errorForPasword}</li>
                  <p className="signup_Password_Validation_para">
                    Make your password strong by adding:
                  </p>
                  <li className="signup_password_Validation_Checks">
                    {passwordContainLengthGreaterThanEight ? (
                      <>
                        <span>
                          <svg
                            width="11"
                            height="8"
                            viewBox="0 0 11 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.73609 7.84288L0.161085 4.35628C-0.0536949 4.14681 -0.0536949 3.80718 0.161085 3.59769L0.938884 2.8391C1.15366 2.62961 1.50193 2.62961 1.71671 2.8391L4.125 5.18782L9.28329 0.157101C9.49807 -0.0523671 9.84634 -0.0523671 10.0611 0.157101L10.8389 0.915689C11.0537 1.12516 11.0537 1.46479 10.8389 1.67428L4.51391 7.8429C4.29911 8.05237 3.95087 8.05237 3.73609 7.84288Z"
                              fill="#46AA30"
                            />
                          </svg>
                        </span>
                        {"  "}
                      </>
                    ) : (
                      <span>
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="5.5" cy="5.5" r="5.5" fill="#D9D9D9" />
                        </svg>
                        {"  "}
                      </span>
                    )}
                    Minimum 8 characters (letters & numbers)
                  </li>
                  <li className="signup_password_Validation_Checks">
                    {passwordContainSpecialChar ? (
                      <span>
                        <svg
                          width="11"
                          height="8"
                          viewBox="0 0 11 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.73609 7.84288L0.161085 4.35628C-0.0536949 4.14681 -0.0536949 3.80718 0.161085 3.59769L0.938884 2.8391C1.15366 2.62961 1.50193 2.62961 1.71671 2.8391L4.125 5.18782L9.28329 0.157101C9.49807 -0.0523671 9.84634 -0.0523671 10.0611 0.157101L10.8389 0.915689C11.0537 1.12516 11.0537 1.46479 10.8389 1.67428L4.51391 7.8429C4.29911 8.05237 3.95087 8.05237 3.73609 7.84288Z"
                            fill="#46AA30"
                          />
                        </svg>
                        {"  "}
                      </span>
                    ) : (
                      <span>
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="5.5" cy="5.5" r="5.5" fill="#D9D9D9" />
                        </svg>
                        {"  "}
                      </span>
                    )}
                    Minimum 1 special character (@ # $ % ! ^ & *)
                  </li>
                  <li className="signup_password_Validation_Checks">
                    {passwordContainUpperCaseLatter ? (
                      <span>
                        <svg
                          width="11"
                          height="8"
                          viewBox="0 0 11 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.73609 7.84288L0.161085 4.35628C-0.0536949 4.14681 -0.0536949 3.80718 0.161085 3.59769L0.938884 2.8391C1.15366 2.62961 1.50193 2.62961 1.71671 2.8391L4.125 5.18782L9.28329 0.157101C9.49807 -0.0523671 9.84634 -0.0523671 10.0611 0.157101L10.8389 0.915689C11.0537 1.12516 11.0537 1.46479 10.8389 1.67428L4.51391 7.8429C4.29911 8.05237 3.95087 8.05237 3.73609 7.84288Z"
                            fill="#46AA30"
                          />
                        </svg>
                        {"  "}
                      </span>
                    ) : (
                      <>
                        <span>
                          <svg
                            width="11"
                            height="11"
                            viewBox="0 0 11 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="5.5" cy="5.5" r="5.5" fill="#D9D9D9" />
                          </svg>
                          {"  "}
                        </span>
                      </>
                    )}
                    Minimum 1 uppercase letter (A-Z)
                  </li>
                  <li className="signup_password_Validation_Checks">
                    {passwordContainLowerCaseLatter ? (
                      <span>
                        <svg
                          width="11"
                          height="8"
                          viewBox="0 0 11 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.73609 7.84288L0.161085 4.35628C-0.0536949 4.14681 -0.0536949 3.80718 0.161085 3.59769L0.938884 2.8391C1.15366 2.62961 1.50193 2.62961 1.71671 2.8391L4.125 5.18782L9.28329 0.157101C9.49807 -0.0523671 9.84634 -0.0523671 10.0611 0.157101L10.8389 0.915689C11.0537 1.12516 11.0537 1.46479 10.8389 1.67428L4.51391 7.8429C4.29911 8.05237 3.95087 8.05237 3.73609 7.84288Z"
                            fill="#46AA30"
                          />
                        </svg>
                        {"  "}
                      </span>
                    ) : (
                      <>
                        <span>
                          <svg
                            width="11"
                            height="11"
                            viewBox="0 0 11 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="5.5" cy="5.5" r="5.5" fill="#D9D9D9" />
                          </svg>
                          {"  "}
                        </span>
                      </>
                    )}
                    Minimum 1 lowercase letter (a-z)
                  </li>
                  <li className="signup_password_Validation_Checks">
                    {passwordContainNumber ? (
                      <>
                        <span>
                          <svg
                            width="11"
                            height="8"
                            viewBox="0 0 11 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.73609 7.84288L0.161085 4.35628C-0.0536949 4.14681 -0.0536949 3.80718 0.161085 3.59769L0.938884 2.8391C1.15366 2.62961 1.50193 2.62961 1.71671 2.8391L4.125 5.18782L9.28329 0.157101C9.49807 -0.0523671 9.84634 -0.0523671 10.0611 0.157101L10.8389 0.915689C11.0537 1.12516 11.0537 1.46479 10.8389 1.67428L4.51391 7.8429C4.29911 8.05237 3.95087 8.05237 3.73609 7.84288Z"
                              fill="#46AA30"
                            />
                          </svg>
                        </span>
                        {"  "}
                      </>
                    ) : (
                      <>
                        <span>
                          <svg
                            width="11"
                            height="11"
                            viewBox="0 0 11 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="5.5" cy="5.5" r="5.5" fill="#D9D9D9" />
                          </svg>
                          {"  "}
                        </span>
                      </>
                    )}
                    Minimum 1 number (0-9)
                  </li>
                </div>
                <div>
                  {IsValidEmail && isValidPassword && isValidName ? (
                    <>
                      <button
                        id="sellers-createAccountButton-forLogin"
                        className="signup_Create_Account_Button_Active"
                        onClick={() => {
                          setEmailAndOtpError("");
                          createSellerAccount();
                          setSubmitButtonDisable(true);
                        }}
                        disabled={submitButtonDisable}
                      >
                        {submitButtonDisable ? (
                          <img
                            className="Signup_button_loading_Img"
                            src={ButtonImg}
                            alt=""
                          />
                        ) : (
                          " Create Account"
                        )}
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        id="sellers-createAccountButton-forLogin"
                        className="signup_Create_Account_Button_disable"
                      >
                        Create Account
                      </button>
                    </>
                  )}
                </div>
                <div className="TermsConditionsTextHolder">
                  {" "}
                  <span>
                    <p className="Terms_Conditions_Text">
                      By continuing, I agree to{" "}
                      <span
                        onClick={() => {
                          navigate("/terms-of-use");
                        }}
                        className="openPolicyDocs"
                      >
                        Terms of Use
                      </span>{" "}
                      &{" "}
                      <span
                        onClick={() => {
                          navigate("/privacy-policy");
                        }}
                        className="openPolicyDocs"
                      >
                        Privacy Policy
                      </span>
                    </p>
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
