import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import validator from "validator";
import navigation from "../../../Configs/Navigation.json";
import ApiEndpoint from "../../../Configs/APIEndpoints.json";
import Alert from "../../../Configs/Alerts.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import Toast from "../../../Component/Toastify/Toast";
import { faTimes, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
// import login_banner from "../../../Assets/login_banner.png";
import "../auth.css";
import TermsConditionsText from "../Login/TermsConditionsText";
import ButtonImg from "../../../Assets/rolling.svg";

import login_banner from "../../../Assets/ResetPassword.jpg";
//constant
const API_HOST = process.env.REACT_APP_HOST_API_URL;

const ForgotPassword = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [emailOrPhoneError, setEmailOrPhoneError] = useState("");
  const [emailPhone, setEmailPhone] = useState({
    email_phone_value: "",
  });
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [otpButtonActive, setOtpButtonActive] = useState(false);
  const [emailOrPhoneType, setEmailOrPhoneType] = useState("");
  const [emailOrPhoneTypeValue, setEmailOrPhoneTypeValue] = useState("");
  const [showSetPassword, setShowSetPassword] = useState(false);
  const [forgotPassword, setforgotPassword] = useState({
    otp: "",
    password: "",
  });
  const [IsValidOtp, setIsValidOtp] = useState(false);
  const [verifyOtpButtonDisable, setVerifyOtpButtonDisable] = useState(false);
  const [submitButtonDisable, setSubmitButtonDisable] = useState(false);
  const [errorForOtp, SetErrorForOtp] = useState("");
  const [errorforForgotPassword, setErrorForForgotPassword] = useState("");
  const [EmailOrPasswordType, setEmailOrPasswordType] = useState("");
  const [verifyToken, setVerifyToken] = useState("");
  const [mobileEmailOtpButtonDisable, setMobileEmailOtpButtonDisable] =
    useState(false);
  const [verifyOtpButton, setVerifyOtpButton] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [passwordContainUpperCaseLatter, setPasswordContainUpperCaseLatter] =
    useState(false);
  const [passwordContainNumber, setPasswordContainNumber] = useState(false);
  const [passwordContainSpecialChar, setPasswordContainSpecialChar] =
    useState(false);
  const [passwordContainLowerCaseLatter, setPasswordContainLowerCaseLatter] =
    useState(false);
  const [
    passwordContainLengthGreaterThanEight,
    setPasswordContainLengthGreaterThanEight,
  ] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [isActive, setIsActive] = useState(false);
  const [showcurrent_password, setShowcurrent_password] = useState(false);
  const [sendOtpDisable, SetSendOtpDisable] = useState(false);
  const [remember_Token, set_Remember_Token] = useState("");
  const { showToast } = Toast();
  // Formated Time Zone
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  // Start Timer
  const startTimer = () => {
    setIsActive(true);
  };

  // Reset Timer
  const resetTimer = () => {
    setIsActive(false);
    setSeconds(60);
  };

  // Resend otp timer setting
  useEffect(() => {
    let interval = null;

    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
    if (seconds === 0) {
      resetTimer();
    }

    return () => clearInterval(interval);
  }, [isActive, seconds]);

  // email validation
  const OTPSendForForgotPassword = async (value, type) => {
    // create a new payload object
    let payload = {};
    if (type === "email") {
      payload["email"] = value;
      setEmailOrPhoneType("email");
      setEmailOrPhoneTypeValue(value);
    } else {
      payload["phone"] = value;
      setEmailOrPhoneType("mobile number");
      setEmailOrPhoneTypeValue(value);
    }

    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellersForgotPasswordStep1}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    const res = await response.json();
    if (res) {
      setMobileEmailOtpButtonDisable(false);
    }
    if (res.status === 201) {
      startTimer();
      setEmailOrPasswordType(value);
      setVerifyOtpButton(true);
      setVerifyToken(res.data);
      SetSendOtpDisable(true);
    } else if (res.status === 404 || res.status === 429) {
      setEmailOrPhoneError(res.message);
    } else if (res.status === 400) {
      if (res.errors.email) {
        setEmailOrPhoneError(res.errors.email[0]);
      } else if (res.errors.phone) {
        setEmailOrPhoneError(res.errors.phone[0]);
      }
    } else {
      setEmailOrPhoneError(Alert.alertForSomethingWrong);
      console.error(res);
    }
    setIsLoading(false);
  };

  //

  const forgotPasswordhandleReSendOtp = async () => {
    const payload = {};
    if (emailOrPhoneType === "email") {
      payload.email = emailOrPhoneTypeValue;
    } else {
      payload.phone = emailOrPhoneTypeValue;
    }
    payload.otp_verify_token = verifyToken;

    const AssignParams = JSON.stringify(payload);
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.sellersForgotPasswordResendOtp}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: AssignParams,
      }
    );
    const res = await response.json();
    if (res.status === 201) {
      startTimer();
      setVerifyToken(res.data);
    } else if (res.status === 400) {
    }
  };

  const handleEmailPhoneSubmit = async () => {
    setEmailOrPhoneError("");
    setIsLoading(true);

    const emailPhoneInput = emailPhone.email_phone_value;
    const emailPhoneInputValue = emailPhoneInput;

    // email or phone validation
    let errors = false;
    if (emailPhoneInputValue === "") {
      setIsLoading(false);
      // emailPhoneInput.setAttribute("id", "red-border");
      setEmailOrPhoneError(Alert.alertForEmailRequirement);
      errors = true;
    }

    // Check if input matches email format
    const isEmail = validator.isEmail(emailPhoneInputValue);
    const phoneRegex = /^(\+?91|0)?[6789]\d{9}$/;
    const isPhoneNumber = phoneRegex.test(emailPhoneInputValue);

    if (isEmail) {
      if (errors === true) return;
      OTPSendForForgotPassword(emailPhoneInputValue, "email");
    } else if (isPhoneNumber) {
      if (errors === true) return;
      OTPSendForForgotPassword(emailPhoneInputValue, "phone");
    } else {
      setIsLoading(false);
      //  emailPhoneInput.setAttribute("id", "red-border");
      setEmailOrPhoneError(Alert.alertForValidEmail);
      errors = true;
      return;
    }
  };

  const handleChange = (event) => {
    let { name, value } = event.target;
    setEmailOrPhoneError("");
    if (name === "email_phone_value") {
      const trimmedValue = value.trim();
      if (trimmedValue.length === 10 && /^[6789]\d{9}$/.test(trimmedValue)) {
        // If the value is a 10-digit number, setOtpButtonActive(true)
        setOtpButtonActive(true);
      } else if (/^\S+@\S+\.\S+$/.test(trimmedValue)) {
        // If the value is an email, setOtpButtonActive(true)
        setOtpButtonActive(true);
      } else {
        // In any other case, setOtpButtonActive(false)
        setOtpButtonActive(false);
      }
      setEmailPhone((prevData) => ({
        ...prevData,
        [name]: trimmedValue,
      }));
    }
  };

  // handle keypress events
  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === 13) {
      event.preventDefault();
      handleEmailPhoneSubmit();
    }
  };

  // Handle Otp Input Change
  const handleOtpChange = (event) => {
    let { name, value } = event.target;
    if (name === "otp") {
      SetErrorForOtp("");
      const trimmedValue = value.replace(/[^0-9]/g, "");
      setforgotPassword((prevData) => ({
        ...prevData,
        [name]: trimmedValue,
      }));
      if (trimmedValue.length === 4 && /^\d+$/.test(trimmedValue)) {
        setIsValidOtp(true);
      } else {
        setIsValidOtp(false);
      }
    }
  };

  // Handle Password Input change

  const handlePasswordInputChange = (event) => {
    let { name, value } = event.target;
    if (name === "password") {
      setErrorForForgotPassword("");
      value = event.target.value.replace(
        /[\[\],+":<>=()_;`|'~?\-\/\\{}\.\s]/g,
        ""
      );
      // Check password strength and update state variables
      const containsUpperCase = /[A-Z]/.test(value);
      const containsLowerCase = /[a-z]/.test(value);
      const containsNumber = /\d/.test(value);
      const containsSpecialChar = /[!@#$%^&*(){}|<>]/.test(value);
      const isLengthGreaterThanEight = value.length >= 8;

      setPasswordContainUpperCaseLatter(containsUpperCase);
      setPasswordContainNumber(containsNumber);
      setPasswordContainSpecialChar(containsSpecialChar);
      setPasswordContainLengthGreaterThanEight(isLengthGreaterThanEight);
      setPasswordContainLowerCaseLatter(containsLowerCase);
      // Set state variables to false if criteria are not met
      if (
        containsUpperCase &&
        containsLowerCase &&
        containsNumber &&
        containsSpecialChar &&
        isLengthGreaterThanEight
      ) {
        setIsValidPassword(true);
      } else {
        setIsValidPassword(false);
      }
      setforgotPassword((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  // API call to send the otp and email and phone to verify

  const handleOtpSubmit = async () => {
    const payload = {};
    if (emailOrPhoneType === "email") {
      payload.email = emailOrPhoneTypeValue;
    } else {
      payload.phone = emailOrPhoneTypeValue;
    }
    payload.otp = forgotPassword.otp;
    payload.otp_verify_token = verifyToken;

    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellersForgotPasswordStep2}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    const res = await response.json();
    setVerifyOtpButtonDisable(false);
    if (res.status === 200) {
      set_Remember_Token(res.data);
      setShowSetPassword(true);
    } else if (res.status === 401) {
      SetErrorForOtp(res.message);
    } else if (res.status === 400) {
    } else if ((res.status = 404)) {
      setEmailOrPhoneError(res.message);
    }
  };

  // API Integration To set the Reset Forgot Password

  const resetForgotPassword = async () => {
    const payload = {};
    if (emailOrPhoneType === "email") {
      payload.email = emailOrPhoneTypeValue;
    } else {
      payload.phone = emailOrPhoneTypeValue;
    }
    payload.new_password = forgotPassword.password;
    payload.remember_token = remember_Token;
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellerUpdateForgotPassowrd}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    const res = await response.json();
    setSubmitButtonDisable(false);
    if (res.status === 200) {
      showToast("success", res.message);
      setShowSetPassword(true);
      props.onClose(false);
      props.showLoginModal(false);
      // setTimeout(() => {
      //   navigate(`${navigation.home}`);
      // }, 3000);
    } else if (res.status === 401) {
      setErrorForForgotPassword(res.message);
    } else if (res.status === 400) {
      let errorMessage = "";
      const errors = res.errors;
      if (errors) {
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((message) => {
            errorMessage = errorMessage + " " + message;
          });
        });
      } else if (res.message) {
        errorMessage = res.message;
      }
      setErrorForForgotPassword(errorMessage);
    } else if ((res.status = 404)) {
      setErrorForForgotPassword(res.message);
    }
  };

  return (
    <>
      <div
        className="auth-container"
        // className="ForgotPassword_Container_Box"
      >
        <div className="auth-Holder">
          <div className="closeForgotPassModalBtn closeForgotPassModalBtnWebView">
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="curserPointer"
              size="lg"
              color="#fff"
              onClick={() => {
                props.onClose(false);
                props.showLoginModal(true);
              }}
            />
            <FontAwesomeIcon
              icon={faTimes}
              className="curserPointer"
              size="lg"
              color="#fff"
              onClick={() => {
                props.showLoginModal(false);
                props.onClose(false);
              }}
            />
          </div>
          <div className="login-Image-Container">
            <img src={login_banner} alt="" className="login-Image" />
          </div>
          <div
            className="login-left-box"
            //  className="Forgot_box_mainContainer"
          >
            <div className="closeForgotPassModalBtn closeForgotPassModalBtnMobileView">
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="curserPointer"
                size="lg"
                color="#9e9e9eb7"
                onClick={() => {
                  props.onClose(false);
                  props.showLoginModal(true);
                }}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className="curserPointer"
                size="lg"
                color="#9e9e9eb7"
                onClick={() => {
                  props.onClose(false);
                  props.showLoginModal(false);
                }}
              />
            </div>
            <h1 className="ForgotPasswordHeading"> JOYREJOY </h1>
            <div className="ForgotPasswordContainer">
              {/* <Link to="/login" className="quick-word-link"> */}
              {/* <Link
                // to="/"
                onClick={() => {
                  props.onClose(false);
                  props.showLoginModal(true);
                }}
                className="quick-word-link"
              >
                <div className="Forgot_spandiv">
                  <span>
                    <svg
                      width="14"
                      height="10"
                      viewBox="0 0 14 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 4.78636H2.70251L6.43929 1.00908L5.44102 0L0 5.5L5.44102 11L6.43929 9.99092L2.70251 6.21364H13V4.78636Z"
                        fill="#3366CC"
                      />
                    </svg>
                  </span>{" "}
                  <span className="ForgotPassword_BackButton"> Back</span>
                </div>
              </Link> */}
              {showSetPassword === false ? (
                <>
                  <div>
                    <div className="Forgotbox_headingelContainer">
                      <h1 className="Forgot_Password_Heading">
                        Forgot login password
                      </h1>
                      <li className="ForgotPassword_line">
                        Please enter your registered email or mobile
                      </li>
                    </div>
                    {isActive == true ? (
                      <>
                        <div className="ForgotPasswoed_sentDetails">
                          <li className="sendOtpContainer">
                            We have sent an OTP to your {emailOrPhoneType}:{" "}
                            {emailOrPhoneTypeValue}
                          </li>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="ForgotPassword_EmailOrPhone">
                      <label className="ForgotPassword_lable_tag">
                        <input
                          type="text"
                          placeholder="Email or Phone"
                          className="Forgot_Password_input_tag"
                          onChange={handleChange}
                          value={emailPhone.email_phone_value}
                          name="email_phone_value"
                          autoComplete="off"
                          maxLength={50}
                          onKeyPress={handleKeyPress}
                        />
                        <span className="ForgotPassword_span_tag">
                          Email or Phone
                        </span>
                      </label>
                      <>
                        {otpButtonActive == true ? (
                          <>
                            {sendOtpDisable === false ? (
                              <>
                                <button
                                  className="signup_Joyrejoy_SendOtpButtonactive"
                                  onClick={() => {
                                    handleEmailPhoneSubmit();
                                    setMobileEmailOtpButtonDisable(true);
                                  }}
                                  disabled={mobileEmailOtpButtonDisable}
                                >
                                  {mobileEmailOtpButtonDisable ? (
                                    <img
                                      className="Signup_button_loading_Img"
                                      src={ButtonImg}
                                      alt=""
                                    />
                                  ) : (
                                    "Send OTP"
                                  )}
                                </button>
                              </>
                            ) : (
                              <>
                                {isActive === false ? (
                                  <>
                                    <button
                                      className="signup_Joyrejoy_SendOtpButtonactive"
                                      onClick={forgotPasswordhandleReSendOtp}
                                    >
                                      Resend OTP
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button className="signup_Joyrejoy_SendOtpButton_disable">
                                      Resend in {formatTime(seconds)}
                                    </button>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {sendOtpDisable === false ? (
                              <button className="signup_Joyrejoy_SendOtpButton_disable">
                                Send OTP
                              </button>
                            ) : (
                              <>
                                <button className="signup_Joyrejoy_SendOtpButton_disable">
                                  Resend OTP
                                </button>
                              </>
                            )}
                          </>
                        )}
                      </>
                    </div>
                    {emailOrPhoneError && (
                      <p className="auth-error">{emailOrPhoneError}</p>
                    )}
                    <label className="ForgotPassword_lable_tag">
                      <input
                        type="otp"
                        placeholder="Enter OTP"
                        className="Forgot_Password_input_tag"
                        autoComplete="off"
                        name="otp"
                        maxLength={4}
                        value={forgotPassword.otp}
                        onChange={handleOtpChange}
                      />
                      <span className="ForgotPassword_span_tag">Enter OTP</span>
                    </label>
                    <p className="auth-error">{errorForOtp}</p>
                    {otpButtonActive && IsValidOtp && verifyOtpButton ? (
                      <>
                        <button
                          className="ForgotPasswordVerify_OtpButton_active"
                          onClick={() => {
                            handleOtpSubmit();
                            setVerifyOtpButtonDisable(true);
                          }}
                          disabled={verifyOtpButtonDisable}
                        >
                          {verifyOtpButtonDisable ? (
                            <img
                              className="Signup_button_loading_Img"
                              src={ButtonImg}
                              alt=""
                            />
                          ) : (
                            "Verify OTP"
                          )}
                        </button>
                      </>
                    ) : (
                      <>
                        <button className="ForgotPasswordVerify_OtpButton">
                          Verify OTP
                        </button>
                      </>
                    )}
                    <div className="TermsConditionsTextHolder">
                      {" "}
                      <TermsConditionsText onClose={props.onClose} />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <h1 className="Forgot_SetPassword_heading">Set Password</h1>
                    <div>
                      <label className="ForgotPassword_lable_tag">
                        <input
                          type="password"
                          id="current_passwordInputTag"
                          placeholder="Set password"
                          name="password"
                          className="Forgot_Password_input_tag"
                          maxLength={16}
                          autoComplete="off"
                          onChange={handlePasswordInputChange}
                          value={forgotPassword.password}
                        />
                        <span className="ForgotPassword_span_tag">
                          Set password
                        </span>
                        <span className="password-eye-icons-password">
                          {showcurrent_password ? (
                            <FontAwesomeIcon
                              icon={faEye}
                              className="fontassowmicon"
                              style={{ color: "#CDCDCD" }}
                              onClick={() => {
                                setShowcurrent_password(false);
                                const x = document.getElementById(
                                  "current_passwordInputTag"
                                );
                                x.type = "password";
                              }}
                              tabindex="0"
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="fontassowmicon"
                              icon={faEyeSlash}
                              style={{ color: "#CDCDCD" }}
                              onClick={() => {
                                setShowcurrent_password(true);
                                const x = document.getElementById(
                                  "current_passwordInputTag"
                                );
                                x.type = "text";
                              }}
                              tabindex="0"
                            />
                          )}
                        </span>
                      </label>
                      {errorforForgotPassword && (
                        <p className="auth-error">{errorforForgotPassword}</p>
                      )}
                      <p className="ForgotPassword_Click">
                        Make your password strong by adding:
                      </p>
                      <li className="ForgotPasswordList_clicks">
                        {passwordContainLengthGreaterThanEight ? (
                          <>
                            <span>
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 11 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.73609 7.84288L0.161085 4.35628C-0.0536949 4.14681 -0.0536949 3.80718 0.161085 3.59769L0.938884 2.8391C1.15366 2.62961 1.50193 2.62961 1.71671 2.8391L4.125 5.18782L9.28329 0.157101C9.49807 -0.0523671 9.84634 -0.0523671 10.0611 0.157101L10.8389 0.915689C11.0537 1.12516 11.0537 1.46479 10.8389 1.67428L4.51391 7.8429C4.29911 8.05237 3.95087 8.05237 3.73609 7.84288Z"
                                  fill="#46AA30"
                                />
                              </svg>
                            </span>
                            {"  "}
                          </>
                        ) : (
                          <>
                            <span>
                              <svg
                                width="11"
                                height="11"
                                viewBox="0 0 11 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="5.5"
                                  cy="5.5"
                                  r="5.5"
                                  fill="#D9D9D9"
                                />
                              </svg>
                              {"  "}
                            </span>
                          </>
                        )}
                        Minimum 8 characters (letters & numbers)
                      </li>
                      <li className="ForgotPasswordList_clicks">
                        {passwordContainSpecialChar ? (
                          <>
                            <span>
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 11 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.73609 7.84288L0.161085 4.35628C-0.0536949 4.14681 -0.0536949 3.80718 0.161085 3.59769L0.938884 2.8391C1.15366 2.62961 1.50193 2.62961 1.71671 2.8391L4.125 5.18782L9.28329 0.157101C9.49807 -0.0523671 9.84634 -0.0523671 10.0611 0.157101L10.8389 0.915689C11.0537 1.12516 11.0537 1.46479 10.8389 1.67428L4.51391 7.8429C4.29911 8.05237 3.95087 8.05237 3.73609 7.84288Z"
                                  fill="#46AA30"
                                />
                              </svg>
                            </span>
                            {"  "}
                          </>
                        ) : (
                          <>
                            <span>
                              <svg
                                width="11"
                                height="11"
                                viewBox="0 0 11 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="5.5"
                                  cy="5.5"
                                  r="5.5"
                                  fill="#D9D9D9"
                                />
                              </svg>
                              {"  "}
                            </span>
                          </>
                        )}
                        Minimum 1 special character (@ # $ % ! ^ & *)
                      </li>
                      <li className="ForgotPasswordList_clicks">
                        {passwordContainUpperCaseLatter ? (
                          <>
                            <span>
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 11 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.73609 7.84288L0.161085 4.35628C-0.0536949 4.14681 -0.0536949 3.80718 0.161085 3.59769L0.938884 2.8391C1.15366 2.62961 1.50193 2.62961 1.71671 2.8391L4.125 5.18782L9.28329 0.157101C9.49807 -0.0523671 9.84634 -0.0523671 10.0611 0.157101L10.8389 0.915689C11.0537 1.12516 11.0537 1.46479 10.8389 1.67428L4.51391 7.8429C4.29911 8.05237 3.95087 8.05237 3.73609 7.84288Z"
                                  fill="#46AA30"
                                />
                              </svg>
                            </span>
                            {"  "}
                          </>
                        ) : (
                          <>
                            <span>
                              <svg
                                width="11"
                                height="11"
                                viewBox="0 0 11 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="5.5"
                                  cy="5.5"
                                  r="5.5"
                                  fill="#D9D9D9"
                                />
                              </svg>
                              {"  "}
                            </span>
                          </>
                        )}
                        Minimum 1 capital letter (A-Z)
                      </li>
                      <li className="signup_password_Validation_Checks">
                        {passwordContainLowerCaseLatter ? (
                          <span>
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 11 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.73609 7.84288L0.161085 4.35628C-0.0536949 4.14681 -0.0536949 3.80718 0.161085 3.59769L0.938884 2.8391C1.15366 2.62961 1.50193 2.62961 1.71671 2.8391L4.125 5.18782L9.28329 0.157101C9.49807 -0.0523671 9.84634 -0.0523671 10.0611 0.157101L10.8389 0.915689C11.0537 1.12516 11.0537 1.46479 10.8389 1.67428L4.51391 7.8429C4.29911 8.05237 3.95087 8.05237 3.73609 7.84288Z"
                                fill="#46AA30"
                              />
                            </svg>
                            {"  "}
                          </span>
                        ) : (
                          <>
                            <span>
                              <svg
                                width="11"
                                height="11"
                                viewBox="0 0 11 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="5.5"
                                  cy="5.5"
                                  r="5.5"
                                  fill="#D9D9D9"
                                />
                              </svg>
                              {"  "}
                            </span>
                          </>
                        )}
                        Minimum 1 lowercase letter (a-z)
                      </li>
                      <li className="ForgotPasswordList_clicks">
                        {passwordContainNumber ? (
                          <>
                            <span>
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 11 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.73609 7.84288L0.161085 4.35628C-0.0536949 4.14681 -0.0536949 3.80718 0.161085 3.59769L0.938884 2.8391C1.15366 2.62961 1.50193 2.62961 1.71671 2.8391L4.125 5.18782L9.28329 0.157101C9.49807 -0.0523671 9.84634 -0.0523671 10.0611 0.157101L10.8389 0.915689C11.0537 1.12516 11.0537 1.46479 10.8389 1.67428L4.51391 7.8429C4.29911 8.05237 3.95087 8.05237 3.73609 7.84288Z"
                                  fill="#46AA30"
                                />
                              </svg>
                            </span>
                            {"  "}
                          </>
                        ) : (
                          <>
                            <span>
                              <svg
                                width="11"
                                height="11"
                                viewBox="0 0 11 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="5.5"
                                  cy="5.5"
                                  r="5.5"
                                  fill="#D9D9D9"
                                />
                              </svg>
                              {"  "}
                            </span>
                          </>
                        )}
                        Minimum 1 number (0-9)
                      </li>
                    </div>
                    <div>
                      {isValidPassword == true ? (
                        <button
                          className="Forgot_password_Submit_Button_Active"
                          disabled={submitButtonDisable}
                          onClick={() => {
                            resetForgotPassword();
                            setSubmitButtonDisable(true);
                          }}
                        >
                          {submitButtonDisable ? (
                            <img
                              className="Signup_button_loading_Img"
                              src={ButtonImg}
                              alt=""
                            />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      ) : (
                        <button className="Forgot_password_Submit_Button">
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
